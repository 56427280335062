import React from "react";
import {LockedIcon} from "../../components/SvgComponents";

export const StatusBar = (props) => {
    let type = props.type;
    if (type === "success") {
        return (
            <div className={"chatStatus success"}>
                <p>
                    <LockedIcon/>
                    {props.text ? props.text : "success"}
                </p>
            </div>
        )
    } else {
        return (
            <div className={"chatStatus error"}>
                <p>
                    <LockedIcon/>
                    {props.text ? props.text : "error"}
                </p>
            </div>
        )
    }
}
