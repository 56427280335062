import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Form, Row, Button} from "react-bootstrap";
import AccSidebar from "../components/AccSidebar";
import {toast, ToastContainer} from "react-toastify";
import {useForm} from "react-hook-form";
import {Patterns, VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import {SuccessNotificationIcon} from "../components/SvgComponents";
import {useNavigate} from "react-router-dom";
import {PostAdminQuery} from "../../services/admin_queries.service";
import {EmptyLocalStorage} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";
import {IsCustomerLogin} from "../../App";

function Support() {
    const navigate = useNavigate();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [disableButton, setDisableButton] = useState(false);
    const {register, handleSubmit, reset, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const onSubmit = async (data) => {
        setDisableButton(true);
        await PostAdminQuery({
            email: data.email,
            name: data.fullName,
            description: data.msg,
        }).then((data) => {
            if (data.status) {
                Toast('Your request sent successfully.', true);
                reset();
                setDisableButton(false);
                navigate("/support")
            } else {
                setDisableButton(false);
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                setDisableButton(false);
                return Toast(error.response.data.message, false);
            }
        })
    }

    return (
        <div className={"mainDashboard accountSettings"}>
            <div className={"ProfileInformation"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row>
                                <Col xs={12} sm={12} md={5} lg={4} xl={4} className={"col-xxl-4 d-none d-md-block"}>
                                    <AccSidebar userData={isCustomerLogin}/>
                                </Col>
                                <Col xs={12} sm={12} md={7} lg={8} xl={{span: 6, offset: 1}}
                                     className={"col-xxl-6 offset-xxl-1"}>
                                    <h1 className={"m-b-30"}>Support</h1>
                                    <h4>Contact us</h4>
                                    <form className={"contactSupport"} autoComplete={"off"}
                                          onSubmit={handleSubmit(onSubmit)}>
                                        <div className={"mb13"}>
                                            <Form.Control type="text" autoComplete={"off"} placeholder="First Name"
                                                          className={"formNameF"}
                                                          {...register("fullName",
                                                              {
                                                                  maxLength: {
                                                                      value: VALIDATIONS.NAME,
                                                                      message: VALIDATIONS_TEXT.NAME_MAX
                                                                  },
                                                                  required: {
                                                                      value: true,
                                                                      message: VALIDATIONS_TEXT.NAME_REQUIRED
                                                                  },
                                                              })
                                                          }/>
                                            {errors.fullName &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.fullName.message}</span>}
                                        </div>
                                        <div className={"mb13"}>
                                            <Form.Control type="email" autoComplete={"off"} placeholder="Email address"
                                                          className={"formemail"}
                                                          {...register("email",
                                                              {
                                                                  maxLength: {
                                                                      value: VALIDATIONS.EMAIL,
                                                                      message: VALIDATIONS_TEXT.EMAIL_MAX
                                                                  },
                                                                  pattern: {
                                                                      value: Patterns.Email,
                                                                      message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                                  },
                                                                  required: {
                                                                      value: true,
                                                                      message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                                  },
                                                              })
                                                          }/>
                                            {errors.email &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.email.message}</span>}
                                        </div>
                                        <div className={"mb13"}>
                                            <Form.Control
                                                as="textarea"
                                                type="text"
                                                placeholder="Message"
                                                rows={"5"}
                                                {...register('msg', {
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.MESSAGE_REQUIRED
                                                    },
                                                    maxLength: {
                                                        value: VALIDATIONS.MESSAGE_MAX,
                                                        message: VALIDATIONS_TEXT.MESSAGE_MAX
                                                    },
                                                })}
                                            />
                                            {errors.msg &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.msg.message}</span>}
                                        </div>
                                        <Button disabled={disableButton} type={"submit"} className={"btn-solid w-100"}>
                                            Submit
                                        </Button>
                                    </form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <ToastContainer/>
                </Container>
            </div>
        </div>
    )
}

export default Support;
