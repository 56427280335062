import React, {useState, useCallback} from 'react'
import {isOnline} from "../../../services/firebase.service";

export const GetIsOnline = ({search, user_id}) => {
    const [online, setOnline] = useState(true);

    useCallback(isOnline(user_id, (data) => {
        setOnline(data)
    }), [search])

    return (
        <span className={online ? 'd-block' : 'd-none'} style={{
            content: "",
            position: "absolute",
            width: "12px",
            height: "12px",
            background: "#12B76A",
            border: "2px solid #fff",
            boxSizing: "border-box",
            right: "0",
            bottom: "0",
            borderRadius: "100%",
        }}></span>
    )
}
