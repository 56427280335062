import React, {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Button, OverlayTrigger, Tooltip, Container, Row, Col, DropdownButton, Dropdown, Form} from "react-bootstrap";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../utils/constants";
import {SuccessIcon} from "../../components/SvgComponents";
import {PostReport} from "../../../services/report.service";
import {constants} from "../../../utils/constants";
import {Toast} from "../../../utils/helpers";
import {EmptyLocalStorage} from "../../../services/authentication.service";
import ThemeModal from "../../components/ThemeModal";

export const MessageReport = ({userId, activeCustomerDetail, isCustomerLogin, modalReportShow, setModalReportShow, reportMessage, setReportMessage, reportType, setReportType}) => {
    const navigate = useNavigate();
    const [ReportSuccessModalShow, setReportSuccessModalShow] = useState(false);

    const {register, handleSubmit, reset, watch, formState: {errors}} = useForm({
        mode: "onBlur"
    });

    const ReportModalContent = () => {
        return (
            <div className={"blockProfile"}>
                <p>
                    Reason to report
                </p>
                <form className={"contactSupport"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                    <Row className={"g-3 p-3"}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={"mb13"}>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    readOnly
                                    rows={3}
                                    value={reportMessage}
                                    {...register('message')}
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={"mb13"}>
                                <Form.Select id={"formLocation"}
                                             {...register("report_type_id",
                                                 {
                                                     required: {
                                                         value: true,
                                                         message: VALIDATIONS_TEXT.REPORT_TYPE_REQUIRED
                                                     },
                                                 })
                                             }>
                                    <option value="">Report Type</option>
                                    {
                                        reportType && reportType.map((type) => {
                                            return (
                                                <option value={type.id}>{type.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                                {errors.report_type_id &&
                                <span className={"validation-error"}
                                      role="alert">{errors.report_type_id.message}</span>}
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={"mb13"}>
                                <Form.Control
                                    autoFocus
                                    as="textarea"
                                    type="text"
                                    placeholder="Description"
                                    rows={"5"}
                                    {...register('description', {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.MESSAGE_REQUIRED
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.MESSAGE_MAX,
                                            message: VALIDATIONS_TEXT.MESSAGE_MAX
                                        },
                                    })}
                                />
                                {errors.description &&
                                <span className={"validation-error"}
                                      role="alert">{errors.description.message}</span>}
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button type={"submit"} className={"btn-solid w-100"}>
                                Report
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
        )
    }

    const ReportSuccessModal = () => {
        return (
            <div className={"SuccessModal codeSentSuccess py-5"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-20"}>
                        <SuccessIcon/>
                    </div>
                    <h2 className={"m-b-20"}><b>Thanks for reporting</b></h2>
                    <p className={"m-b-20"}>If we find this content to be in violation of our community guidelines we
                        will remove it.</p>
                    <Button className="btn-solid" onClick={() => setReportSuccessModalShow(false)}>Done</Button>
                </div>
            </div>
        )
    }

    const onSubmit = async (data) => {
        await PostReport({
            report_type_id: data.report_type_id,
            description: data.description,
            instance_type: constants.ROLES.ROLE_PROFESSIONAL,
            instance_id: userId,
            message: reportMessage,
            status: 20
        }).then((data) => {
            if (data.status) {
                //Toast(data.message, true);
                reset();
                setReportSuccessModalShow(true);
                setModalReportShow(false);
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    return (
        <>
            <ThemeModal title={"Report Message"} content={<ReportModalContent user_id={''}/>} className={"reportUser"}
                        size={"md"}
                        show={modalReportShow}
                        onHide={() => {
                            reset()
                            setModalReportShow(false)
                        }}/>

            <ThemeModal title={""} content={<ReportSuccessModal/>} className={"addCardInfo"} size={"sm"}
                        show={ReportSuccessModalShow}
                        onHide={() => {
                            setReportSuccessModalShow(false)
                            reset()
                        }}/>
        </>
    )
}
