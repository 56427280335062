import React, {useContext, useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import OtpInput from "react-otp-input";
import {
    InstagramIcon,
    LeftIcon,
    MessengerIcon,
    SuccessNotificationIcon,
    TwitterIcon
} from "../components/SvgComponents";
import {useForm} from "react-hook-form";
import {useLocation, useNavigate, Navigate} from "react-router-dom";
import {toast} from "react-toastify";
import {
    EmptyLocalStorage,
    GetCurrentUser, Logout,
    ResendOtp,
    SetAuthUser,
    VerifyOtpApi
} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";
import {IsCustomerLogin} from "../../App";
import {activeInactiveUser} from "../../services/firebase.service";

/*
*
*  Box Invite list and Component
*
* */
const InviteList = [{
    platform: "Instagram",
    icon: <InstagramIcon/>,
    text: "Send via Instagram",
    para: "Curabitur id ex et ex pharetra mollis. Duis placerat, augue.",
},
    {
        platform: "Twitter",
        icon: <TwitterIcon/>,
        text: "Send via Twitter",
        para: "Curabitur id ex et ex pharetra mollis. Duis placerat, augue.",
    },
    {
        platform: "Messenger",
        icon: <MessengerIcon/>,
        text: "Send via Messenger",
        para: "Curabitur id ex et ex pharetra mollis. Duis placerat, augue.",
    }];
const BoxInvite = ({icon, text, para, ...restProps}) => {
    return (
        <div className={"boxSendCode"} {...restProps}>
            <div className={"Icon"}>
                {icon}
            </div>
            <div className={"Text"}>
                <span>{text}</span>
                <span>{para}</span>
            </div>
        </div>
    );
}

function AccountVerification() {
    const navigate = useNavigate();
    const location = useLocation();
    //const current_user = GetCurrentUser();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);

    /*otp code state*/
    const [otpCode, setOtpCode] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [resend, setResend] = useState(false);
    const [counter, setCounter] = useState(10);

    const {register, handleSubmit, formState: {errors, isValid}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (!resend) {
                setResend(true)
            }
        }, 10000)
    }, [resend])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCounter(counter - 1);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [counter]);

    const handleOtpChange = (otp) => {
        setOtpCode(otp);
    }

    const onSubmit = async () => {
        if (otpCode.length === 0) {
            setError(true)
            setErrorMessage("Please Enter OTP code")
        } else if (otpCode.length > 0 && otpCode.length < 3) {
            setError(true)
            setErrorMessage("OTP code is incomplete")
        } else {
            setError(false)
            await VerifyOtpApi(otpCode, isCustomerLogin.email).then((data) => {
                if (data.status) {
                    //SetAuthUser(JSON.parse(data.data))
                    Toast(data.message, true);
                    navigate('/success-verified-profile');
                } else {
                    Toast(data.message, false);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    const onResend = async (email) => {
        if (email) {
            setResend(true)
            setCounter(10)
            await ResendOtp(email).then((data) => {
                if (data.status) {
                    Toast(data.message, true);
                } else {
                    Toast(data.message, false);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    async function handleLogout() {
        await Logout().then(async (data) => {
            if (data.status) {
                await EmptyLocalStorage();
                Toast(data.message, true)
                activeInactiveUser(isCustomerLogin.id, false)
                setIsCustomerLogin(null)
                navigate('/');
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                //console.log(error);
                return Toast(error.response.data.message, false);
            }
        })
    }

    return (
        !isCustomerLogin ?
            <Navigate to={"/"}/>
            :
            <div className={"loginForm "}>
                <h1 className={"text-center"}>Verify your account</h1>
               {/* <p>Urna nunc id cursus metus aliquam eleifend<br/> mi. Duis ultricies lacus sed turpis tincidunt
                    id<br/> aliquet risus.</p>*/}
                <div className={"filler m-t-30"}></div>
                <form className={"m-t-40"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                    <div className={"OtpInput-container m-b-40"}>
                        <OtpInput
                            value={otpCode}
                            onChange={(otp) => handleOtpChange(otp)}
                            numInputs={4}
                            isInputNum={true}
                            placeholder={"____"}
                            className={"OtpInput"}
                            hasErrored={true}
                            focusStyle="focus"
                        />
                        {error && <span className={"validation-error"} role="alert">{errorMessage}</span>}
                    </div>

                    <div className={"mx-auto text-center m-t-40"}>
                        {
                            resend ?
                                <p className={"p-small"}>Didn’t get the code? <span onClick={() => {
                                    onResend(isCustomerLogin.email)
                                    setResend(false)
                                }} style={{fontWeight: 'bold', cursor: 'pointer'}}>Send again</span></p> :
                                <>
                                    <p className={"p-small"}>Didn’t get the code? Send again</p>
                                    <h1>{counter}</h1>
                                </>
                        }

                    </div>

                    {/* mapped component for sending invitational code to user */}
                    {/*{
                    InviteList.map((inviteBtns, index)=>{
                        return(
                            <BoxInvite key={index} icon={inviteBtns.icon} text={inviteBtns.text} para={inviteBtns.para} onClick={()=>console.log("invite sent to: ", inviteBtns.platform)} />
                        )
                    })
                }*/}

                    <Button type={"submit"} className={"w-100 btn-solid m-t-30"}>
                        Continue
                    </Button>
                    <div className={"mx-auto text-center mt-4"}>
                        <span onClick={()=> {
                            handleLogout()
                            // navigate('/')
                        }} style={{cursor: 'pointer'}}>
                            <span style={{marginRight: '10px'}}><LeftIcon /></span> Back to Sign In
                        </span>
                    </div>
                </form>
                <div className={"filler m-b-30"}></div>
            </div>
    );
}

export default AccountVerification;
