import React, {useContext, useEffect, useState, useMemo} from 'react'
import {Container, Row, Col, Form, FloatingLabel, FormLabel, Button} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {LeftIcon, SuccessNotificationIcon} from "../components/SvgComponents";
import {EmptyLocalStorage, GetCurrentUser, SetAuthUser} from "../../services/authentication.service";
import {Toast, UploadImageToS3} from "../../utils/helpers";
import {GetPages, UpdateProfile} from "../../services/user.service";
import countryList from 'react-select-country-list'

function Page() {
    let navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(false);
    const options = useMemo(() => countryList().getData(), [])
    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getPages();
    }, [])

    const getPages = async () => {
        setLoading(false)
        await GetPages({slug: params.slug}).then((data) => {
            if (data.status) {
                setPage(data.data)
                //console.log(data.data);
                setLoading(true)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            }
        })
    }

    return (
        loading &&
        <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'start',
            marginTop: '0px',
            padding: '20px'
        }}>
            <div className={"configureProfile PolicyPage"}>
                <Container>
                    <Row className={"m-b-20"}>
                        <Col xs={12} sm={12}>
                            <h1> {page?.title || ''}</h1>
                        </Col>
                        <Col xs={12} sm={12}>
                            <div dangerouslySetInnerHTML={{ __html: page?.content }} />
                            {/*<div>
                                {page?.content || ''}
                            </div>*/}
                        </Col>

                    </Row>

                </Container>
            </div>
        </div>
    )
}

export default Page
