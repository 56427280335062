import React, {useContext, useEffect, useState, useMemo} from 'react'
import {Container, Row, Col, Form, FloatingLabel, FormLabel, Button} from "react-bootstrap";
import ProfileImg from "../../assets/images/others/profileImage.png"
import {MdDeleteOutline} from "react-icons/md"
/*
* React tags input
* */
import {render} from 'react-dom';
import {WithContext as ReactTags} from 'react-tag-input';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {SuccessNotificationIcon} from "../components/SvgComponents";
import placeholderImg from "../../assets/images/others/profilePlaceholder.png"
import ProfileImgUser from "../../assets/images/others/profileUser.png";
import {Patterns, VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import {EmptyLocalStorage, GetCurrentUser, SetAuthUser} from "../../services/authentication.service";
import {GetProfession} from "../../services/profession.service";
import {Toast, UploadImageToS3} from "../../utils/helpers";
import {PostUserTags} from "../../services/user_tag.service";
import {UpdateProfile} from "../../services/user.service";
import {IsCustomerLogin} from "../../App";
import Navigation from "../components/navigations/Navigation";
import {createOrUpdateUser} from "../../services/firebase.service";
import countryList from 'react-select-country-list'
import moment from "moment";
import {constants} from "../../utils/constants";
import {GetCharity} from "../../services/charity.service";

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];


function ConfigureProfile() {
    let navigate = useNavigate();
    //const current_user = GetCurrentUser()
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [profile, setProfile] = useState(false);
    const [profession, setProfession] = useState(false);
    const [charity, showCharity] = useState(false);
    const [charities, setCharities] = useState([]);
    const options = useMemo(() => countryList().getData(), [])
    const [imageError, showImageError] = useState(false);
    const [messageLimit, showMessageLimit] = useState(false);
    const [socialAccount, showSocialAccount] = useState(false);
    const {register, setFocus, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getProfessionList();
        getCharityList()
    }, [])

    const getProfessionList = async () => {
        await GetProfession({'order-column': 'name', order: 'asc'}).then((data) => {
            if (data.status) {
                setProfession(data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const getCharityList = async () => {
        await GetCharity({'order-column': 'name', order: 'asc'}).then((data) => {
            if (data.status) {
                setCharities(data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const handleChange = (event) => {
        if (event.target.files[0]) {
            setProfile(URL.createObjectURL(event.target.files[0]));
        } else {
            setProfile(false);
        }
    }

    /*
    * React Tags Input ADD, delete and click
    * */
    const [tags, setTags] = React.useState([]);

    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    const RemoveTagComponent = (props) => {
        const {className, onRemove} = props;
        return (
            <button onClick={onRemove} className={className}>
                <MdDeleteOutline/>
            </button>
        )
    }
    /*
    * On form submit
    * */
    const onSubmit = async (data) => {
        //console.log(data);
        let tag_data = [];

        tags && tags.map((data) => {
            tag_data.push({
                user_id: isCustomerLogin.id,
                tag: data.text
            })
        })

        let params = {
            tags: tag_data,
            about: data.about,
            dob: data.dob,
            charity_mode: data.charity_mode ? 1 : 0,
            charity_id: data.charity_mode == 1 ? data.charity_id : null,
            location: data.location,
            latitude: '12.000',
            longitude: '12.000',
            unlimited_message: data.unlimited_message ? 1 : 0,
            //message_characters: data?.msgLimit || 0,
            message_price: data?.msgPrice || '',
            profession_id: data.profession,
            taking_messages: data.taking_messages ? 1 : 0,
            username: data.username,
            payment_link: data.payment_link,
            social_account: data.social_account,
            social_link: data.social_link,
            is_profile_complete: 1
        }

        if(data.unlimited_message && data.unlimited_message){
            params.message_characters = data.msgLimit
        }

        if (data.media && data.media.length > 0) {
            let uploadImage = await UploadImageToS3(data?.media);
            if (uploadImage) {
                if (!uploadImage.success) {
                    Toast(uploadImage.message, false);
                    return false;
                }

                params.image = uploadImage.data
            }
        } else {
            showImageError(true);
            setFocus('username');
            return false;
        }

        await UpdateProfile(isCustomerLogin.id, params).then(async (data) => {
            if (data.status) {
                setIsCustomerLogin(data.data)
                createOrUpdateUser(data.data).then(() => {
                    Toast('Account created successfully', true);
                    navigate("/dashboard");
                })
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error?.response?.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error?.response?.data?.message, false);
            }
        })
    }

    return (
        !isCustomerLogin ? navigate('/') :
            <div>
                <Navigation/>
                <div className={"configureProfile"}>
                    <Container>
                        <Row className={"m-b-30"}>
                            <Col xs={12} sm={12} md={{span: 4, offset: 1}} lg={{span: 4, offset: 1}}
                                 xl={{span: 4, offset: 1}}>
                                <h1>Configure Profile</h1>
                                {/*<p>Quam pulvinar nullam quam nunc.</p>*/}
                            </Col>
                            {/*<Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className={"text-end"}>
                            <button className={"skipLink"} onClick={() => navigate('/dashboard')}>Skip for later
                            </button>
                        </div>
                    </Col>*/}
                        </Row>
                        <form className={"formConfigureProfile"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col xs={12} sm={12} md={{span: 4, offset: 1}} lg={{span: 4, offset: 1}}
                                     xl={{span: 4, offset: 1}}>
                                    <img width="120px" height="120px" className={"img imgProfile"}
                                         src={profile ? profile : placeholderImg}
                                         alt={"Profile Img"} title={"Profile Img"} onError={({currentTarget}) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = placeholderImg;
                                    }}/>

                                    <Form.Group controlId="profileImg" className="LabelFile m-t-10"
                                                onChange={(e) => handleChange(e)}>
                                        <Form.Label>{profile ? "Change Image" : "+ Add Profile Picture *"}</Form.Label>
                                        <Form.Control type="file"
                                                      {...register("media",
                                                          {
                                                              required: {
                                                                  value: false,
                                                                  message: VALIDATIONS_TEXT.IMAGE_REQUIRED
                                                              },
                                                          })
                                                      }
                                                      accept="image/png, image/gif, image/jpeg"/>

                                        {!profile && imageError ?
                                            <span autofocus className={"validation-error d-block"} role="alert">Please upload your profile picture</span> : ""}

                                    </Form.Group>

                                    <div className={"m-t-40"}></div>
                                    <h3>Status</h3>
                                    <div className={"checkBox-container m-b-20"}>
                                        <div className={"checkBox-Group"}>
                                            <FormLabel className={""} htmlFor={"takingMessages"}>
                                                Taking Messages
                                            </FormLabel>
                                            <Form.Check
                                                type="switch"
                                                id="takingMessages"
                                                {...register("taking_messages")}
                                            />
                                        </div>
                                        {/*<p>Curabitur id ex et ex pharetra mollis.</p>*/}
                                    </div>
                                    <div className={"checkBox-container m-b-20"}>
                                        <div className={"checkBox-Group"}>
                                            <FormLabel className={""} htmlFor={"charityMode"}>
                                                Charity Mode
                                            </FormLabel>
                                            <Form.Check
                                                type="switch"
                                                onClick={(e) => {
                                                    e.target.checked ? showCharity(true) : showCharity(false)
                                                }}
                                                id="charityMode"
                                                {...register("charity_mode")}
                                            />
                                        </div>
                                        {/*<p>Curabitur id ex et ex pharetra mollis.</p>*/}

                                        {
                                            charity ?
                                                <div className={charity ? 'mb13 m-t-20' : 'mb13 d-none'}>
                                                    <FloatingLabel controlId="charity_id" label="Charity Partner *">
                                                        <Form.Select aria-label="charity_id" {...register("charity_id",
                                                            {
                                                                required: {
                                                                    value: true,
                                                                    message: VALIDATIONS_TEXT.CHARITY_REQUIRED
                                                                },
                                                            })
                                                        }>
                                                            <option value="">Select Chairty</option>
                                                            {
                                                                charities && charities.map((dt) => {
                                                                    return <option value={dt.id}>{dt.name}</option>
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    {errors.charity_id &&
                                                    <span className={"validation-error"}
                                                          role="alert">{errors.charity_id.message}</span>}
                                                </div> : ''
                                        }
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <h3 className={"m-b-10"}>About</h3>
                                    <div className={"mb13"}>
                                        <FloatingLabel
                                            controlId="userName"
                                            label="Username *"
                                        >
                                            <Form.Control type="text" placeholder="clauinfuencer"
                                                          maxLength={VALIDATIONS.USERNAME}
                                                          {...register("username",
                                                              {
                                                                  pattern: {
                                                                      value: Patterns.Username,
                                                                      message: VALIDATIONS_TEXT.USERNAME_VALID
                                                                  },
                                                                  minLength: {
                                                                      value: VALIDATIONS.USERNAME_MIN,
                                                                      message: VALIDATIONS_TEXT.USERNAME_MIN
                                                                  },
                                                                  maxLength: {
                                                                      value: VALIDATIONS.USERNAME,
                                                                      message: VALIDATIONS_TEXT.USERNAME_MAX
                                                                  },
                                                                  required: {
                                                                      value: true,
                                                                      message: VALIDATIONS_TEXT.USERNAME_REQUIRED
                                                                  },
                                                              })
                                                          }
                                            />
                                        </FloatingLabel>
                                        {errors.username &&
                                        <span className={"validation-error"}
                                              role="alert">{errors.username.message}</span>}
                                    </div>
                                    <div className={"mb13"}>
                                        <FloatingLabel controlId="dob" label="Date Of Birth *">
                                            <Form.Control type="date" autoComplete={"off"} placeholder="Date of birth"
                                                          className={"formDob"}
                                                //minDate={new Date()}
                                                          max={moment().subtract(constants.DATE_AGE_LIMIT, 'years').format('YYYY-MM-DD')}
                                                          {...register("dob",
                                                              {
                                                                  required: {
                                                                      value: true,
                                                                      message: VALIDATIONS_TEXT.DOB_REQUIRED
                                                                  },
                                                              })
                                                          }
                                            />
                                        </FloatingLabel>
                                        {errors.dob &&
                                        <span className={"validation-error"} role="alert">{errors.dob.message}</span>}
                                    </div>
                                    <div className={"mb13"}>
                                        <FloatingLabel controlId="location" label="Location *">
                                            <Form.Select aria-label="location *" {...register("location",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.LOCATION_REQUIRED
                                                    },
                                                })
                                            }>
                                                <option value=""> Location</option>
                                                {
                                                    options && options.map((dt) => {
                                                        return <option value={dt.label}>{dt.label}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                        {errors.location &&
                                        <span className={"validation-error"}
                                              role="alert">{errors.location.message}</span>}
                                    </div>

                                    <div className={"mb13"}>
                                        <FloatingLabel
                                            controlId="Profession"
                                            label="Profession *"
                                        >
                                            <Form.Select aria-label="profession *" {...register("profession",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.PROFESSION_REQUIRED
                                                    },
                                                })
                                            }>
                                                <option value="">Select Profession</option>
                                                {
                                                    profession && profession.map((data) => {
                                                        return (<option value={data.id}>{data.name}</option>)
                                                    })
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                        {errors.profession &&
                                        <span className={"validation-error"}
                                              role="alert">{errors.profession.message}</span>}
                                    </div>

                                    <div className={"mb13"}>
                                        <FloatingLabel
                                            controlId="payment_link"
                                            label="Paypal Email to Receive Payments *"
                                        >
                                            <Form.Control type="email" placeholder="payment link *"
                                                          maxLength="500"
                                                          {...register("payment_link",
                                                              {
                                                                  pattern: {
                                                                      value: Patterns.Email,
                                                                      message: VALIDATIONS_TEXT.EMAIL_VALID
                                                                  },
                                                                  required: {
                                                                      value: true,
                                                                      message: VALIDATIONS_TEXT.PAYMENT_LINK_REQUIRED
                                                                  },
                                                              })
                                                          }
                                            />
                                        </FloatingLabel>
                                        {errors.payment_link &&
                                        <span className={"validation-error"}
                                              role="alert">{errors.payment_link.message}</span>}
                                    </div>

                                    <div className={"mb13"}>
                                        <FloatingLabel
                                            controlId="social_account"
                                            label="Platform for Verification *"
                                            onChange={(e) => {
                                                e.target.value.length > 0 ? showSocialAccount(true) : showSocialAccount(false)
                                            }}
                                        >
                                            <Form.Select
                                                aria-label="social_account *"
                                                {...register("social_account",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.SOCIAL_REQUIRED
                                                        },
                                                    })
                                                }>
                                                <option value="">Select Platform</option>
                                                <option value="facebook">Facebook</option>
                                                <option value="twitter">Twitter</option>
                                                <option value="snapchat">Snapchat</option>
                                                <option value="instagram">Instagram</option>
                                                <option value="linkedIn">LinkedIn</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                        <small style={{fontSize:'12px', fontWeight:'500'}}>In order to verify your account, we need to contact you on one of your verified social accounts</small>
                                                <br/>
                                        {errors.social_account &&
                                        <span className={"validation-error"}
                                              role="alert">{errors.social_account.message}</span>}
                                    </div>

                                    {
                                        socialAccount ?
                                            <div className={"mb13"}>
                                                <FloatingLabel
                                                    controlId="social_link *"
                                                    label="Social Link"
                                                >
                                                    <Form.Control type="text" placeholder="social link *"
                                                                  maxLength="500"
                                                                  {...register("social_link",
                                                                      {
                                                                          pattern: {
                                                                              value: Patterns.Url,
                                                                              message: VALIDATIONS_TEXT.URL_VALID
                                                                          },
                                                                          required: {
                                                                              value: true,
                                                                              message: VALIDATIONS_TEXT.SOCIAL_LINK_REQUIRED
                                                                          },
                                                                      })
                                                                  }
                                                    />
                                                </FloatingLabel>
                                                <small style={{fontSize:'12px', fontWeight:'500'}}>We will need this social account to contact you to confirm your identity</small>
                                                <br/>
                                                {errors.social_link &&
                                                <span className={"validation-error"}
                                                      role="alert">{errors.social_link.message}</span>}
                                            </div> : ''
                                    }

                                    <div className={"mb13"}>
                                        <FloatingLabel controlId="about" label="Enter Bio *">
                                            <Form.Control
                                                as="textarea"
                                                type="text"
                                                placeholder="Nunc congue egestas turpis proin nulla. Dui duis ac consequat in. Et tristique consectetur at ultricies."
                                                rows={"5"}
                                                maxLength={VALIDATIONS.BIO_MAX}
                                                {...register('about', {
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.BIO_REQUIRED
                                                    },
                                                    maxLength: {
                                                        value: VALIDATIONS.BIO_MAX,
                                                        message: VALIDATIONS_TEXT.BIO_MAX
                                                    },
                                                })}
                                            />
                                        </FloatingLabel>
                                        {errors.about &&
                                        <span className={"validation-error"} role="alert">{errors.about.message}</span>}
                                    </div>
                                    <div className={"m-t-30"}></div>
                                    <h3>Pricing Configuration</h3>
                                    <Row className={""}>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className={"mb13"}>
                                                <div className={"dualLabels"}>
                                                    <FloatingLabel
                                                        controlId="messagePrice"
                                                        label="Message Price *"
                                                    >
                                                        <Form.Control type="number" placeholder="20" min={"1"}
                                                                      max={"10000000"}
                                                                      {...register('msgPrice', {
                                                                          required: {
                                                                              value: true,
                                                                              message: VALIDATIONS_TEXT.MSG_REQUIRED
                                                                          },
                                                                      })}
                                                        />
                                                        <span>tokens</span>
                                                    </FloatingLabel>
                                                </div>
                                                {errors.msgPrice &&
                                                <span className={"validation-error"}
                                                      role="alert">{errors.msgPrice.message}</span>}
                                            </div>
                                        </Col>

                                        <div className={"checkBox-container m-b-20"}>
                                            <div className={"checkBox-Group"}>
                                                <FormLabel className={""} htmlFor={"unlimitedMessage"}>
                                                    Message Limit
                                                </FormLabel>
                                                <Form.Check
                                                    type="switch"
                                                    id="unlimitedMessage"
                                                    onClick={(e) => {
                                                        e.target.checked ? showMessageLimit(true) : showMessageLimit(false)
                                                    }}
                                                    {...register("unlimited_message")}
                                                />
                                            </div>
                                            {/*<p>Curabitur id ex et ex pharetra mollis.</p>*/}
                                        </div>

                                        {
                                            messageLimit ?
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <div className={"mb13"}>
                                                        <div className={"dualLabels"}>
                                                            <FloatingLabel
                                                                controlId="messageLimit"
                                                                label="Messages limit *"
                                                            >
                                                                <Form.Control type="number" placeholder="20" min={"1"}
                                                                              max={"1000"}
                                                                              {...register('msgLimit', {
                                                                                  required: {
                                                                                      value: true,
                                                                                      message: VALIDATIONS_TEXT.MSG_LIMIT_REQUIRED
                                                                                  },
                                                                              })}
                                                                />
                                                                <span>messages</span>
                                                            </FloatingLabel>
                                                        </div>
                                                        {errors.msgLimit &&
                                                        <span className={"validation-error"}
                                                              role="alert">{errors.msgLimit.message}</span>}
                                                    </div>
                                                </Col> : ''}
                                    </Row>
                                    <h3 className={"m-b-20 m-t-20"}>Tags</h3>
                                    <h4>Add Categories</h4>
                                    <div className={"mb13"}>
                                        <div className={"TagsContainer"}>
                                            <ReactTags
                                                tags={tags}
                                                delimiters={delimiters}
                                                handleDelete={handleDelete}
                                                handleAddition={handleAddition}
                                                handleTagClick={handleTagClick}
                                                removeComponent={RemoveTagComponent}
                                                inputFieldPosition="inline"
                                                inputProps={{
                                                    maxLength: "20",
                                                    autocomplete: "off"
                                                }}
                                                maxLength={"20"}
                                                allowUnique={true}
                                                autofocus={false}
                                                autocomplete
                                                {...register("user_tags")}
                                            />
                                        </div>
                                    </div>
                                    <div className={"m-t-20"}>
                                        <Button type={"submit"} className={"btn-solid btn-save ms-auto"}>
                                            Save
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </Container>
                </div>
            </div>
    )
}

export default ConfigureProfile
