import React, {useEffect, useState} from "react";
import {LeftIcon, SuccessIcon} from "../components/SvgComponents";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Button} from "react-bootstrap";
import {GetCurrentUser} from "../../services/authentication.service";
import {constants} from "../../utils/constants";


const Error404 = () => {
    const navigate = useNavigate();
    return (
        <div className={"codeSentSuccess"}>
            <div className={"mx-auto text-center"}>
                <div className={"m-b-20"}>
                    <h1 className={"error404Text"}>404</h1>
                </div>
                <h2>Feeling Lost?</h2>
                <p>Let us help you go back!!</p>
            </div>
            <div className={"mx-auto text-center m-t-40"}>
                <p className={"p-small"}><LeftIcon/> <span onClick={() => {
                    navigate('/')
                }} style={{cursor: 'pointer'}}>Go Back</span></p>
            </div>
        </div>
    )
}

const ProfileNotFound = () => {
    const navigate = useNavigate();
    return (
        <div className={"codeSentSuccess"}>
            <div className={"mx-auto text-center"}>
                {/*<div className={"m-b-20"}>
                    <h1 className={"error404Text"}>403</h1>
                </div>*/}
                <h2>Access Denied!</h2>
                <p>The profile can not be viewed. You need to login as a user.</p>
            </div>
            <div className={"mx-auto text-center m-t-40"}>
                <p className={"p-small"}><LeftIcon/> <span onClick={() => {
                    navigate('/')
                }} style={{cursor: 'pointer'}}>Go To Login</span></p>
            </div>
        </div>
    )
}

const ProfessionalNotFound = () => {
    const navigate = useNavigate();
    return (
        <div className={"codeSentSuccess"}>
            <div className={"mx-auto text-center"}>
                {/*<div className={"m-b-20"}>
                    <h1 className={"error404Text"}>403</h1>
                </div>*/}
                <h2>Access Denied!</h2>
                <p>The profile cannot be accessed by a professional account.</p>
            </div>
            <div className={"mx-auto text-center m-t-40"}>
                <p className={"p-small"}><LeftIcon/> <span onClick={() => {
                    navigate('/')
                }} style={{cursor: 'pointer'}}>Go Back</span></p>
            </div>
        </div>
    )
}


const renderComponent = (type) => {
    //console.log(type)
    if (type === "404") {
        return <Error404/>
    }
    if (type === "500") {
        return <ProfileNotFound/>
    }
    if (type === "600") {
        return <ProfessionalNotFound/>
    }
}

function Error({type}) {
    const isCustomerLogin = GetCurrentUser()
    const location = useLocation();
    const navigate = useNavigate();
    if(isCustomerLogin && isCustomerLogin.roles[0] && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL && location.pathname.toString().includes("user_profile")){
        return (
            <div className={"viewportFull"}>
                <div className={"d-flex align-items-center justify-content-center h-100"}>
                    <div className={"componentContainer"}>
                        <div className={"successPage"}>
                            {
                                renderComponent("600")
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (location.pathname.toString().includes("user_profile")) {
        return (
            <div className={"viewportFull"}>
                <div className={"d-flex align-items-center justify-content-center h-100"}>
                    <div className={"componentContainer"}>
                        <div className={"successPage"}>
                            {
                                renderComponent("500")
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={"viewportFull"}>
                <div className={"d-flex align-items-center justify-content-center h-100"}>
                    <div className={"componentContainer"}>
                        <div className={"successPage"}>
                            {
                                renderComponent(type)
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Error;
