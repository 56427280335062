import React, {useState, useCallback, useEffect} from 'react'
import {Container, Row, Col, DropdownButton, Dropdown, Button, Form} from "react-bootstrap"

import {
    ArchiveIcon,
    BlockIcon,
    CancelIcon,
    CircleVerticalIcon,
    ReportIcon,
    SuccessIcon,
    VerticalIcon
} from "../../components/SvgComponents";
import ThemeModal from "../../components/ThemeModal";
import {DeleteBlockUser, GetBlockUserById, PostBlockUser} from "../../../services/user.service";
import {Toast} from "../../../utils/helpers";
import {EmptyLocalStorage} from "../../../services/authentication.service";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {PostReport} from "../../../services/report.service";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../utils/constants";
import {GetReportType} from "../../../services/report_type.service";
import {constants} from "../../../utils/constants";
import {
    blockUnblockThread, checkDeleteUser,
    getImage, getThreadMessages,
    getUnreadCount,
    hideThread,
    isOnline,
    readChatMessages
} from "../../../services/firebase.service";
import {GetThreadProfileImage} from "./GetThreadProfileImage";
import {GetUserName} from "./GetUserName";
import ourChatAvatar from "../../../assets/images/others/our-chat-avatar.png"
import {PinnedTab} from "./PinnedTab";
import {GetUnreadCount} from "./GetUnreadCount";
import {GetIsOnline} from "./GetIsOnline";


function ChatList({
                      threads,
                      activeThread,
                      setActiveThread,
                      setActiveCustomer,
                      chatInput,
                      handleArchiveClick,
                      activeCustomerDetail,
                      isBlocked,
                      setIsBlocked,
                      isCustomerLogin,
                      search,
                      mobileSidebarActive,
                      setMobileSidebarActive,
                      welcomeScreen,
                      showWelcomeScreen,
                      location,
                      reportType,
                      setReportType,
                      searchMessage,
                      setaccLocked,
                      setLastMessage,
                      setMessages
                  }) {
    const navigate = useNavigate()
    const [modalBlockShow, setModalBlockShow] = useState(false);
    const [modalUnBlockShow, setModalUnBlockShow] = useState(false);
    const [userId, setUserId] = useState(null);
    const [chatId, setChatId] = useState(null);
    const [modalReportShow, setModalReportShow] = useState(false);
    //const [reportType, setReportType] = useState([]);
    const [loadImage, setLoadImage] = useState(false);
    const [ReportSuccessModalShow, setReportSuccessModalShow] = useState(false);
    const [checkDelete, setCheckDelete] = useState(false);

    const {register, handleSubmit, reset, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    /*useEffect(() => {
        getReportType();
    }, [])*/

    const handleActiveClick = useCallback(async (chat_id) => {
        await setActiveThread(chat_id)
    }, [setActiveThread])

    const handleActiveCustomer = useCallback(async (customer_id) => {
        await setActiveCustomer(customer_id)
        //console.log('here');
    }, [setActiveCustomer])

    const getMessages = async (customer_id, active_thread) => {
        //console.log(customer_id, active_thread, isCustomerLogin)
        if (active_thread, customer_id) {
            setaccLocked(false);
            setLastMessage(null);
            setMessages(null)
            await getThreadMessages(searchMessage, active_thread, customer_id, isCustomerLogin, (data) => {
                if (data) {
                    setMessages(data ? data : []);
                }
            });
        }
    }

    /*const getReportType = async () => {
        await GetReportType().then((data) => {
            if (data.status) {
                setReportType(data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }*/

    const handleBlockProfile = async (id, chat_id) => {
        await PostBlockUser(id).then((data) => {
            if (data.status) {
                Toast("User Blocked", true)
                setIsBlocked(true)
                setModalBlockShow(false)
                blockUnblockThread(activeThread, 1, activeCustomerDetail)
                //hideThread(chat_id, isCustomerLogin, '1')
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const handleUnBlockProfile = async (id) => {
        await DeleteBlockUser(id).then((data) => {
            if (data.status) {
                Toast("User UnBlocked", true)
                setIsBlocked(false)
                setModalUnBlockShow(false)
                blockUnblockThread(activeThread, 0, activeCustomerDetail)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const BlockModalContent = ({user_id, chat_id}) => {
        return (
            <div className={"blockProfile"}>
                <p className={"text-center"}>
                    Are you sure you want to<br/>
                    block this user?
                </p>
                <Row className={"g-3"}>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button type={"button"} className={"btn-solid active w-100"}
                                onClick={() => setModalBlockShow(false)}>
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button type={"button"} className={"btn-solid w-100"}
                                onClick={async () => {
                                    let checkDel = await checkDeleteUser(user_id);
                                    if (checkDel) {
                                        Toast(`This account has been deleted by the user`, false);
                                        return false;
                                    }
                                    handleBlockProfile(user_id, chat_id)
                                }}>
                            Block
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    const UnBlockModalContent = ({user_id}) => {
        return (
            <div className={"blockProfile"}>
                <p className={"text-center"}>
                    Are you sure you want to<br/>
                    unblock this user?
                </p>
                <Row className={"g-3"}>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button type={"button"} className={"btn-solid active w-100"}
                                onClick={() => setModalUnBlockShow(false)}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button type={"button"} className={"btn-solid w-100"}
                                onClick={async () => {
                                    let checkDel = await checkDeleteUser(user_id);
                                    if (checkDel) {
                                        Toast(`This account has been deleted by the user`, false);
                                        return false;
                                    }
                                    handleUnBlockProfile(user_id)
                                }}>
                            Unblock
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    const ReportModalContent = () => {
        return (
            <div className={"blockProfile"}>
                <p>
                    Reason to report
                </p>
                <form className={"contactSupport"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                    <Row className={"g-3 p-3"}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={"mb13"}>
                                <Form.Select id={"formLocation"}
                                             {...register("report_type_id",
                                                 {
                                                     required: {
                                                         value: true,
                                                         message: VALIDATIONS_TEXT.REPORT_TYPE_REQUIRED
                                                     },
                                                 })
                                             }>
                                    <option value="">Report Type</option>
                                    {
                                        reportType && reportType.map((type) => {
                                            return (
                                                <option value={type.id}>{type.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                                {errors.report_type_id &&
                                <span className={"validation-error"}
                                      role="alert">{errors.report_type_id.message}</span>}
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={"mb13"}>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    placeholder="Description"
                                    rows={"5"}
                                    {...register('description', {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.MESSAGE_REQUIRED
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.MESSAGE_MAX,
                                            message: VALIDATIONS_TEXT.MESSAGE_MAX
                                        },
                                    })}
                                />
                                {errors.description &&
                                <span className={"validation-error"}
                                      role="alert">{errors.description.message}</span>}
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button type={"submit"} className={"btn-solid w-100"}>
                                Report
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
        )
    }

    const ReportSuccessModal = () => {
        return (
            <div className={"SuccessModal codeSentSuccess py-5"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-20"}>
                        <SuccessIcon/>
                    </div>
                    <h2 className={"m-b-20"}><b>Thanks for reporting</b></h2>
                    <p className={"m-b-20"}>If we find this content to be in violation of our community guidelines we
                        will remove it.</p>
                    <Button className="btn-solid" onClick={() => setReportSuccessModalShow(false)}>Done</Button>
                </div>
            </div>
        )
    }

    const onSubmit = async (data) => {
        let checkDel = await checkDeleteUser(userId);
        if (checkDel) {
            Toast(`This account has been deleted by the user`, false);
            reset();
            setModalReportShow(false);
            return false;
        }

        await PostReport({
            report_type_id: data.report_type_id,
            description: data.description,
            instance_type: constants.ROLES.ROLE_PROFESSIONAL,
            instance_id: userId
        }).then(async (data) => {
            if (data.status) {
                // Toast(data.message, true);
                reset();
                setReportSuccessModalShow(true);
                setModalReportShow(false);
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    return (
        <>
            <ul className={"tilesList"}>
                <PinnedTab showWelcomeScreen={showWelcomeScreen}
                           setActiveThread={setActiveThread}
                           welcomeScreen={welcomeScreen}
                           isCustomerLogin={isCustomerLogin}
                           location={location}
                />
                {
                    threads && threads.map((thread, index) => {
                        return (
                            <li className={""}
                                key={index}
                                onClick={() => {
                                    if (chatInput.exists) {
                                        chatInput.current.value = ""
                                    }
                                    handleActiveClick(thread.chat_id)
                                    handleActiveCustomer(thread.user_id)
                                    getMessages(thread.user_id, thread.chat_id)
                                    setMobileSidebarActive(!mobileSidebarActive)
                                    showWelcomeScreen(false)
                                    readChatMessages(thread.chat_id, thread.user_id)
                                }}
                            >
                                <div
                                    className={`tileComponent ${!welcomeScreen && activeThread == thread.chat_id ? 'isOpen' : ''}`}>

                                    <div className={"profileImgContainer"}>
                                        <div className={"imgTile"}>
                                            <GetThreadProfileImage search={search} user_id={thread.user_id}/>
                                            <GetIsOnline search={search} user_id={thread.user_id}/>
                                        </div>
                                    </div>
                                    <div className={"profileDetails"} style={{width: '100%'}}>
                                        <div className={"profileDetailsHeader"}>
                                            <GetUserName search={search} user_id={thread.user_id}/>
                                            {/*<div className={"profileName"}>{thread?.username.substring(0, 12)}</div>*/}
                                            <div className={"activeStatus"}>{thread.created_ago}</div>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            fontSize: '12px'
                                        }}>
                                            <p>{thread.last_message.substring(0, 25)}{thread.last_message && thread.last_message.length >= 25 ? '...' : ''}</p>
                                            {
                                                <GetUnreadCount search={search} thread={thread}/>
                                            }
                                        </div>


                                        <div className={"dropdownContainer"}>
                                            <DropdownButton id="chatListSettings" align={"end"}
                                                            title={<CircleVerticalIcon/>}>
                                                {/*<Dropdown.Item as="button"
                                                               onClick={() => handleArchiveClick()}><ArchiveIcon/> Archive</Dropdown.Item>*/}
                                                {
                                                    isBlocked ?
                                                        <Dropdown.Item as="button" onClick={() => {
                                                            setUserId(thread.user_id)
                                                            setModalUnBlockShow(true)
                                                        }}><BlockIcon/> Unblock</Dropdown.Item>
                                                        :
                                                        <Dropdown.Item as="button" onClick={() => {
                                                            setUserId(thread.user_id)
                                                            setModalBlockShow(true)
                                                            setChatId(thread.chat_id)
                                                        }}><BlockIcon/> Block</Dropdown.Item>
                                                }

                                                <Dropdown.Item as="button"
                                                               onClick={() => {
                                                                   setUserId(thread.user_id)
                                                                   setModalReportShow(true)
                                                               }}><ReportIcon/> Report</Dropdown.Item>

                                            </DropdownButton>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>

            <ThemeModal title={"Block User"} content={<BlockModalContent user_id={userId} chat_id={chatId}/>}
                        className={"blockUser"}
                        size={"sm"}
                        show={modalBlockShow}
                        onHide={() => {
                            setModalBlockShow(false)
                        }}/>

            <ThemeModal title={"Unblock User"} content={<UnBlockModalContent user_id={userId}/>} className={"blockUser"}
                        size={"sm"}
                        show={modalUnBlockShow}
                        onHide={() => {
                            setModalUnBlockShow(false)
                        }}/>

            <ThemeModal title={"Report User"} content={<ReportModalContent user_id={userId}/>} className={"reportUser"}
                        size={"md"}
                        show={modalReportShow}
                        onHide={() => {
                            setModalReportShow(false)
                        }}/>
            <ThemeModal title={""} content={<ReportSuccessModal/>} className={"addCardInfo"} size={"sm"}
                        show={ReportSuccessModalShow}
                        onHide={() => {
                            setReportSuccessModalShow(false)
                            reset()
                        }}/>
        </>
    )
}

export default ChatList
