import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {EmptyLocalStorage, ForgetPassword} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";
import {SuccessIcon} from "../components/SvgComponents";

export const ResetPasswordSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false)
    const [resend, setResend] = useState(false);
    const [counter, setCounter] = useState(10);


    useEffect(() => {
        if (!location?.state?.email) {
            navigate("/forgot-password")
        } else {
            setShow(true);
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (!resend) {
                setResend(true)
            }
        }, 10000)
    }, [resend])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCounter(counter - 1);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [counter]);

    const onResend = async (email) => {
        if (email) {
            setResend(true)
            setCounter(10)
            await ForgetPassword(email).then((data) => {
                if (data.status) {
                    Toast(data.message, true);
                    navigate("/reset-password-success", {
                        state: {
                            email: email
                        }
                    });
                } else {
                    Toast(data.message, false);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    if (show) {
        return (
            <div className={"SuccessModal codeSentSuccess"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-50"}>
                        <SuccessIcon/>
                    </div>
                    <h2>Check your email</h2>
                    <p>Please follow the instructions that we have<br/> sent to your email.</p>
                </div>
                <div className={"mx-auto text-center m-t-40"}>
                    {
                        resend ?
                            <p className={"p-small"}>Didn’t get the code? <span onClick={() => {
                                onResend(location.state.email)
                                setResend(false)
                            }} style={{fontWeight: 'bold', cursor: 'pointer'}}>Send again</span></p> :
                            <>
                                <p className={"p-small"}>Didn’t get the code? Send again</p>
                                <h1>{counter}</h1>
                            </>
                    }

                </div>
            </div>
        )
    }
}
