import React from 'react';
import {ClockIcon} from "./SvgComponents";
import {Link} from "react-router-dom";

function AccountNotification () {
    return (
        <div className={"AccountNotification"}>
            <div className={"d-flex align-items-center"}>
                <ClockIcon /> <span><b>Account is pending verification.</b> Don’t hesitate to <Link to={"/support"} className={"inlineLink"}>contact us</Link> if you have any issues or questions.</span>
            </div>
        </div>
    );
}

export default AccountNotification;
