import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row, Button, FormLabel, Form} from "react-bootstrap";
import {ArrowIcon, ChevronRight, Star} from "../components/SvgComponents";
import AccSidebar from "../components/AccSidebar";
import {ToastContainer, toast} from 'react-toastify';
import ThemeModal from "../components/ThemeModal";
import Feed1Image from "../../assets/images/others/feed/feed1.png";
import Feed2Image from "../../assets/images/others/feed/feed2.png";
import Feed3Image from "../../assets/images/others/feed/feed3.png";
import Feed4Image from "../../assets/images/others/feed/feed4.png";
import Feed5Image from "../../assets/images/others/feed/feed5.png";
import {DeleteBlockUser, UpdateProfile} from "../../services/user.service";
import {useNavigate} from "react-router-dom";
import {Toast} from "../../utils/helpers";
import {EmptyLocalStorage, GetCurrentUser} from "../../services/authentication.service";
import {GetBlockUsers} from "../../services/block_user.service";
import placeholderImg from "../../assets/images/others/image-not-found.png"
import {IsCustomerLogin} from "../../App";
import {constants} from "../../utils/constants";
import {blockUnblockProfile} from "../../services/firebase.service";

function BlockedUsers() {
    const navigate = useNavigate()
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [blockUserModalShow, setBlockUserModalShow] = useState(false)
    const [unblockModalShow, setUnblockModalShow] = useState(false)
    const [unblockId, setUnblockId] = useState('')
    const [blockedUserData, setBlockedUserData] = useState([])
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getBlockUser();
    }, [])

    const getBlockUser = async () => {
        await GetBlockUsers().then((data) => {
            if (data.status) {
                setBlockedUserData(data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    // const BlockUserModal = () => {
    //     return (
    //         <div className="BlockedUserModal">
    //             <p className="m-b-40 text-center">Once you block someone, that person can no longer view your profile or be able to chat.</p>
    //             {
    //                 blockedUserData && blockedUserData.length > 0 ? blockedUserData.map((data, index) => (
    //                     <div className="d-flex align-items-center justify-content-between mb-4">
    //                         <div className="d-flex align-items-center">
    //                             <div className="img-container">
    //                                 <img src={data.blocked_user && data.blocked_user.image ? data.blocked_user.image : placeholderImg}
    //                                      alt="Image1"
    //                                      className={"img-fluid"}
    //                                      onError={({currentTarget}) => {
    //                                          currentTarget.onerror = null; // prevents looping
    //                                          currentTarget.src = placeholderImg;
    //                                      }}/>
    //                             </div>
    //                             <div className="ms-3">
    //                                 <h5><b>{data.blocked_user ? data.blocked_user.username : ''}</b></h5>
    //                                 <span>{data.blocked_user && data.blocked_user.profession ? data.blocked_user.profession.name : ''}</span>
    //                             </div>
    //                         </div>
    //
    //                         <div>
    //                             <Button className="btn-rounded-outline"
    //                                     onClick={() => handleUnblockButtonClick(data.blocked_user_id)}>Unblock</Button>
    //                         </div>
    //                     </div>
    //                 )) : <small>No Blocked User Found</small>
    //             }
    //         </div>
    //     )
    // }

    const UnblockModal = () => {
        return (
            <div className="UnblockModal">
                <p className={"text-center"}>Are you sure you want to <br/> unblock this user? </p>
                <div class="m-t-20 m-b-20 d-flex align-items-center justify-content-center">
                    <Button disabled={disableButton} className="btn-rounded-outline"
                            onClick={() => handleUnBlockProfile()}>Unblock</Button>
                    <Button className="btn-solid" onClick={() => handleCancelButtonClick()}>Cancel</Button>
                </div>
            </div>
        )
    }

    const handleUnBlockProfile = async () => {
        setDisableButton(true)
        if (unblockId) {
            await DeleteBlockUser(unblockId).then((data) => {
                let id = parseInt(unblockId)
                if (data.status) {
                    Toast("User Unblocked", true);
                    //setBlockUserModalShow(false)
                    let activeThread = [];
                    if (isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER) {
                        activeThread = isCustomerLogin.id + '-' + id;
                        //hideThread(id + '-' + isCustomerLogin.id, isCustomerLogin, 0)
                    } else {
                        activeThread = id + '-' + isCustomerLogin.id;
                        //hideThread(isCustomerLogin.id + '-' + id, isCustomerLogin, 0)
                    }
                    blockUnblockProfile(activeThread, 0, id)

                    setUnblockModalShow(false)
                    setDisableButton(false)
                    getBlockUser();
                } else {
                    setDisableButton(false)
                    Toast(data.message, false);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    setDisableButton(false)
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    const handleUnblockButtonClick = (blocked_user_id) => {
        setUnblockId(blocked_user_id);
        //setBlockUserModalShow(false)
        setUnblockModalShow(true)
    }

    const handleCancelButtonClick = () => {
        //setBlockUserModalShow(true)
        setUnblockModalShow(false)
    }


    return (
        <div className={"mainDashboard accountSettings"}>
            <div className={"ProfileInformation"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row>
                                <Col xs={12} sm={12} md={5} lg={4} xl={4} className={"col-xxl-4 d-none d-md-block"}>
                                    <AccSidebar userData={isCustomerLogin}/>
                                </Col>
                                <Col xs={12} sm={12} md={7} lg={8} xl={{span: 7, offset: 1}}
                                     className={"col-xxl-7 offset-xxl-1"}>
                                    <h1 className={"m-b-30"}>Blocked Users</h1>

                                    <div className="BlockedUserModal">
                                        <p className="m-b-40">Once you block someone, that person can no longer view
                                            your profile or be able to chat.</p>
                                        {
                                            blockedUserData && blockedUserData.length > 0 ? blockedUserData.map((data, index) => (
                                                <div className="d-flex align-items-center justify-content-between mb-4">
                                                    <div className="d-flex align-items-center">
                                                        <div className="img-container">
                                                            <img
                                                                src={data.blocked_user && data.blocked_user.image ? data.blocked_user.image : placeholderImg}
                                                                alt="Image1"
                                                                className={"img-fluid"}
                                                                onError={({currentTarget}) => {
                                                                    currentTarget.onerror = null; // prevents looping
                                                                    currentTarget.src = placeholderImg;
                                                                }}/>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h5><b>{data.blocked_user ? data.blocked_user.name : ''}</b>
                                                            </h5>
                                                            <span>{data.blocked_user && data.blocked_user.profession ? data.blocked_user.profession.name : ''}</span>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <Button className="btn-rounded-outline"
                                                                onClick={() => handleUnblockButtonClick(data.blocked_user_id)}>Unblock</Button>
                                                    </div>
                                                </div>
                                            )) : <small>No Blocked User Found</small>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <ToastContainer/>
                </Container>
            </div>

            {/*<ThemeModal title={"Blocked Users"} content={<BlockUserModal/>} className={"addCardInfo"} size={"md"}*/}
            {/*            show={blockUserModalShow}*/}
            {/*            onHide={() => {*/}
            {/*                setBlockUserModalShow(false)*/}
            {/*            }}/>*/}

            <ThemeModal title={"Unblock Users"} content={<UnblockModal/>} className={"addCardInfo"} size={"sm"}
                        show={unblockModalShow}
                        onHide={() => {
                            setUnblockModalShow(false)
                        }}/>
        </div>
    );
}

export default BlockedUsers;
