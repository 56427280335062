import React from "react";
import {LeftIcon, SuccessIcon} from "../components/SvgComponents";
import {Link} from "react-router-dom";

export const PasswordChangeSuccess = () => {
    return (
        <div className={"passwordChangedSuccess"}>
            <div className={"mx-auto text-center"}>
                <div className={"m-b-50"}>
                    <SuccessIcon/>
                </div>
                <h2>Password has been changed</h2>
                {/*<p>Please follow the instructions that we have<br/> sent to your email and we’ll verify
                    your<br/> account in no time!</p>*/}
            </div>
            <div className={"mx-auto text-center m-t-40"}>
                <Link to={"/"} className={"backToBtn"}>
                    <LeftIcon/> Back to login
                </Link>
            </div>
        </div>
    )
}
