import React from "react";
import {CancelIcon} from "../../components/SvgComponents";
import {Dropdown, DropdownButton, Form, Button, Container} from "react-bootstrap";

export const SearchBar = ({searchBar, searchInputRef, setSearchMessage, setSearchReset, searchReset, setSearchBar}) => {
    return (
        <div className={`searchWindow ${!searchBar ? "d-none" : "d-block"}`}>
            <div className={"searchFlex"}>
                <div className={"searchContainer"}>
                    <Form.Control type={"text"}
                                  placeholder={"Find messages in the current conversation"}
                                  className={"searchBar"}
                                  ref={searchInputRef}
                                  autoFocus
                                  value={searchInputRef?.current?.value || ''}
                                  onChange={(e) => {
                                      if (e.target.value !== "") {
                                          setSearchMessage(e.target.value);
                                          setSearchReset(true)
                                      } else {
                                          setSearchMessage(null);
                                          setSearchReset(false)
                                      }
                                  }
                                  }
                    />
                    <span onClick={() => {
                        searchInputRef.current.value = ''
                        setSearchMessage(null);
                        setSearchReset(false)
                    }} className={!searchReset ? "d-none" : ""}>
                                                <CancelIcon/>
                                                </span>
                </div>
                {/*<div className={"searchNavigate"}>
                                            <span className={"searchResultCount"}>0/0</span>
                                            <div className={"down"}>
                                                <ChevronDownCircle/>
                                            </div>
                                            <div className={"up"}>
                                                <ChevronDownCircle/>
                                            </div>
                                        </div>*/}
                <div className={"searchCancel"} onClick={() => {
                    setSearchBar(false)
                    setSearchMessage(null);
                    searchInputRef.current.value = ''
                }}>
                    <p>Cancel</p>
                </div>
            </div>
        </div>
    )
}