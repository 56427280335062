import React, {useContext, useState} from 'react';
import {useForm} from "react-hook-form";
import {IsCustomerLogin} from "../../../../App";
import {Row, Col, Button, FormLabel, Form, FloatingLabel} from "react-bootstrap";
import {VALIDATIONS_TEXT} from "../../../utils/constants";

export const PriceConfiguration = ({updateProfile}) => {
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [messageLimit, showMessageLimit] = useState(isCustomerLogin?.unlimited_message == 1 ? true : false);
    const [disableButton, setDisableButton] = useState(false);
    const {register, handleSubmit, reset, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        let params = {
            unlimited_message: data.unlimited_message ? 1 : 0,
            message_price: data.msgPrice,
        }
        if (data.unlimited_message && data.unlimited_message) {
            params.message_characters = data.msgLimit
        }
        updateProfile(isCustomerLogin.id, params)
    }

    return (
        <form className={"profileInfoForm"} autoComplete={"off"}
              onSubmit={handleSubmit(onSubmit)}>
            {/*<h4>Pricing configuration</h4>*/}
            <Row className={""}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row className={""}>
                        <div className={"checkBox-container m-b-20"}>
                            <div className={"checkBox-Group"}>
                                <FormLabel className={""} htmlFor={"unlimitedMessage"}>
                                    Message Limit
                                </FormLabel>
                                <Form.Check
                                    defaultChecked={isCustomerLogin.unlimited_message}
                                    type="switch"
                                    id="unlimitedMessage"
                                    onClick={(e) => {
                                        if (e.target.checked) {
                                            showMessageLimit(true)
                                        } else {
                                            showMessageLimit(false)
                                        }
                                    }}
                                    {...register("unlimited_message")}
                                />
                            </div>
                            {/*<p>Curabitur id ex et ex pharetra mollis.</p>*/}
                        </div>

                        {
                            messageLimit || isCustomerLogin?.unlimited_message ?
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className={messageLimit ? 'mb13' : 'mb13 d-none'}>
                                        <div className={"dualLabels"}>
                                            <FloatingLabel
                                                controlId="messageLimit"
                                                label="Messages limit"
                                            >
                                                <Form.Control type="number"
                                                              placeholder="20"
                                                              min={"1"}
                                                              max={"1000"}
                                                    //disabled={messageLimit || isCustomerLogin?.unlimited_message == 1}
                                                              defaultValue={isCustomerLogin?.message_characters || ''}
                                                              {...register('msgLimit', {
                                                                  valueAsNumber: {
                                                                      value: true,
                                                                      message: "value should be a number only"
                                                                  },
                                                                  required: {
                                                                      value: true,
                                                                      message: VALIDATIONS_TEXT.MSG_LIMIT_REQUIRED
                                                                  },
                                                              })}
                                                />
                                                <span style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    zIndex: '99',
                                                    fontWeight: '400',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    color: '#060836',
                                                    opacity: '0.7'
                                                }}>messages</span>
                                            </FloatingLabel>
                                        </div>
                                        {errors.msgLimit &&
                                        <span className={"validation-error"}
                                              role="alert">{errors.msgLimit.message}</span>}
                                    </div>
                                </Col> : ''}
                    </Row>

                    <div className={"mb13"}>
                        <div className={"dualLabels"}>
                            <FloatingLabel
                                controlId="messagePrice"
                                label="Message Price"
                            >
                                <Form.Control type="number"
                                              placeholder="20" min={"1"}
                                              max={"10000000"}
                                              defaultValue={isCustomerLogin?.message_price || ''}
                                              {...register('msgPrice', {
                                                  valueAsNumber: {
                                                      value: true,
                                                      message: "value should be a number only"
                                                  },
                                                  required: {
                                                      value: true,
                                                      message: VALIDATIONS_TEXT.MSG_REQUIRED
                                                  },
                                              })}
                                />
                                <span style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    zIndex: '99',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: '#060836',
                                    opacity: '0.7'
                                }}>tokens</span>
                            </FloatingLabel>
                        </div>
                        {errors.msgPrice &&
                        <span className={"validation-error"}
                              role="alert">{errors.msgPrice.message}</span>}
                    </div>


                </Col>
            </Row>

            <Button disabled={disableButton} type={"submit"}
                    className={"btn-solid w-100 m-t-30"}>
                Save
            </Button>
        </form>
    )
}