import {constants, apiUrl} from '../utils/constants';
import {GET, POST, PUT} from './api.service.wrapper';

export const GetChatRequest = async (params) => {
    let response = await GET(apiUrl.chat_requests, params);
    return response;
}

export const PostChatRequest = async (data) => {
    let response = await POST(apiUrl.chat_requests, data);
    return response;
}

export const UpdateChatRequest = async (id, data) => {
    let response = await PUT(apiUrl.chat_requests, id, data);
    return response;
}

export const GetChatRequestLimit = async (params) => {
    let response = await GET(apiUrl.chat_request_limit, params);
    return response;
}

export const UnlockChatRequest = async (data) => {
    let response = await POST(apiUrl.unlocked_chat_request, data);
    return response;
}

export const ChatNotifications = async (data) => {
    let response = await POST(apiUrl.chat_notifications, data);
    return response;
}


/*export const UpdateChatRequest = async (data) => {
    let response = await POST(apiUrl.update_chat_requests, data);
    return response;
}*/
