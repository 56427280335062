import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row, Button, FormLabel, Form} from "react-bootstrap";
import {ArrowIcon, ChevronRight, Star} from "../components/SvgComponents";
import AccSidebar from "../components/AccSidebar";
import {ToastContainer, toast} from 'react-toastify';
import ThemeModal from "../components/ThemeModal";
import Feed1Image from "../../assets/images/others/feed/feed1.png";
import Feed2Image from "../../assets/images/others/feed/feed2.png";
import Feed3Image from "../../assets/images/others/feed/feed3.png";
import Feed4Image from "../../assets/images/others/feed/feed4.png";
import Feed5Image from "../../assets/images/others/feed/feed5.png";
import {DeleteBlockUser, UpdateProfile} from "../../services/user.service";
import {useLocation, useNavigate} from "react-router-dom";
import {Toast} from "../../utils/helpers";
import {EmptyLocalStorage, GetCurrentUser} from "../../services/authentication.service";
import {GetBlockUsers} from "../../services/block_user.service";
import placeholderImg from "../../assets/images/others/image-not-found.png"
import {IsCustomerLogin} from "../../App";
import {constants} from "../../utils/constants";
import {blockUnblockProfile} from "../../services/firebase.service";

function PageSetting({}) {
    const navigate = useNavigate()
    const location = useLocation()
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location?.state?.page])

    return (
        <div className={"mainDashboard accountSettings"}>
            <div className={"ProfileInformation"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row>
                                <Col xs={12} sm={12} md={5} lg={4} xl={4} className={"col-xxl-4 d-none d-md-block"}>
                                    <AccSidebar userData={isCustomerLogin}/>
                                </Col>
                                <Col xs={12} sm={12} md={7} lg={8} xl={{span: 7, offset: 1}}
                                     className={"col-xxl-7 offset-xxl-1"}>
                                    {
                                        location && location?.state && location?.state?.page &&
                                        <>
                                            <h1 className={"m-b-30"}>{location?.state?.page?.title}</h1>

                                            <div className="BlockedUserModal">
                                                <div dangerouslySetInnerHTML={{ __html: location?.state?.page?.content }} />
                                            </div>
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <ToastContainer/>
                </Container>
            </div>
        </div>
    );
}

export default PageSetting;
