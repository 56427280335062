import React, {useState, useEffect} from 'react'
import {getUsername} from "../../../services/firebase.service";

export const GetUserName = ({user_id, search}) => {
    const [name, setName] = useState(null);

    useEffect(() => {
        getUsername(user_id, (data) => {
            setName(data)
        })
    })

    useEffect(() => {
        getUsername(user_id, (data) => {
            setName(data)
        })
    }, [search])

    return (
        <div className={"profileName"}>{name}</div>
    )
}
