import React, {useContext, useEffect, useLayoutEffect, useMemo, useState} from "react";
import {Col, Container, Row, Button, Form, FloatingLabel} from "react-bootstrap";
import {
    AddIcon,
    ApplePay,
    ArrowIcon,
    CancelCircle,
    ChevronRight,
    Star,
    SuccessIcon,
    VisaIcon
} from "../components/SvgComponents";
import AccSidebar from "../components/AccSidebar";
import {ToastContainer, toast} from 'react-toastify';
import ThemeModal from "../components/ThemeModal";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import {useForm} from "react-hook-form";
import {IsCustomerLogin} from "../../App";
import {constants} from "../../utils/constants";
import {GetWithDrawRequest, PostWithDrawRequest} from "../../services/withdrawal_request.service";
import {EmptyLocalStorage} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";
import {useLocation, useNavigate} from "react-router-dom";
import {DeleteUserCard, GetProfile, GetUserCards, PostUserCards} from "../../services/user.service";
import {GetTokens, PurchaseTokens, PurchaseTokensWithTax} from "../../services/token.service";
import {useStripe, useElements, CardElement} from "@stripe/react-stripe-js";
import moment from "moment";
import {updateTokens} from "../../services/firebase.service";
import {createBrowserHistory} from 'history';

const history = createBrowserHistory();

export const WithdrawalHistory = ({data, setData}) => {
    const navigate = useNavigate();


    const Status = ({status}) => {
        if (status == constants.CHAT_REQUEST_STATUS.REQUESTED) {
            return (<span className={"statusBar pending"}>
                                PENDING
                            </span>)
        } else if (status == constants.CHAT_REQUEST_STATUS.ACCEPTED) {
            return (<span className={"statusBar completed"}>
                                ACCEPTED
                            </span>)
        } else if (status == constants.CHAT_REQUEST_STATUS.LOCKED) {
            return (<span className={"statusBar completed"}>
                                LOCKED
                            </span>)
        }
    }

    return (
        <div className={"withdrawalHistory"}>
            <h1 className={"m-b-30"}>Pending Withdrawls</h1>
            <ul className={"list-unstyled list-withdrawls"}>
                {
                    data && data.length > 0 ?
                        data.map((dt, index) => {
                            return (
                                <li key={index}>
                                    <div
                                        className={"Box-withdraw d-flex align-items-center justify-content-between w-100"}>
                                        <p>
                                        <span
                                            className={"amount"}>${(parseInt(dt.tokens) * 0.01).toFixed(2)} • {dt.tokens} tokens</span>
                                            <br/>
                                            <span
                                                className={"date"}>{dt.created_at ? moment(dt.created_at).format('dddd, DD MMMM yy') : ''}</span>
                                        </p>

                                        <Status status={dt.status}/>
                                    </div>
                                </li>
                            )
                        })
                        : 'No Data Found'
                }
            </ul>
        </div>
    )
}

export const CardRadio = ({checked, setChecked, data, index, setSelectedCard}) => {
    return (
        /*<Form.Check type={"radio"}
                    //id={`payment${index}`}
                    className={"form-check-payments"}
                    name={"payment"}

        >*/
        <div className={"form-check-payments"} key={index}>
            <Form.Check.Label>
                <VisaIcon/>
                <sup>***</sup> {data.last_four}
            </Form.Check.Label>
            <div>
                <Form.Check
                    id={index}
                    type={"radio"}
                    name={"payment"}
                    value={index}
                    defaultChecked={checked === index}
                    /*onChange={(e) => {
                        console.log(data)
                        setSelectedCard(data)
                    }}*/
                    onChange={(e) => {
                        //console.log(e.target.value)
                        setSelectedCard(data)
                        setChecked(e.target.value);
                    }}
                />
            </div>
        </div>
    )
}

function Payment({updateCard}) {
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [cardModalShow, setCardModalShow] = useState(false);
    const [tokenModalShow, setTokenModalShow] = useState(false);
    const [withdrawTokenModalShow, setWithdrawTokenModalShow] = useState(false);
    const [paymentModalShow, setPaymentModalShow] = useState(false);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [successWithDrawModalShow, setSuccessWithdrawModalShow] = useState(false);
    const [zeroTokenModalShow, setZeroTokenModalShow] = useState(false);
    const [userCards, setUserCards] = useState([]);
    const [tokensList, setTokensList] = useState([]);
    const [purchaseToken, setPurchaseToken] = useState([]);
    const [selectedCard, setSelectedCard] = useState([]);
    const [token] = useState(isCustomerLogin.total_tokens);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteCardModalShow, setDeleteCardModalShow] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    const stripe = useStripe();
    const elements = useElements();
    const [checked, setChecked] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    const {register, handleSubmit, watch, reset, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getTokens()
    }, [])

    useEffect(() => {
        getUserCards()
    }, [updateCard, paymentModalShow])

    const [data, setData] = useState([]);

    useEffect(() => {
        getWithDrawData();
    }, [])

    /*useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status')
        if (status == 'cancel') {
            Toast('Payment Cancelled', false)
            queryParams.delete('cancel')
        }
        if (status == 'success') {
            Toast('Payment Successful', true)
            queryParams.delete('success')
        }

        history.replace({
            status: queryParams.toString(),
        });
    }, [])*/

    const getWithDrawData = async () => {
        await GetWithDrawRequest({is_mine: 1}).then((data) => {
            if (data.status) {
                setData(data.data.data)
                //console.log(data.data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    * Add new Card Details
    * */
    const onCardDetailsSubmit = async (event) => {
        event.preventDefault();
        event.target.disabled = true

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        });

        if (error) {
            event.target.disabled = false
            Toast(error.message, false);
            return false;
        }
        //console.log('paymentMethod', paymentMethod)
        if (paymentMethod) {
            setIsLoading(true)
            await PostUserCards({
                payment_method_id: paymentMethod, //'pm_card_visa'
            }).then((data) => {
                if (data.status) {
                    Toast(data.message, true);
                    getUserCards();
                    setCardModalShow(false);
                    setIsLoading(false);
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    const CardModalContent = () => {
        let style = {
            base: {
                iconColor: '#3D2570',
                color: '#3D2570',
                fontWeight: '500',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
                ':-webkit-autofill': {
                    fontWeight: '500',
                    color: '#3D2570',
                },
                '::placeholder': {
                    fontWeight: '500',
                    color: '#3D2570',
                },
            },
            invalid: {
                iconColor: '#E14339',
                color: '#E14339',
            },
        };
        return (
            <div className={"cardDetailsModal"}>
                <Form className={"addNewCardDetails"} autoComplete={"off"}>
                    <div className="p-3">

                        <CardElement options={{style: style}}
                                     className={`text-black themeCardStripe ${isLoading ? "d-none" : ''}`}/>
                        {
                            (isLoading)
                                ?

                                <Button className={"btn-solid w-100 btn-pay"}
                                        disabled={true}
                                >
                                    Processing...
                                </Button>

                                :

                                <Button className={"btn-solid w-100 btn-pay"}
                                        type="submit"
                                        onClick={(event) => {
                                            onCardDetailsSubmit(event)
                                        }}
                                        disabled={!stripe || !elements}>
                                    Add Card
                                </Button>
                        }

                    </div>
                </Form>
            </div>
        )
    }

    const handleTokenComponentClick = (data) => {
        setPurchaseToken(data);
        setTokenModalShow(false)
        setPaymentModalShow(true)
    }

    const handleBackButtonClick = () => {
        setTokenModalShow(true)
        setPaymentModalShow(false)
    }

    const handlePurchaseButtonClick = async () => {
        if (purchaseToken) {
            setIsLoading(true)
            await PurchaseTokensWithTax({
                token_id: purchaseToken.id,
                return_url: window.location.href
            }).then((data) => {
                if (data.status) {
                    setIsLoading(false)
                    //redirect to data.data.url
                    if (data.data.url) {
                        setPaymentModalShow(false)
                        //setSuccessModalShow(true)
                        setChecked(false)
                        getProfile(true);
                        window.location.replace(data.data.url);
                    } else {
                        Toast("Some error occured please refresh page and try again!", false);
                    }
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    /*const handlePurchaseButtonClick = async () => {
        if (purchaseToken, selectedCard) {
            setIsLoading(true)
            await PurchaseTokens({
                payment_method_id: selectedCard.payment_method_id,
                token_id: purchaseToken.id
            }).then((data) => {
                if (data.status) {
                    Toast(data.message, true);
                    getProfile(true);
                    setPaymentModalShow(false)
                    setSuccessModalShow(true)
                    setChecked(false)
                    setSelectedCard([])
                    setIsLoading(false)
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }*/

    const getProfile = async (purchase = false) => {
        await GetProfile().then(async (result) => {
            if (result.status) {
                if (result.data?.profile) {
                    if (purchase) {
                        updateTokens(result.data.profile.id, result.data.profile.total_tokens);
                    }
                    let dt = result.data?.profile;
                    if (dt) {
                        await setIsCustomerLogin(dt)
                    }
                }
            }
        }).catch((error) => {
            setIsCustomerLogin(null)
        })
    }

    const getUserCards = async () => {
        await GetUserCards().then(async (result) => {
            if (result.status) {
                setUserCards(result.data)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const getTokens = async () => {
        await GetTokens({status: 10}).then(async (result) => {
            if (result.status) {
                let tkn = [];
                result.data && result.data.map((dt) => {
                    tkn.push({
                        id: dt.id,
                        tokenCount: dt.tokens,
                        pricePerToken: (dt.total_amount / dt.tokens).toFixed(2),
                        //price: dt.amount,
                        price: dt.total_amount,
                        saved_amount: dt.saved_amount,
                        isRecommended: dt.recommended == 1 ? true : false
                    })
                })
                setTokensList(tkn)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const handleWithDrawClick = async (token) => {
        if (token > 0) {
            setDisableButton(true)
            await PostWithDrawRequest({tokens: token}).then((data) => {
                if (data.status) {
                    Toast(data.message, true);
                    getProfile(false);
                    getWithDrawData();
                    setSuccessWithdrawModalShow(true);
                    setWithdrawTokenModalShow(false);
                    setDisableButton(false)
                } else {
                    setDisableButton(false)
                    Toast(data.message, false);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    setDisableButton(false)
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    const TokenComponent = ({data, index}) => {
        return (
            <Form.Check type={"radio"} id={`token${index}`} className={"form-check-tokens"}>
                <Form.Check.Input className={data.isRecommended && "recommended"} type={"radio"}
                                  name={"selectedTokenPackage"}/>
                <Form.Check.Label className={data.isRecommended && "recommended"}>
                    <div className={"TokenCount"}>
                        <Star/>
                        <span className={"countContainer"}>
                            <span className={"count"}>{data.tokenCount}</span>
                            <span className={"text"}>tokens</span>
                        </span>
                    </div>
                    {/*<div className={"costPerToken"}>
                        <p>${data.pricePerToken} / token</p>
                    </div>*/}
                    <div className={"priceToken"}>
                        <p>
                            ${data.price.toFixed(2)} {/*<small>Save ${data.saved_amount}</small>*/}
                        </p>
                        <ArrowIcon/>
                    </div>
                </Form.Check.Label>
            </Form.Check>
        )
    }

    const PurchaseTokensModal = () => {
        return (
            <div className={"purchaseTokensModal"}>
                {/*<p>Quam pulvinar nullam quam nunc.</p>*/}
                <ul className={"list-unstyled listTokens"}>
                    {
                        tokensList.map((data, index) => (
                            <li key={index} onClick={() => handleTokenComponentClick(data)}>
                                <TokenComponent data={data} index={index}/>
                            </li>
                        ))
                    }

                </ul>
            </div>
        )
    }

    const WithDrawTokensModal = () => {
        return (
            <div className={"purchaseTokensModal text-center"}>
                <p>Are you sure want to withdraw?</p>
                <Button className="btn-rounded-outline me-3"
                        disabled={disableButton}
                        onClick={() => handleWithDrawClick(token)}>Yes</Button>
                <Button className="btn-rounded-outline me-3"
                        onClick={() => {
                            setWithdrawTokenModalShow(false)
                        }}>No</Button>
            </div>
        )
    }

    const PaymentModal = ({selectedCard, setSelectedCard, checked, setChecked}) => {
        return (
            <div className="mainDashboard accountSettings">
                <div className="ProfileInformation">
                    <div className="PaymentModal">
                        <div className="heading-container d-flex align-items-center justify-content-between py-2">
                            <div className="star-svg-container d-flex align-items-center">
                                <Star/>
                                <span className="ms-2"><b>{purchaseToken.tokenCount}</b> tokens</span>
                            </div>
                            <span><b>${purchaseToken.price.toFixed(2)}</b></span>
                        </div>

                        {/*<h4 className={"m-t-40 mb-4"}>Payment methods</h4>

                        <ul className={"list-unstyled listCards"}>
                            {
                                userCards && userCards.map((data, index) => {
                                    return (
                                        // <PaymentCardBox data={data} index={index} key={index}/>
                                        <li key={index}>
                                            <div className={"form-check-payments"}>
                                                <Form.Check.Label htmlFor={`payment-${index}`}>
                                                    <VisaIcon/>
                                                    <sup>***</sup> {data.last_four}
                                                </Form.Check.Label>
                                                <div>
                                                    <Form.Check.Input
                                                        autoFocus
                                                        type={"radio"}
                                                        id={`payment-${index}`}
                                                        name={"payment"}
                                                        value={data.id}
                                                        defaultChecked={selectedCard && selectedCard.id === data.id}
                                                        onChange={(e) => {
                                                            setChecked(!checked);
                                                            setSelectedCard(data)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                            <li>
                                <div className={"addNewPayment"}
                                     onClick={() => setCardModalShow(true)}>
                                    <div className={"svgContainer"}>
                                        <AddIcon/>
                                        <span><b>Add debit / credit card</b></span>
                                    </div>
                                    <div>
                                        <ArrowIcon/>
                                    </div>
                                </div>
                            </li>
                        </ul>*/}

                        <div className="btn-container d-flex align-items-center">
                            <Button className="btn-rounded-outline me-3"
                                    onClick={() => {
                                        setSelectedCard([])
                                        setChecked(false)
                                        handleBackButtonClick()
                                    }}>Back</Button>
                            <Button className="btn-solid" disabled={isLoading}
                                    onClick={() => {
                                        handlePurchaseButtonClick()
                                        /*if (selectedCard && selectedCard.id) {
                                            handlePurchaseButtonClick()
                                        } else {
                                            Toast("Please select a card.", false);
                                        }*/
                                    }}>Purchase {purchaseToken.tokenCount} tokens <small>(${purchaseToken.price.toFixed(2)})</small></Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const SuccessModal = () => {
        return (
            <div className={"SuccessModal codeSentSuccess py-5"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-20"}>
                        <SuccessIcon/>
                    </div>
                    <h2 className={"m-b-20"}><b>Great</b></h2>
                    <p className={"m-b-20"}>You have
                        successfully <br/> purchased <b>{purchaseToken.tokenCount} tokens</b></p>
                    <Button className="btn-solid" onClick={() => setSuccessModalShow(false)}>Done</Button>
                </div>
            </div>
        )
    }

    const SuccessWithdrawModal = () => {
        return (
            <div className={"SuccessModal codeSentSuccess py-5"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-20"}>
                        <SuccessIcon/>
                    </div>
                    <h2 className={"m-b-20"}><b>Great</b></h2>
                    <p className={"m-b-20"}>Your request is submitted <br/> to admin for token <b> withdrawal</b></p>
                    <Button className="btn-solid" onClick={() => {
                        setSuccessWithdrawModalShow(false)
                    }}>Done</Button>
                </div>
            </div>
        )
    }

    const ZeroTokenModal = () => {
        return (
            <div className={"purchaseTokensModal text-center"}>
                <p>No tokens are available for withdrawal</p>
            </div>
        )
    }

    const DeleteCardModal = () => {
        return (
            <div className={"purchaseTokensModal text-center"}>
                <p>Are you sure you want to <br/> remove this card? </p>
                <div class="m-t-20 m-b-20 d-flex align-items-center justify-content-center">
                    <Button disabled={isLoading} className="btn-rounded-outline"
                            onClick={() => onClickDeleteCard()}>Remove</Button>
                    <Button className="btn-rounded-outline" onClick={() => {
                        setDeleteCardModalShow(false)
                    }}>Cancel</Button>
                </div>
            </div>
        )
    }

    const onClickDeleteCard = async () => {
        if (selectedCard) {
            setIsLoading(true)
            await DeleteUserCard(selectedCard.id).then((data) => {
                if (data.status) {
                    Toast(data.message, true);
                    getProfile(false);
                    setDeleteCardModalShow(false)
                    setSelectedCard([])
                    getUserCards()
                    setIsLoading(false)
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }


    return (
        <div className={"mainDashboard accountSettings"}>
            <div className={"ProfileInformation"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row>
                                <Col xs={12} sm={12} md={5} lg={4} xl={4} className={"col-xxl-4 d-none d-md-block"}>
                                    <AccSidebar userData={isCustomerLogin}/>
                                </Col>
                                <Col xs={12} sm={12} md={7} lg={8} xl={{span: 7, offset: 1}}
                                     className={"col-xxl-7 offset-xxl-1"}>
                                    <h1 className={"m-b-30"}>Payment</h1>
                                    {
                                        isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL ?
                                            <>
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <div className={"tokenBox"}>
                                                            <div className={"tokenCountContainer"}>
                                                                <h5>Tokens balance</h5>
                                                                <p className={"tokenCount"}>
                                                                    <Star/> {isCustomerLogin?.total_tokens || 0}
                                                                </p>
                                                            </div>
                                                            <div className={"btnContainer"}>
                                                                <Button type={"button"} className={"btnTopup-link"}
                                                                        onClick={() => {
                                                                            if (isCustomerLogin?.total_tokens > 0) {
                                                                                setWithdrawTokenModalShow(true)
                                                                            } else {
                                                                                setZeroTokenModalShow(true)
                                                                            }
                                                                        }}>
                                                                    Withdraw All <ChevronRight/>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <div className={"tokenBox"}>
                                                            <div className={"tokenCountContainer"}>
                                                                <h5>Charity Tokens balance</h5>
                                                                <p className={"tokenCount"}>
                                                                    <Star/> {isCustomerLogin?.total_charity_tokens || 0}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr/>
                                                <WithdrawalHistory data={data} setData={setData}/>
                                            </>
                                            :
                                            <div className={"tokenBox"}>
                                                <div className={"tokenCountContainer"}>
                                                    <h5>Tokens balance</h5>
                                                    <p className={"tokenCount"}>
                                                        <Star/> {isCustomerLogin?.total_tokens || 0}
                                                    </p>
                                                </div>
                                                <div className={"btnContainer"}>
                                                    <Button type={"button"} className={"btnTopup"}
                                                            onClick={() => setTokenModalShow(true)}>
                                                        Top up <ChevronRight/>
                                                    </Button>
                                                </div>
                                            </div>
                                    }


                                    {
                                        isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ?
                                            <div>
                                                {/*<h4 className={"m-t-40 mb-4"}>Payment methods</h4>
                                                <ul className={"list-unstyled listCards"}>
                                                    {
                                                        userCards && userCards.length > 0 ? userCards?.map((data, key) => {
                                                            return (
                                                                    <li key={key}>
                                                                        <Form.Check type={"radio"} id={"applePay"}
                                                                                    className={"form-check-payments"}>
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                alignItems: 'baseline',
                                                                                width: '100%',

                                                                            }}>
                                                                                <Form.Check.Label>
                                                                                    <VisaIcon/>
                                                                                    <sup>***</sup> {data.last_four}
                                                                                </Form.Check.Label>
                                                                                <div style={{cursor: 'pointer'}}
                                                                                     onClick={() => {
                                                                                         setSelectedCard(data)
                                                                                         setDeleteCardModalShow(true);
                                                                                     }}>
                                                                                    <CancelCircle/>
                                                                                </div>
                                                                            </div>
                                                                        </Form.Check>
                                                                    </li>
                                                            )
                                                        }) : <div className={'m-b-40'}>No Card Found</div>
                                                    }
                                                    <li>
                                                        <div className={"addNewPayment"}
                                                             onClick={() => setCardModalShow(true)}>
                                                            <div className={"svgContainer"}>
                                                                <AddIcon/>
                                                                <span><b>Add debit / credit card</b></span>
                                                            </div>
                                                            <div>
                                                                <ArrowIcon/>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>*/}
                                            </div> : ''
                                    }

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <ToastContainer/>
                    <ThemeModal title={"Add Card Details"} content={<CardModalContent/>} className={"addCardInfo"}
                                size={"md"}
                                show={cardModalShow}
                                onHide={() => {
                                    setCardModalShow(false)
                                    reset()
                                }}/>
                    <ThemeModal title={"Purchase tokens"} content={<PurchaseTokensModal/>} className={"addCardInfo"}
                                size={"sm"}
                                show={tokenModalShow}
                                onHide={() => {
                                    setTokenModalShow(false)
                                    reset()
                                }}/>

                    <ThemeModal title={"Withdraw Token"} content={<WithDrawTokensModal/>} className={"addCardInfo"}
                                size={"sm"}
                                show={withdrawTokenModalShow}
                                onHide={() => {
                                    setWithdrawTokenModalShow(false)
                                    reset()
                                }}/>

                    <ThemeModal title={"Purchase tokens"}
                                content={<PaymentModal checked={checked} setChecked={setChecked}
                                                       selectedCard={selectedCard}
                                                       setSelectedCard={setSelectedCard}/>}
                                className={"addCardInfo"}
                                size={"sm"}
                                show={paymentModalShow}
                                onHide={() => {
                                    setPaymentModalShow(false)
                                    setChecked(false)
                                    setSelectedCard([])
                                    reset()
                                }}/>

                    <ThemeModal title={""} content={<SuccessModal/>} className={"addCardInfo"} size={"sm"}
                                show={successModalShow}
                                onHide={() => {
                                    setSuccessModalShow(false)
                                    reset()
                                }}/>

                    <ThemeModal title={""} content={<SuccessWithdrawModal/>} className={"addCardInfo"} size={"sm"}
                                show={successWithDrawModalShow}
                                onHide={() => {
                                    setWithdrawTokenModalShow(false)
                                    reset()
                                }}/>

                    <ThemeModal title={"Withdraw Token"} content={<ZeroTokenModal/>} className={"addCardInfo"}
                                size={"sm"}
                                show={zeroTokenModalShow}
                                onHide={() => {
                                    setZeroTokenModalShow(false)
                                    reset()
                                }}/>

                    <ThemeModal title={"Delete Card"} content={<DeleteCardModal/>} className={"addCardInfo"}
                                size={"sm"}
                                show={deleteCardModalShow}
                                onHide={() => {
                                    setDeleteCardModalShow(false)
                                    reset()
                                }}/>
                </Container>
            </div>
        </div>
    );
}

export default Payment;
