import {constants, apiUrl} from '../utils/constants';
import {GET, POST} from './api.service.wrapper';

export const GetTokens = async () => {
    const response = await GET(apiUrl.tokens);
    return response;
}

export const PurchaseTokens = async (params) => {
    const response = await POST(apiUrl.purchase_tokens, params);
    return response;
}

export const PurchaseTokensWithTax = async (params) => {
    const response = await POST(apiUrl.checkout, params);
    return response;
}
