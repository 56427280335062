import React, {useContext, useEffect, useRef, useState} from "react";
import {Dropdown, DropdownButton, Form, Button, Container} from "react-bootstrap";
import {constants} from "../../../utils/constants";
import {SendIcon} from "../../components/SvgComponents";
import {GetChatRequestLimit} from "../../../services/chat_request.service";
import {Toast} from "../../../utils/helpers";
import {EmptyLocalStorage} from "../../../services/authentication.service";
import {useNavigate} from "react-router-dom";

export const MessageInput = (props) => {

    const navigate = useNavigate();
    return (
        <div className={"chatInputContainer"}>
            <div className={`chatInput ${!props.inputFocus ? "w-100" : "focused"}`}>
                <Form.Control type={"text"}
                              ref={props.chatInput}
                              onChange={(e) => props.textCount(e.target.value.length)}
                              maxLength={constants.MESSAGE_CHARACTER_LIMIT}
                              disabled={props.textareaDisabled || props.activeCustomerDetail?.taking_messages == 0 || props.isBlocked || props.accLocked && props.isCustomerLogin && props.isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER || props.activeThreadData.block == 1 || props.isCustomerLogin && props.isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL && props.messageBar}
                              onKeyDown={props.onKeyDownMessage}
                              placeholder={props?.isCustomerLogin?.roles[0]?.id == constants.ROLES.ROLE_PROFESSIONAL ? "Select Message to Reply To" : "Send Message"}>
                </Form.Control>
                <span>{props.characterCount} / {constants.MESSAGE_CHARACTER_LIMIT} characters</span>
            </div>
            <div className={`SendBtn ${!props.inputFocus ? "d-none" : ""}`}>
                <Button className={`SendBtn ${!props.inputFocus ? "d-none" : ""}`} onClick={async () => {
                    let censoredWords = []
                    props.offensiveWords && props.offensiveWords.filter(word => {
                        if (props.chatInput.current.value.includes(word)) {
                            censoredWords.push(word)
                        }
                    });

                    if (censoredWords && censoredWords.length > 0) {
                        props.setOffensiveWordsForModal(censoredWords)
                        props.setOffensiveWordsModal(true);
                        return false;
                    }
                    if (props.isCustomerLogin && props.isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER) {
                        if (props.chatInput.current.value.length > 0) {
                            //console.log(props.message)
                            //Check Professional Has Message Limit
                            await GetChatRequestLimit({
                                professional_id: props.activeCustomerDetail.id
                            }).then(async (data) => {
                                if (data.status) {
                                    if (data.data.length == 0) {
                                        await props.setSubmitModalShow(true);
                                    } else {
                                        //Enable Message Limit Reach Popup
                                        props.setMessageReachedModal(true);
                                        return false;
                                    }
                                } else {
                                    Toast(data.message, false);
                                }
                            }).catch((error) => {
                                if (error?.response?.status == 401) {
                                    EmptyLocalStorage()
                                    navigate('/');
                                } else {
                                    return Toast(error?.response?.data?.message, false);
                                }
                            })

                        }
                    } else {
                        //console.log(props.message)
                        props.SubmitMessage(props.message)
                    }
                }}>
                    <SendIcon/>
                </Button>
            </div>
        </div>
    )
}
