import React, {useContext, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import authImg from "../../assets/images/auth/intro.png";
import authImg1 from "../../assets/images/auth/intro2.png";
import authImg2 from "../../assets/images/auth/intro3.png";
import authImg3 from "../../assets/images/auth/intro4.png";
import authImg4 from "../../assets/images/auth/intro5.png";
import authImg5 from "../../assets/images/auth/intro6.png";
import authImg6 from "../../assets/images/auth/intro7.png";
import authImg7 from "../../assets/images/auth/intro8.png";
import authImg8 from "../../assets/images/auth/intro9.png";
import logoImg from "../../assets/images/logo/logo.svg";
import {ToastContainer, toast} from 'react-toastify';
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {SignOutIcon} from "../components/SvgComponents";
import NavDropdown from "react-bootstrap/NavDropdown";
import {EmptyLocalStorage, GetAuthUser, Logout} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";
import {activeInactiveUser} from "../../services/firebase.service";
import {IsCustomerLogin} from "../../App";
import {Splash} from "../authentication/Splash";

function AuthLayout({user}) {
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const location = useLocation();
    const navigate = useNavigate();


    // console.log("location:", location);

    async function handleLogout() {
        await Logout().then(async (data) => {
            if (data.status) {
                await EmptyLocalStorage();
                Toast(data.message, true)
                activeInactiveUser(isCustomerLogin.id, false)
                setIsCustomerLogin(null)
                navigate('/');
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                //console.log(error);
                return Toast(error.response.data.message, false);
            }
        })
    }

    const renderLayoutImage = (pathname) => {
        if(pathname === "/" ){
            return <img src={authImg} alt={"Our Chat"} className={"img-fluid my-auto mx-auto"}/>
        }
        else if(pathname === "/sign-up" ){
            return <img src={authImg2} alt={"Our Chat"} className={"img-fluid my-auto mx-auto"}/>
        }
        else if(pathname === "/create-account-professional" ){
            return <img src={authImg3} alt={"Our Chat"} className={"img-fluid my-auto mx-auto"}/>
        }
        else if(pathname === "/forgot-password"){
            return <img src={authImg3} alt={"Our Chat"} className={"img-fluid my-auto mx-auto"}/>
        }
        else if(pathname === "/reset-password-success"){
            return <img src={authImg4} alt={"Our Chat"} className={"img-fluid my-auto mx-auto"}/>
        }
        else if(pathname === "/password-change-success"){
            return <img src={authImg5} alt={"Our Chat"} className={"img-fluid my-auto mx-auto"}/>
        }
        else{
            return <img src={authImg1} alt={"Our Chat"} className={"img-fluid my-auto mx-auto"}/>
        }
    }

    return (
        <div className="AuthLayout">
            <div className={"authenticationLayout"}>
                <div className={"left"}>
                    <div
                        className={`logoContainer ${location.pathname === "/account-verification-professional" ? "logoutOption" : ""}`}>
                        <Link to={"/"}>
                            <img src={logoImg} alt={"Our Chat"} className={"img-fluid"}/>
                        </Link>
                        {
                            location.pathname === "/account-verification-professional" || location.pathname === "/not-verified-account" ?
                                <Button style={{float:'right'}} type={"button"} className={"btn-logout"} onClick={handleLogout}>
                                    <SignOutIcon/> Logout
                                </Button>
                                : ''
                        }
                    </div>
                    <div className={"componentContainer"} style={{ alignItems: location.pathname === "/sign-up" ? 'unset' : 'center' }}>
                        <Outlet/>
                    </div>
                </div>
                <div className={"right"}>
                    {
                        renderLayoutImage(location.pathname)
                    }
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default AuthLayout;
