import React from "react";
import ourChatAvatar from "../../../assets/images/others/our-chat-avatar.png"

export const PinnedTab = (props) => {

    return (
        <li className={""} onClick={() => {
            props.showWelcomeScreen(true);
            props.setActiveThread(false);
        }}>
            <div className={`tileComponent ${props.welcomeScreen ? 'isOpen' : ''}`}>
                <div className={"profileImgContainer"}>
                    <div className={"imgTile"}>
                        <img className={"img-fluid"} src={ourChatAvatar} alt={"our chat"}
                             />
                    </div>
                </div>
                <div className={"profileDetails"}>
                    <div className={"profileDetailsHeader"}>
                        <div className={"profileName"}>OurChat Team</div>
                    </div>
                    <p>Welcome {props.isCustomerLogin?.name || ''}! <br/></p>
                </div>
            </div>
        </li>
    )
}