import React, {useContext, useEffect, useState} from 'react';
import LogoImg from '../../../assets/images/logo/logo.svg';
import {NavLink, useNavigate} from "react-router-dom";
import {
    InboxIcon,
    DashboardIcon,
    Star,
    SecurityIcon,
    PaymentIcon,
    SettingsIcon,
    ProfileInfoIcon, FaqIcon, SupportIcon, SignOutIcon, BlockSqIcon, InboxIconOutline, FeedIcon, LogoSvg
} from "../SvgComponents";
import ProfileIcon from "../../../assets/images/others/image-not-found.png"
import ProfileIconFallback from "../../../assets/images/others/profileUser.png"
import {EmptyLocalStorage, Logout} from "../../../services/authentication.service";
import {Toast} from "../../../utils/helpers";
import {IsCustomerLogin} from "../../../App";
import {constants} from "../../../utils/constants";
import {activeInactiveUser, get_chat_indicator, getUser, post_chat_indicator} from "../../../services/firebase.service";
import {Offcanvas, NavDropdown, Navbar, Nav, Container, Button} from "react-bootstrap";
import ThemeModal from "../ThemeModal";
import {auth} from "../../../utils/firebase-config";


const NavLinks = [
    {
        type: "link",
        url: "/",
        urlText: "Home",
        disabled: false
    },
    {
        type: "link",
        url: "/our-team",
        urlText: "Our Team",
        disabled: false
    },
    {
        type: "link",
        url: "/why-ourchat",
        urlText: "Why OurChat",
        disabled: false
    },
    {
        type: "link",
        url: "/contact",
        urlText: "Contact Us",
        disabled: false
    },
    {
        type: "dropdown",
        url: "/",
        urlText: "Services",
        dropdownItems: [{
            url: "/service-01",
            urlText: "Service 01",
            disabled: false
        },
            {
                url: "/service-02",
                urlText: "Service 02",
                disabled: true
            }],
    }
];

const NavBtns = [
    {
        btnType: "outline",
        btnText: "Login",
        btnUrl: "/login",
    },
    {
        btnType: "solid",
        btnText: "Sign up",
        btnUrl: "/sign-up",
    }
];

const RenderProfileTile = (name, icon) => {
    return (
        <>
            <img className={"ProfileIcon"} src={icon} alt={""} title={""} onError={({currentTarget}) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = ProfileIcon;
            }}/>
            {name.substring(0, 15)}
        </>
    )
}

function ProfessionalNavigation({userData}) {
    const navigate = useNavigate();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [showOffCanvas, setShowOffCanvas] = useState(false);
    const [chatIndicator, setChatIndicator] = useState(false);

    useEffect(() => {
        if (userData) {
            get_chat_indicator(userData.id.toString(), (new_message_notify) => {
                setChatIndicator(new_message_notify)
            })
        }
    }, [])

    const handleOffCanvasSelect = (eventKey) => console.log(`selected ${eventKey}`);

    const handleClose = () => setShowOffCanvas(false);
    const toggleShow = () => setShowOffCanvas(!showOffCanvas);

    const [isLoading, setIsLoading] = useState(false);
    const [logoutModalShow, setLogoutModalShow] = useState(false);

    const LogoutModal = () => {
        return (
            <div className={"purchaseTokensModal text-center"}>
                <p>Are you sure want to logout? </p>
                <div class="m-t-20 m-b-20 d-flex align-items-center justify-content-center">
                    <Button disabled={isLoading} className="btn-rounded-outline"
                            onClick={() => handleLogout()}>Logout</Button>
                    <Button className="btn-rounded-outline" onClick={() => {
                        setLogoutModalShow(false)
                    }}>Cancel</Button>
                </div>
            </div>
        )
    }

    async function handleLogout() {
        await Logout().then(async (data) => {
            if (data.status) {
                //await auth.signOut();
                await EmptyLocalStorage();
                Toast(data.message, true);
                activeInactiveUser(isCustomerLogin.id, false)
                setIsCustomerLogin(null)
                navigate('/');
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                console.log(error);
                return Toast(error.response.data.message, false);
            }
        })
    }

    return (
        <>
            <Navbar bg="light" expand="lg" fixed="top" className={"ourChatNav ProfessionalNav d-none d-md-block"}>
                <Container fluid>
                    <NavLink
                        to={isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ? "/explore" : "/dashboard"}>
                        <img src={LogoImg} alt={"Our Chat"} className={"img-fluid"}/>
                    </NavLink>
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                        >
                            <NavLink
                                onClick={() => {
                                    post_chat_indicator(isCustomerLogin.id.toString(), 0)
                                }}
                                to={"/inbox/"} className={`nav-link inboxIco ${chatIndicator ? 'hasNewMessages' : ''}`}>
                                <span><InboxIconOutline/></span>
                                Inbox
                            </NavLink>
                            <NavLink to={"/dashboard"} className={"nav-link feedIco"}>
                                <FeedIcon/> Dashboard
                            </NavLink>
                        </Nav>
                        <div className="d-flex align-items-center">
                        <span className={"profileStats"} onClick={() => navigate('/payment')}>
                            <Star/> <span>{userData?.total_tokens || 0}</span>
                        </span>
                            <NavDropdown align={"end"}
                                         style={{paddingLeft: '20px'}}
                                         title={RenderProfileTile(userData?.name || 'Profile', userData?.image || ProfileIcon)}
                                         id={"ProfileDropdown"} className={"ProfileDropdown"} autoClose="auto">
                                <NavDropdown.Item onClick={() => navigate('/profile-information')}>
                                    <ProfileInfoIcon/> Profile
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/security')}>
                                    <SecurityIcon/> Security
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/payment')}>
                                    <PaymentIcon/> Payment
                                </NavDropdown.Item>
                                {/*<NavLink to={"/settings"} className={"dropdown-item"}>
                                <SettingsIcon/> Settings
                            </NavLink>*/}
                                <NavDropdown.Item onClick={() => navigate('/blocked-users')}>
                                    <BlockSqIcon/> Blocked Users
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item onClick={() => navigate('/faqs')}>
                                    <FaqIcon/> FAQ
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/support')}>
                                    <SupportIcon/> Contact Us
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item onClick={() => {setLogoutModalShow(true)}}>
                                    <SignOutIcon/> Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/*Mobile Nav Bar*/}
            <Navbar expand="lg" fixed="top" variant="light" bg="light" className={"ourChatNavMobile d-block d-md-none"}>
                <Container>
                    <Navbar.Brand href={isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ? "/explore" : "/dashboard"} className={"logoMobile"}>
                        <LogoSvg />
                    </Navbar.Brand>
                    <div className={"ms-auto mobileNavs"}>
                        <span className={"profileStats"} onClick={() => navigate('/payment')}>
                            <Star/> <span>{userData?.total_tokens || 0}</span>
                        </span>
                        <div onClick={()=>toggleShow()}>
                            <img className={"img-fluid profileImg"} src={userData?.image || ProfileIcon} />
                        </div>
                    </div>
                </Container>
            </Navbar>
            <Offcanvas show={showOffCanvas} onHide={handleClose} className={"offCanvasBar"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <LogoSvg />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className={"navOffCanvas"} variant="pills" activeKey="auto" autoClose={"auto"}
                         onSelect={handleOffCanvasSelect}>
                        <Nav.Item className={`inboxIcon ${chatIndicator ? "hasNewMessages" : ''}`}>
                            <Nav.Link eventKey="1" href={"/inbox"}>
                                <span><InboxIconOutline /></span> Inbox
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={"feedIcon"}>
                            <Nav.Link eventKey="2"  href={"/dashboard"}>
                                <FeedIcon /> Dashboard
                            </Nav.Link>
                        </Nav.Item>
                        {
                            isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ?
                                <Nav.Item>
                                    <Nav.Link eventKey="3" onClick={()=> {
                                        toggleShow()
                                        navigate('/myprofile')
                                    }}>
                                        <ProfileInfoIcon />  Profile
                                    </Nav.Link>
                                </Nav.Item> : <Nav.Item>
                                    <Nav.Link eventKey="3" onClick={()=> {
                                        toggleShow()
                                        navigate('/profile-information')
                                    }}>
                                        <ProfileInfoIcon />  Profile
                                    </Nav.Link>
                                </Nav.Item>
                        }

                        <Nav.Item>
                            <Nav.Link eventKey="4" onClick={()=> {
                                toggleShow()
                                navigate('/security')
                            }}>
                                <SecurityIcon /> Security
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="5" onClick={()=> {
                                toggleShow()
                                navigate('/payment')
                            }}>
                                <PaymentIcon /> Payment
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="6" onClick={()=> {
                                toggleShow()
                                navigate('/blocked-users')
                            }}>
                                <BlockSqIcon /> Blocked Users
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="7" onClick={()=> {
                                toggleShow()
                                navigate('/faqs')
                            }}>
                                <FaqIcon /> FAQ
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="8" onClick={()=> {
                                toggleShow()
                                navigate('/support')
                            }}>
                                <SupportIcon /> Support
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="9" onClick={handleLogout}>
                                <SignOutIcon /> Sign Out
                            </Nav.Link>
                        </Nav.Item>
                        {/*<NavDropdown title="Dropdown" id="nav-dropdown">*/}
                        {/*    <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>*/}
                        {/*    <NavDropdown.Item eventKey="4.2">Another action</NavDropdown.Item>*/}
                        {/*    <NavDropdown.Item eventKey="4.3">Something else here</NavDropdown.Item>*/}
                        {/*    <NavDropdown.Divider />*/}
                        {/*    <NavDropdown.Item eventKey="4.4">Separated link</NavDropdown.Item>*/}
                        {/*</NavDropdown>*/}
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
            <ThemeModal title={"Logout"} content={<LogoutModal/>} className={"addCardInfo"}
                                size={"sm"}
                                show={logoutModalShow}
                                onHide={() => {
                                    setLogoutModalShow(false)
                                }}/>
            {/*Mobile Nav Bar*/}
        </>
    );
}

export default ProfessionalNavigation;
