import React, {useRef, useState, useMemo, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Form, InputGroup, Button, Col, Row} from "react-bootstrap"
import {AiFillEyeInvisible, AiFillEye} from "react-icons/ai"
import ProfileImgUser from "../../assets/images/others/profileUser.png";
import {useForm, Controller} from "react-hook-form";
import {toast} from "react-toastify";
import {SuccessNotificationIcon} from "../components/SvgComponents";
import {Patterns, VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import placeholderImg from "../../assets/images/others/profilePlaceholder.png"
import {CheckEmail, EmptyLocalStorage, Register} from "../../services/authentication.service";
import {constants} from "../../utils/constants";
import {Toast, UploadImageToS3} from "../../utils/helpers";
import {createOrUpdateUser} from "../../services/firebase.service";
import countryList from 'react-select-country-list'
import moment from "moment";
import {GetAvatar} from "../../services/avatar.service";
import ThemeModal from "../components/ThemeModal";
import Pages from "../authentication/Pages";
import Select from 'react-select'
import {Country, State, City} from 'country-state-city';

function CreateAccountUser() {
    const {register, handleSubmit, watch, control, setValue, formState: {errors}} = useForm({
        mode: "onSubmit",
        criteriaMode: "firstError",
        shouldFocusError: true,
    });
    const navigate = useNavigate();
    const [password, setPassword] = useState(false);
    const [cPassword, setCpassword] = useState(false);
    const [profile, setProfile] = useState(false);
    const options = useMemo(() => countryList().getData(), [])
    const [avatarModalShow, setAvatarModalShow] = useState(false);
    const [avatars, setAvatars] = useState([]);
    const [selectedAvatar, setSelectedAvatar] = useState('');
    const [privacyPolicy, showPrivacyPolicy] = useState(false);
    const [terms, showTerms] = useState(false);
    const [countryCode, setCountryCode] = useState('');
    const [location, setLocation] = useState('');
    const [stateRequired, setStateRequired] = useState('');
    const country = watch("country")
    const city = watch("city")
    const state = watch("state")

    useEffect(() => {
        setValue("state", null)
        setValue("city", null)
    }, [country])

    useEffect(() => {
        setValue("city", null)
    }, [state])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const onSubmit = async (data) => {
        let params = {
            'first_name': data.fName,
            'last_name': data.lName,
            'username': data.uName,
            'email': data.email,
            'dob': data.dob,
            'password': data.password,
            'password_confirmation': data.Cpassword,
            'location': data?.country?.value ? data.country.value : '',
            'latitude': '12.222',
            'longitude': '36.322',
            'role_id': constants.ROLES.ROLE_USER.toString(),
            'device_type': 'web',
            'device_token': 'xyz',
            'is_approved': 1,
            'is_verified': 1,
            'image': avatars[selectedAvatar]?.image,
            'address': data.address,
            'city': data?.city?.value ? data.city.value : '',
            'state': data?.state?.value ? data.state.value : '',
            'zip': data.zip,
            'country_code': data?.country?.isoCode ? data.country.isoCode : '',
            'state_code': data?.state?.isoCode ? data.state.isoCode : '',
        }

        /*if (data.media && data.media.length > 0) {
            let uploadImage = await UploadImageToS3(data?.media);
            if (uploadImage) {
                if (!uploadImage.success) {
                    Toast(uploadImage.message, false);
                    return false;
                }
                params.image = uploadImage.data;
            }
        }*/

        //Check email
        await CheckEmail({email: data.email}).then(async (data) => {
            if (data.status) {
                //Register User
                await Register(params).then(async (data) => {
                    if (data.status) {
                        //Add User In Firebase
                        let obj = {...data.data, ...{online: false}};
                        createOrUpdateUser(obj).then(() => {
                            Toast(data.message, true);
                            navigate('/account-created-success');
                        })
                    } else {
                        Toast(data.message, false);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        navigate('/');
                    } else {
                        return Toast(error.response.data.message, false);
                    }
                })
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const handleChange = (event) => {
        setAvatarModalShow(true);
        setProfile(selectedAvatar);
        /*if (event.target.files[0]) {
            setProfile(URL.createObjectURL(event.target.files[0]));
        } else {
            setProfile(false);
        }*/
    }

    const passwordWatch = useRef({});
    passwordWatch.current = watch("password", "");

    const RenderLabel = () => {
        return (
            <>
                I accept the <span style={{cursor: 'pointer', textDecoration: 'underline', color: '#3D2570'}}
                                   onClick={() => {
                                       showTerms(true);
                                       showPrivacyPolicy(false);
                                   }}>Terms of Service</span> and the<br/> <span
                style={{cursor: 'pointer', textDecoration: 'underline', color: '#3D2570'}}
                onClick={() => {
                    showPrivacyPolicy(true)
                    showTerms(false)
                }}>Privacy Policy</span>
            </>
        )
    }

    useEffect(() => {
        getAvatars();
    }, [])

    const getAvatars = async () => {
        await GetAvatar().then((data) => {
            if (data.status) {
                setAvatars(data?.data?.data);
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    // const AvatarRadios = ({img, value, name, index, setSelectedAvatar}) => {
    //     const handleAvatarClick = () => {
    //         setSelectedAvatar(value)
    //     }
    //     return (
    //         <Col sm={2}>
    //             <div className={"LabelAvatar"}>
    //                 <input type={"radio"} className={"inputAvatar"} id={`avatarInput${index}`} name={"avatarInput"}
    //                        value={value} aria-label={`option ${index}`}/>
    //                 <Form.Label htmlFor={`avatarInput${index}`} onClick={(value) => handleAvatarClick()}>
    //                     <img src={img} alt={name} title={name} className={"img-fluid"}/>
    //                 </Form.Label>
    //             </div>
    //         </Col>
    //     )
    // }
    //
    // const SelectAvatarModal = ({selectedAvatar, setSelectedAvatar}) => {
    //     const onSelect = () => {
    //         if (selectedAvatar) {
    //             setProfile(selectedAvatar);
    //             setAvatarModalShow(false)
    //         } else {
    //             setProfile(false);
    //             Toast("Select an avatar", false)
    //         }
    //     }
    //
    //     return (
    //         <div className={"AvatarSelectModal"}>
    //             <form className={"AvatarModalForm"}>
    //                 <Row className={"g-1"}>
    //                     {
    //                         avatars && avatars.map((avatar, index) => {
    //                             return (
    //                                 <AvatarRadios key={index}
    //                                               index={index}
    //                                               img={avatar.image}
    //                                               value={avatar.image}
    //                                               name={avatar.name}
    //                                               setSelectedAvatar={setSelectedAvatar}/>
    //                             )
    //                         })
    //                     }
    //                 </Row>
    //                 <Button type={"button"} className={"btn-solid w-100"} onClick={onSelect}>Select Avatar</Button>
    //             </form>
    //         </div>
    //     )
    // }

    const AvatarRadios = ({ img, name, index, isSelected, setSelectedAvatar }) => {
        const handleAvatarClick = () => {
            setSelectedAvatar(index);
        };

        return (
            <Col sm={2}>
            <div className={"LabelAvatar"}>
            <input
        type={"radio"}
        className={"inputAvatar"}
        id={`avatarInput${index}`}
        name={"avatarInput"}
        checked={isSelected}
        onChange={handleAvatarClick}
        aria-label={`option ${index}`}
        />
        <Form.Label htmlFor={`avatarInput${index}`} onClick={handleAvatarClick}>
            <img src={img} alt={name} title={name} className={"img-fluid"} />
        </Form.Label>
        </div>
        </Col>
    );
    };

    const SelectAvatarModal = ({ avatars, selectedAvatar, setSelectedAvatar }) => {
        const onSelect = () => {
            if (selectedAvatar !== null) {
                setProfile(avatars[selectedAvatar].image);
                setAvatarModalShow(false);
            } else {
                setProfile(false);
                Toast("Select an avatar", false);
            }
        };

        return (
            <div className={"AvatarSelectModal"}>
            <form className={"AvatarModalForm"}>
            <Row className={"g-1"}>
            {avatars.map((avatar, index) => (
                    <AvatarRadios
                key={index}
                index={index}
                img={avatar.image}
                name={avatar.name}
                isSelected={selectedAvatar === index}
        setSelectedAvatar={setSelectedAvatar}
        />
    ))}
    </Row>
        <Button type={"button"} className={"btn-solid w-100"} onClick={onSelect}>
            Select Avatar
        </Button>
        </form>
        </div>
    );
    };

    if (privacyPolicy) {
        return (
            <Pages slug={'privacy-policy'} showPrivacyPolicy={showPrivacyPolicy} showTerms={showTerms}/>
        )
    }

    if (terms) {
        return (
            <Pages slug={'terms-and-conditions'} showPrivacyPolicy={showPrivacyPolicy} showTerms={showTerms}/>
        )
    }

    return (
        <div className={"loginForm"} style={{marginTop: '20px'}}>
            <div className={""}></div>
            <h1>Create account</h1>
            {/*<p>Quam pulvinar nullam quam nunc.</p>*/}
            <div className={"m-t-40"}></div>
            <div className={"mx-auto text-center"}>
                <div className={"LabelFile"}>
                    <Form.Label style={{cursor: 'pointer'}} onClick={() => setAvatarModalShow(true)}>
                        <img width="150px" height="150px" className={"img-fluid imgProfile"}
                             src={profile || placeholderImg} alt={"Profile Img"} title={"Profile Img"}
                             onError={({currentTarget}) => {
                                 currentTarget.onerror = null; // prevents looping
                                 currentTarget.src = placeholderImg;
                             }}/>
                        <Form.Label
                            className={"labelFile d-block mt-3"}>{profile ? "Change Avatar" : "+ Add Avatar"}</Form.Label>
                    </Form.Label>
                </div>

                {/*<Form.Group controlId="profileImg" className="LabelFile m-t-10" onChange={(e) => handleChange(e)}>*/}
                {/*    <Form.Label>{profile ? "Change image" : "+ Add profile image"}</Form.Label>*/}
                {/*    <Form.Control type="file"*/}
                {/*                  accept="image/png, image/gif, image/jpeg"*/}
                {/*                  {...register("media",*/}
                {/*                      {*/}
                {/*                          required: {*/}
                {/*                              value: true,*/}
                {/*                              message: VALIDATIONS_TEXT.IMAGE_REQUIRED*/}
                {/*                          },*/}
                {/*                      })*/}
                {/*                  }*/}
                {/*    />*/}
                {/*</Form.Group>*/}
                {/*{errors.media &&*/}
                {/*<span className={"validation-error"} role="alert">{errors.media.message}</span>}*/}
            </div>

            <div className={"filler m-t-30"}></div>
            <form className={"m-t-40"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                <h3>Account Info</h3>
                <div className={"mb13"}>
                    <Form.Control type="text" autoComplete={"off"} placeholder="First Name" className={"formNameF"}
                                  {...register("fName",
                                      {
                                          maxLength: {
                                              value: VALIDATIONS.NAME,
                                              message: VALIDATIONS_TEXT.NAME_MAX
                                          },
                                          pattern: {
                                              value: /^[^\s]+(?:$|.*[^\s]+$)/,
                                              message: "Entered value cant start/end with white spacing"
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.FNAME_REQUIRED
                                          },
                                      })
                                  }/>
                    {errors.fName &&
                    <span className={"validation-error"} role="alert">{errors.fName.message}</span>}
                </div>

                <div className={"mb13"}>
                    <Form.Control type="text" autoComplete={"off"} placeholder="Last Name" className={"formNameL"}
                                  {...register("lName",
                                      {
                                          maxLength: {
                                              value: VALIDATIONS.NAME,
                                              message: VALIDATIONS_TEXT.NAME_MAX
                                          },
                                          pattern: {
                                              value: /^[^\s]+(?:$|.*[^\s]+$)/,
                                              message: "Entered value cant start/end with white spacing"
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.LNAME_REQUIRED
                                          },
                                      })
                                  }/>
                    {errors.lName &&
                    <span className={"validation-error"} role="alert">{errors.lName.message}</span>}
                </div>

                <div className={"mb13"}>
                    <Form.Control type="email" autoComplete={"off"} placeholder="Email address" className={"formemail"}
                                  {...register("email",
                                      {
                                          maxLength: {
                                              value: VALIDATIONS.EMAIL,
                                              message: VALIDATIONS_TEXT.EMAIL_MAX
                                          },
                                          pattern: {
                                              value: Patterns.Email,
                                              message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                          },
                                      })
                                  }/>
                    {errors.email &&
                    <span className={"validation-error"} role="alert">{errors.email.message}</span>}
                </div>

                <div className={"mb13"}>
                    <Form.Control type="text" autoComplete={"off"} placeholder="Username" className={"formUsername"}
                                  maxLength={VALIDATIONS.USERNAME}
                                  {...register("uName",
                                      {
                                          minLength: {
                                              value: VALIDATIONS.USERNAME_MIN,
                                              message: VALIDATIONS_TEXT.USERNAME_MIN
                                          },
                                          pattern: {
                                              value: Patterns.Username,
                                              message: VALIDATIONS_TEXT.USERNAME_VALID
                                          },
                                          maxLength: {
                                              value: VALIDATIONS.USERNAME,
                                              message: VALIDATIONS_TEXT.USERNAME_MAX
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.USERNAME_REQUIRED
                                          },
                                      })
                                  }
                    />
                    {errors.uName &&
                    <span className={"validation-error"} role="alert">{errors.uName.message}</span>}
                </div>

                <div className={"mb13"}>
                    <Form.Control type="date" autoComplete={"off"} placeholder="Date of birth"
                                  className={"safariFix formDob"}
                        //minDate={new Date()}
                                  max={moment().subtract(constants.DATE_AGE_LIMIT, 'years').format('YYYY-MM-DD')}
                                  {...register("dob",
                                      {
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.DOB_REQUIRED
                                          },
                                      })
                                  }
                    />
                    {errors.dob &&
                    <span className={"validation-error"} role="alert">{errors.dob.message}</span>}
                </div>

                <div className='mb13'>
                    <div className='select-box'>
                        <Controller
                        control={control}
                        name="country"
                        rules={{
                            required: {
                                value: true,
                                message: VALIDATIONS_TEXT.COUNTRY_REQUIRED,
                            },
                        }}
                        render={({field}) => (
                            <Select
                                {...field}
                                className="react-select-container w-100"
                                classNamePrefix="react-select"
                                placeholder="Select Country"
                                options={Country.getAllCountries().flatMap((item) => (
                                    {
                                        label: item?.name,
                                        value: item?.name,
                                        ...item,
                                    }
                                ))}
                            />
                        )}
                    />
                    </div>

                    {
                        errors.country &&
                        <span className={"validation-error"} role="alert">{errors.country.message}</span>}
                </div>

                <div className='mb13 select-box'>
                    <Controller
                        control={control}
                        name="state"
                        rules={{
                            required: {
                                value: false,
                                message: VALIDATIONS_TEXT.STATE_REQUIRED,
                            },
                        }}
                        render={({field}) => (
                            <Select
                                {...field}
                                className="react-select-container w-100"
                                classNamePrefix="react-select"
                                placeholder="Select State"
                                options={State.getStatesOfCountry(country?.isoCode).flatMap((item) => (
                                    {
                                        label: item?.name,
                                        value: item?.name,
                                        ...item,
                                    }
                                ))}
                            />
                        )}
                    />
                    {errors.state &&
                    <span className={"validation-error"} role="alert">{errors.state.message}</span>}
                </div>

                <div className='mb13 select-box'>
                    <Controller
                        control={control}
                        name="city"
                        rules={{
                            required: {
                                value: false,
                                message: VALIDATIONS_TEXT.CITY_REQUIRED,
                            },
                        }}
                        render={({field}) => (
                            <Select
                                {...field}
                                className="react-select-container w-100"
                                classNamePrefix="react-select"
                                placeholder="Select City"
                                options={City.getCitiesOfState(country?.isoCode, state?.isoCode).flatMap((item) => (
                                    {
                                        label: item?.name,
                                        value: item?.name,
                                        ...item,
                                    }
                                ))}
                            />
                        )}
                    />
                    {errors.city &&
                    <span className={"validation-error"} role="alert">{errors.city.message}</span>}
                </div>

                <div className={"mb13"}>
                    <Form.Control type="text" autoComplete={"off"} placeholder="Zip Code" className={"formemail"}
                                  {...register("zip",
                                      {
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.ZIP_REQUIRED
                                          },
                                      })
                                  }/>
                    {errors.email &&
                    <span className={"validation-error"} role="alert">{errors.zip.message}</span>}
                </div>

                <div className={"mb13"}>
                    <Form.Control type="text" autoComplete={"off"} placeholder="Address" className={"formemail"}
                                  {...register("address",
                                      {
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.ADDRESS_REQUIRED
                                          },
                                      })
                                  }/>
                    {errors.email &&
                    <span className={"validation-error"} role="alert">{errors.address.message}</span>}
                </div>

                {/*<div className={"mb13"}>*/}
                {/*    <Form.Control type="text" autoComplete={"off"} placeholder="City" className={"formemail"}*/}
                {/*                  {...register("city",*/}
                {/*                      {*/}
                {/*                          required: {*/}
                {/*                              value: true,*/}
                {/*                              message: VALIDATIONS_TEXT.CITY_REQUIRED*/}
                {/*                          },*/}
                {/*                      })*/}
                {/*                  }/>*/}
                {/*    {errors.email &&*/}
                {/*    <span className={"validation-error"} role="alert">{errors.city.message}</span>}*/}
                {/*</div>*/}

                {/*<div className={"mb13"}>*/}
                {/*    <Form.Control type="text" autoComplete={"off"} placeholder="State" className={"formemail"}*/}
                {/*                  {...register("state",*/}
                {/*                      {*/}
                {/*                          required: {*/}
                {/*                              value: true,*/}
                {/*                              message: VALIDATIONS_TEXT.STATE_REQUIRED*/}
                {/*                          },*/}
                {/*                      })*/}
                {/*                  }/>*/}
                {/*    {errors.email &&*/}
                {/*    <span className={"validation-error"} role="alert">{errors.state.message}</span>}*/}
                {/*</div>*/}

                {/*<div className={"mb13"}>*/}
                {/*    <Form.Select id={"formLocation"}*/}
                {/*                 {...register("location",*/}
                {/*                     {*/}
                {/*                         required: {*/}
                {/*                             value: true,*/}
                {/*                             message: VALIDATIONS_TEXT.LOCATION_REQUIRED*/}
                {/*                         },*/}
                {/*                     })*/}
                {/*                 }*/}
                {/*                 onChange={(e) => {*/}
                {/*                     const idx = e.target.selectedIndex;*/}
                {/*                     const option = e.target.querySelectorAll('option')[idx];*/}
                {/*                     const country_code = option.getAttribute('data-code');*/}
                {/*                     setCountryCode(country_code)*/}
                {/*                     setLocation(e.target.value)*/}
                {/*                 }}*/}
                {/*    >*/}
                {/*        <option value={""}>Country</option>*/}
                {/*        {*/}
                {/*            options && options.map((dt) => {*/}
                {/*                return <option data-code={dt.value} value={dt.label}>{dt.label}</option>*/}
                {/*            })*/}
                {/*        }*/}
                {/*    </Form.Select>*/}
                {/*    {errors.location &&*/}
                {/*    <span className={"validation-error"} role="alert">{errors.location.message}</span>}*/}
                {/*</div>*/}


                <div className={"filler m-t-30"}></div>
                <h3>Password</h3>
                <div className={"mb13"}>
                    <InputGroup className={"passwordGroup"}>
                        <Form.Control
                            type={password ? "text" : "password"} placeholder="Password"
                            aria-label="password"
                            autoComplete={"off"}
                            aria-describedby="inputGroup-sizing-sm"
                            className={"mb-0"}
                            {...register("password",
                                {
                                    pattern: {
                                        value: Patterns.Password,
                                        message: VALIDATIONS_TEXT.PASSWORD_VALID
                                    },
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                    },
                                })
                            }
                        />
                        <Button variant="outline-secondary" id="button-addon1" onClick={() => setPassword(!password)}>
                            {password ? <AiFillEyeInvisible/> : <AiFillEye/>}
                        </Button>
                    </InputGroup>
                    {errors.password &&
                    <span className={"validation-error"} role="alert">{errors.password.message}</span>}
                </div>
                <div className={"mb13"}>
                    <InputGroup className={"passwordGroup"}>
                        <Form.Control
                            type={cPassword ? "text" : "password"} placeholder="Repeat password"
                            aria-label="password"
                            autoComplete={"off"}
                            aria-describedby="inputGroup-sizing-sm"
                            className={"mb-0"}
                            {...register('Cpassword', {
                                required: {
                                    value: "required",
                                    message: "Confirm password is required"
                                },
                                validate: value => value === passwordWatch.current || "The passwords do not match",

                                minLength: {
                                    value: 8,
                                    message: "Min length is 8"
                                },
                                maxLength: {
                                    value: 255,
                                    message: "Max length is 255"
                                },
                            })}
                        />
                        <Button variant="outline-secondary" id="button-addon2" onClick={() => setCpassword(!cPassword)}>
                            {cPassword ? <AiFillEyeInvisible/> : <AiFillEye/>}
                        </Button>
                    </InputGroup>
                    {errors.Cpassword &&
                    <span className={"validation-error"} role="alert">{errors.Cpassword.message}</span>}
                </div>


                <ul className={"list-login list-unstyled"}>
                    <li className={"list-item "}>
                        <Form.Check
                            type={"checkbox"}
                            id={"consentPolicy"}
                            label={<RenderLabel/>}
                            className={"formCheckbox"}
                            {...register("consentPolicy",
                                {
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.POLICY_CONSENT
                                    },
                                })
                            }
                        />
                        {errors.consentPolicy &&
                        <span className={"validation-error"} role="alert">{errors.consentPolicy.message}</span>}
                    </li>
                    <li className={"list-item m-t-20"}>
                        <Form.Check
                            type={"checkbox"}
                            id={"consentAge"}
                            label={"I confirm I’m over 13 years old"}
                            className={"formCheckbox"}
                            {...register("consentAge",
                                {
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.AGE_CONSENT
                                    },
                                })
                            }
                        />
                        {errors.consentAge &&
                        <span className={"validation-error"} role="alert">{errors.consentAge.message}</span>}
                    </li>
                </ul>
                <Button type={"submit"} className={"w-100 btn-solid"}>
                    Create Account
                </Button>
            </form>
            <div className={"filler m-t-30"}></div>
            <p className={"text-center p-account"}>Already have an account? <Link to={"/"} className={"linkNav"}>Sign
                In</Link></p>
            <div className={"filler m-b-30"}></div>

            <ThemeModal title={"Select Avatar"} content={<SelectAvatarModal avatars={avatars} selectedAvatar={selectedAvatar}
                                                                            setSelectedAvatar={setSelectedAvatar}/>}
                        className={"addCardInfo"} size={"sm"}
                        show={avatarModalShow}
                        onHide={() => {
                            setAvatarModalShow(false)
                            // reset()
                        }}/>
        </div>
    );
}

export default CreateAccountUser;
