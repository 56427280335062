import {constants, apiUrl} from '../utils/constants';
import {DELETE, GET, POST} from './api.service.wrapper';

export const GetRecentSearches = async (params) => {
    let response = await GET(apiUrl.recent_searches, params);
    return response;
}

export const PostRecentSearches = async (params) => {
    let response = await POST(apiUrl.recent_searches, params);
    return response;
}

export const DeleteRecentSearches = async (id) => {
    let response = await DELETE(apiUrl.recent_searches, id);
    return response;
}
