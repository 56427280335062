import React, {useEffect, useState} from 'react'
import {Container, Row, Col, DropdownButton, Dropdown, Button, Form} from "react-bootstrap"
import {
    Dot,
    LeftIcon,
    Star,
    VerticalIcon,
    ChatIconOutline,
    BlockIcon,
    ReportIcon,
    EmptyListIcon, UnlockedIcon, WarningIcon, ClockIcon, CheckIcon
} from "../components/SvgComponents";
import {Link, useNavigate} from "react-router-dom"
import profileImg from "../../assets/images/others/image-not-found.png"
import ReadMore from "../components/ReadMore";
import ThemeModal from "../components/ThemeModal";
import {useParams} from "react-router-dom";
import ProfilePlaceholder from "../../assets/images/placeholders/placeholder1.png";
import Profile01 from "../../assets/images/others/feed/feed1.png";
import {GetUserById} from "../../services/user.service";
import moment from "moment";

function SingleProfile(props) {
    let navigate = useNavigate();
    let params = useParams();
    const [modalBlockShow, setModalBlockShow] = useState(false);
    const [modalReportShow, setModalReportShow] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [UserProfileDetails, setUserProfileDetails] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getProfile();
    }, [])

    const getProfile = async () => {
        await GetUserById(params.id).then(async (result) => {
            if (result.status) {
                if (result.data) {
                    let dt = result.data;

                    if (dt) {
                        await setUserProfileDetails({
                            profileId: dt?.id,
                            profileName: dt?.first_name + " " + dt?.last_name,
                            ageLimit: moment().diff(dt?.dob, 'years', false) <= 18 ? 'Under 18' : '',
                            userName: dt?.username || '',
                            country: dt?.location || '',
                            status: "active",
                            img: dt?.image ? dt?.image : profileImg,
                        })
                    }
                }
            }
        }).catch((error) => {
            setUserProfileDetails(null)
        })
    }

    const BlockModalContent = () => {
        return (
            <div className={"blockProfile"}>
                <p>
                    Are you sure you want to<br/>
                    block this user?
                </p>
                <Row className={"g-3"}>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button type={"button"} className={"btn-solid active w-100"}
                                onClick={() => setModalBlockShow(false)}>
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button type={"button"} className={"btn-solid w-100"} onClick={() => handleBlockProfile()}>
                            Block
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    const ReportModalContent = () => {
        return (
            <div className={"blockProfile"}>
                <p>
                    Reason to report
                </p>
                <form>
                    <Row className={"g-3 p-3"}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Check
                                reverse
                                label="Spam"
                                name="group1"
                                type={"checkbox"}
                                id={"checkboxSpam"}
                                className={"labelCheckbox"}
                            />
                            <Form.Check
                                reverse
                                label="False Information"
                                name="group1"
                                type={"checkbox"}
                                id={"checkboxFalseInfo"}
                                className={"labelCheckbox"}
                            />
                            <Form.Check
                                reverse
                                label="Harasement"
                                name="group1"
                                type={"checkbox"}
                                id={"checkboxHarasement"}
                                className={"labelCheckbox"}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button type={"submit"} className={"btn-solid w-100"} onClick={() => handleBlockProfile()}>
                                Report
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
        )
    }

    /*
    *  handle Block Click
    * */
    const handleBlockProfile = (id) => {
        console.log("Block profile Id: ", id);
    }
    /*
    *  handle Report Profile Click
    * */
    const handleReportProfile = (id) => {
        console.log("Report profile Id: ", id);
    }
    /*
   *  Request List
   * */
    const RequestList = [
        {
            isActive: true,
            isUnderAge: false,
            img: Profile01,
            name: "Logan Klitsch",
            lastSeen: "Now",
            lastMessage: "You: Curabitur id ex et ex pharetra mollis. Duis placerat, augue.",
            status: "completed"
        },
        {
            isActive: true,
            isUnderAge: true,
            img: Profile01,
            name: "Logan Paul",
            lastSeen: "Now",
            lastMessage: "You: Curabitur id ex et ex pharetra mollis. Duis placerat, augue.",
        }];

    /*
    *  status bar
    * */
    const StatusBar = ({type}) => {
        //console.log(type);
        if (type == "unlocked") {
            return (
                <span className={"statusBar unlocked"}>
                    <UnlockedIcon/> This chat has been unlocked.
                </span>
            )
        } else if (type == "urgent") {
            return (
                <span className={"statusBar urgent"}>
                    <WarningIcon/> Urgent response needed
                </span>
            )
        } else if (type == "timed") {
            return (
                <span className={"statusBar pending"}>
                    <ClockIcon/> You have 47h to reply
                </span>
            )
        } else if (type == "completed") {
            return (
                <span className={"statusBar completed"}>
                    <CheckIcon/> This chat is completed
                </span>
            )
        }
    }

    /*
    *  Request box
    * */
    const RequestBox = (props) => {
        // console.table(props);
        return (
            <li className={""}>
                <div className={`tileComponent ${props.isActive ? "isActive" : ""}`}>
                    <div className={"profileImgContainer"}>
                        <div className={"imgTile"}>
                            <img className={"img-fluid"} src={props.img ? props.img : ProfilePlaceholder} alt={"Logan"}
                                 title={"Logan"}/>
                        </div>
                    </div>
                    <div className={"profileDetails"}>
                        {
                            props.isUnderAge && <span className={"ageTag"}>Under 18</span>
                        }

                        <div className={"profileDetailsHeader"}>
                            {
                                props.name && <div className={"profileName"}>{props.name}</div>
                            }
                            {
                                props.lastSeen && <div className={"activeStatus"}>{props.lastSeen}</div>
                            }
                        </div>
                        {
                            props.lastMessage && <p>{props.lastMessage}</p>
                        }
                        {
                            props.statusBar && <StatusBar type={props.statusBar}/>
                        }
                    </div>
                </div>
            </li>
        )
    }

    return (
        <div className={"mainDashboard"}>
            <div className={"SingleFeedProfile"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row className={"m-b-30"}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div onClick={() => navigate(-1)} className={"linkText"}>
                                        <LeftIcon/> Back
                                    </div>
                                </Col>
                            </Row>

                            <Row className={"feedProfileBox"}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}
                                     className={"col-xxl-5 offset-xxl-1"}>
                                    <div className={"imgContainer"}>
                                        <img src={UserProfileDetails.img} alt={UserProfileDetails.profileName} title={UserProfileDetails.profileName}
                                             className={"img-fluid"}/>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}
                                     className={"col-xxl-5 offset-xxl-1"}>
                                    <div className={"profileDescription"}>
                                        <div className={"profileTitle"}>
                                            <div className={"titleContainer"}>
                                                <h1>{UserProfileDetails.profileName}</h1>
                                                {
                                                    UserProfileDetails.ageLimit &&
                                                    <span className={"tag"}>{UserProfileDetails.ageLimit}</span>
                                                }
                                            </div>
                                            <div className={"dropdownContainer"}>
                                                <DropdownButton id="dropdown-item-button" align={"end"}
                                                                title={<VerticalIcon/>}>
                                                    <Dropdown.Item as="button"
                                                                   onClick={() => setModalBlockShow(true)}><BlockIcon/> Block</Dropdown.Item>
                                                    <Dropdown.Item as="button"
                                                                   onClick={() => setModalReportShow(true)}><ReportIcon/> Report
                                                        profile</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </div>
                                    </div>


                                    <p className={"userName"}>{UserProfileDetails.userName}</p>
                                    <p className={"country"}>{UserProfileDetails.country}</p>

                                    <h4>Messages (2)</h4>
                                    <div className={`requestList ${isEmpty ? "isEmpty" : ""}`}>
                                        <div className={"EmptyIconBox h-100"}>
                                            <div
                                                className={"mx-auto text-center h-100 d-flex flex-column align-contents-center justify-content-center align-items-center"}>
                                                <EmptyListIcon/>
                                                <p>No request yet.</p>
                                            </div>
                                        </div>
                                        <div className={"requestsContainer"}>
                                            <ul className={"tilesList"}>
                                                {
                                                    RequestList.map((requests, index) => {
                                                        return (
                                                            <RequestBox
                                                                isActive={requests.isActive}
                                                                img={requests.img}
                                                                isUnderAge={requests.isUnderAge}
                                                                name={requests.name}
                                                                lastSeen={requests.lastSeen}
                                                                lastMessage={requests.lastMessage}
                                                                statusBar={requests.status}
                                                            />
                                                        )
                                                    })
                                                }
                                            </ul>
                                            <Button type={"button"} className={"grey-btn w-100"}>
                                                Load more
                                            </Button>
                                        </div>
                                    </div>


                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <ThemeModal title={"Block User"} content={<BlockModalContent/>} className={"blockUser"} size={"sm"}
                                show={modalBlockShow}
                                onHide={() => {
                                    setModalBlockShow(false)
                                }}/>

                    <ThemeModal title={"Report User"} content={<ReportModalContent/>} className={"reportUser"}
                                size={"sm"}
                                show={modalReportShow}
                                onHide={() => {
                                    setModalReportShow(false)
                                }}/>
                </Container>
            </div>
        </div>
    )
}

export default SingleProfile
