import React, {useState} from 'react'
import {updateReaction} from "../../../services/firebase.service";
import {ReactionBarSelector} from '@charkour/react-reactions';
import {FaRegSmile, FaUnlock, FaUndo} from "react-icons/fa";
import Emoji from 'react-emojis';
import {GetThreadProfileImage} from "./GetThreadProfileImage";
import {constants} from "../../../utils/constants";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {GetLockRefundButton} from "./GetLockRefundButton";
import {ShowReaction} from "./ShowReaction";

export const SentComponent = (props) => {
    const [reactVisible, setReactVisible] = useState(false);

    const updateReact = (reaction) => {
        let update_reactions = [];

        if (props.message.reactions) {
            update_reactions = props.message.reactions.filter(function (react) {
                return react.id !== props.isCustomerLogin.id
            })
        }

        let data = {
            activeThread: props.activeThread,
            timestamp: props.message.timestamp,
            reactions: [...update_reactions, {
                id: props.isCustomerLogin.id,
                name: props.isCustomerLogin.name || props.isCustomerLogin.username,
                react: reaction
            }]
        }
        updateReaction(data, (data) => {
            setReactVisible(false);
        })
    }

    /*const renderEmoji = (props) => {
        return (
            <Tooltip id="emoji" {...props}>{props.name}</Tooltip>
        )
    };*/

    return (
        <li className={`full-msg sent /*isActive*/`} onMouseLeave={() => setReactVisible(false)}>
            <div className={"MessageTile"}>
                <div className={"imgContainer"}>
                    <div className="imgTile">
                        <GetThreadProfileImage user_id={props.img}/>
                    </div>
                </div>

                <div className={"MessageContainer"}>
                    {
                        props.isCustomerLogin && props.isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL ?
                            <div className={"replyOnBox"}>
                                <div className={"repliedMessage"}>
                                    {props.message.messageReply ? props.message.messageReply : ''}
                                </div>
                            </div> : ''
                    }

                    <p>{props.content}</p>
                    <div className={"timeStamp"}>
                        <span
                            className={"messageTime"}>{moment(props.message.timestamp).format("YYYY-MM-DD hh:mm a")}</span>
                    </div>

                    <div className={`ReactionBar ${!reactVisible ? "d-none" : ""}`}>
                        <ReactionBarSelector
                            iconSize={16}
                            active={props.reaction}
                            onSelect={(reactions) => {
                                updateReact(reactions)
                            }}
                        />
                    </div>
                    <ShowReaction props={props} />
                </div>

                <div style={{display: `${props.isBlocked ? 'none' : ''}`}} className={"MessageOptions"}>
                    <div className={"emoji"} onClick={() => setReactVisible(!reactVisible)}
                         onBlur={() => setReactVisible(!reactVisible)}>
                        <FaRegSmile/>
                    </div>
                </div>
                <GetLockRefundButton customerLogin={props.isCustomerLogin}
                                     message={props.message}
                                     activeThread={props.activeThread}
                />

                {/*<div className={"MessageOptions"} onClick={() => {
                    setReportMessage(props.content)
                    setModalReportShow(true)
                }}>
                    <TbMessageReport style={{fontSize:'20px'}}/>
                </div>*/}
            </div>

        </li>
    )
}
