import React, {useCallback, useContext, useEffect, useState} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {
    ArrowIcon, BlockIcon, BlockSqIcon,
    FaqIcon,
    PaymentIcon,
    ProfileInfoIcon,
    SecurityIcon,
    SettingsIcon,
    SupportIcon,
    PageIcon, DeleteIcon
} from "./SvgComponents";
import profileImg from "../../assets/images/others/image-not-found.png";
import {Col, Container, Row, Button, Form, FloatingLabel} from "react-bootstrap";
import {IsCustomerLogin} from "../../App";
import {constants} from "../../utils/constants";
import {DeleteUserAccount, GetAllPages} from "../../services/user.service";
import {EmptyLocalStorage} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";
import ThemeModal from "./ThemeModal";
import {deleteUser, updateChatListDelete, updateTokens} from "../../services/firebase.service";

const ProfileCard = (props) => {
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    return (
        <div className={"profileCard"}>
            <div className={"imgContainer"}>
                <img className={"img-fluid"} src={props.img} alt={"profileImg"}/>
            </div>
            <div className={"profileDetails"}>
                <span className={"profileName"}>{props.name.substring(0, 25)}</span>
                <span className={"profileEmail"}>{props.email}</span>
                <Link className={"profileLink"}
                      to={isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL ? "/profile-information" : "/myprofile"}>View
                    profile <ArrowIcon/></Link>
            </div>
        </div>
    )
}

function AccSidebar({userData}) {
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [pages, setPages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteAccountModalShow, setDeleteAccountModalShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getPages();
    }, [])

    const getPages = useCallback(async () => {
        await GetAllPages().then((data) => {
            if (data.status) {
                setPages(data?.data);
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }, [pages])

    const DeleteAccountModal = () => {
        return (
            <div className={"purchaseTokensModal text-center"}>
                {
                    isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL ?
                        <p><strong style={{color: 'red'}}>You won't be able to <br/> withdraw the amount.</strong> <br/>Are
                            you sure you want to <br/> delete your account? </p>
                        :
                        <p><strong style={{color: 'red'}}>All of the tokens won't be <br/> refunded if you delete the
                            account.</strong> <br/>Are you sure you want to <br/> delete your account? </p>
                }

                <div class="m-t-20 m-b-20 d-flex align-items-center justify-content-center">
                    <Button disabled={isLoading} className="btn-rounded-outline"
                            onClick={() => onClickDeleteCard()}>Delete</Button>
                    <Button className="btn-rounded-outline" onClick={() => {
                        setDeleteAccountModalShow(false)
                    }}>Cancel</Button>
                </div>
            </div>
        )
    }

    const onClickDeleteCard = async () => {
        if (isCustomerLogin.id) {
            setIsLoading(true)

            await DeleteUserAccount(isCustomerLogin.id).then(async (data) => {
                if (data.status) {
                    if (data.data && data.data.length > 0) {
                        //if professional revert tokens to user that coming in response
                        data.data.map((v) => {
                            updateTokens(v.user_id, v.tokens)
                        })
                    }
                    let id = isCustomerLogin.id;
                    setDeleteAccountModalShow(false)
                    setIsLoading(false)
                    await deleteUser(id)
                    EmptyLocalStorage()
                    window.location.href = '/'
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    return (
        <div>
            <ProfileCard img={userData?.image || profileImg} name={userData.name || ""} email={userData?.email || ""}/>
            <hr/>
            <h4>Options</h4>
            <div className={"accSideBar"}>
                <ul className={"list-unstyled"}>
                    <li className={"sidebar-item"}>
                        <NavLink
                            to={isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL ? "/profile-information" : "/myprofile"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><ProfileInfoIcon/> Profile Information</span>
                                <span><ArrowIcon/></span>
                            </div>
                        </NavLink>
                    </li>
                    <li className={"sidebar-item"}>
                        <NavLink to={"/security"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><SecurityIcon/> Security</span>
                                <span><ArrowIcon/></span>
                            </div>
                        </NavLink>
                    </li>
                    <li className={"sidebar-item"}>
                        <NavLink to={"/payment"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><PaymentIcon/> Payment</span>
                                <span><ArrowIcon/></span>
                            </div>
                        </NavLink>
                    </li>
                    {/*<li className={"sidebar-item"}>
                        <NavLink to={"/settings"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><SettingsIcon /> Settings</span>
                                <span><ArrowIcon /></span>
                            </div>
                        </NavLink>
                    </li>*/}
                    <li className={"sidebar-item"}>
                        <NavLink to={"/blocked-users"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><BlockSqIcon/> Blocked Users</span>
                                <span><ArrowIcon/></span>
                            </div>
                        </NavLink>
                    </li>
                    <li className={"sidebar-item"}>
                        <div onClick={() => {
                            setDeleteAccountModalShow(true)
                        }} className={"delete-btn"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer2"}><span className={"svgset"}><DeleteIcon/> </span>Delete Account</span>
                                <span><ArrowIcon/></span>
                            </div>
                        </div>
                    </li>
                    <h4>Other</h4>
                    <li className={"sidebar-item"}>
                        <NavLink to={"/faqs"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><FaqIcon/> FAQ</span>
                                <span><ArrowIcon/></span>
                            </div>
                        </NavLink>
                    </li>
                    <li className={"sidebar-item"}>
                        <NavLink to={"/support"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><SupportIcon/> Support</span>
                                <span><ArrowIcon/></span>
                            </div>
                        </NavLink>
                    </li>
                    {
                        pages && pages.length > 0 && pages.map((page) => {
                            return (
                                <li className={"sidebar-item"}>
                                    <a href={"javascript:;"} onClick={() => {
                                        navigate(`/other/${page.slug}`, {
                                            state: {
                                                'page': page,
                                            }
                                        })
                                    }}>
                                        <div className={"d-flex align-items-center justify-content-between w-100"}>
                                            <span className={"svgContainer2"}><span
                                                className={"svgset"}><PageIcon/></span> {page?.title}</span>
                                            <span><ArrowIcon/></span>
                                        </div>
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <ThemeModal title={"Delete Account"} content={<DeleteAccountModal/>} className={"addCardInfo"}
                        size={"sm"}
                        show={deleteAccountModalShow}
                        onHide={() => {
                            setDeleteAccountModalShow(false)
                        }}/>
        </div>
    );
}

export default AccSidebar;
