import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Form, InputGroup, Button} from "react-bootstrap"
import {AiFillEyeInvisible, AiFillEye} from "react-icons/ai"
import {FcGoogle} from "react-icons/fc"
import {FaFacebook, FaTwitter, FaApple} from "react-icons/fa"
import {useForm} from "react-hook-form";
import {VALIDATIONS, VALIDATIONS_TEXT, Patterns} from "../utils/constants";
import {ToastContainer, toast} from 'react-toastify';
import {SuccessNotificationIcon} from "../components/SvgComponents";
import ThemeModal from "../components/ThemeModal";
import {
    EmptyLocalStorage,
    GetAuthUser,
    GetCurrentUser, GetSettings,
    Login,
    SocialLogin
} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";
import {constants} from "../../utils/constants";
import {IsCustomerLogin} from "../../App";
import {auth, googleProvider, facebookProvider, appleProvider, twitterProvider} from "../../utils/firebase-config"
import {activeInactiveUser, createOrUpdateUser, isOnline} from "../../services/firebase.service";
//import {LoginSocialTwitter} from 'reactjs-social-login'
import {Splash} from "./Splash";

function SignIn() {
    const navigate = useNavigate();
    const [password, setPassword] = useState(false);
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [loginAs, setLoginAs] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // useEffect(() => {
    //     getSettings()
    // }, [])

    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onSubmit",
        criteriaMode: "firstError",
        shouldFocusError: true,
    });

    // const getSettings = async () => {
    //         await GetSettings().then((data) => {
    //             console.log('setting data', data?.data?.social_login);
    //
    //             // if (data.status) {
    //             //     setCharities(data.data)
    //             // } else {
    //             //     Toast(data.message, false);
    //             // }
    //         }).catch((error) => {
    //             if (error.response.status == 401) {
    //                 EmptyLocalStorage()
    //                 navigate('/');
    //             } else {
    //                 return Toast(error.response.data.message, false);
    //             }
    //         })
    //     }

    // const handleOnClick = async (provider, role_id) => {
    //     auth.signInWithPopup(provider).then(async (response) => {
    //             // Accounts successfully linked.
    //             if (!response) {
    //                 console.log(response, "response from social login")
    //             }
    //
    //             var credential = response.credential;
    //             var user = response.user;
    //             //console.log("credential:",credential.accessToken)
    //
    //             let data = {
    //                 name: response?.additionalUserInfo?.profile?.given_name || '',
    //                 //last_name: response?.additionalUserInfo?.profile?.family_name || '',
    //                 //user_name: user?.displayName || response?.additionalUserInfo?.profile?.name || '',
    //                 email: user?.email || response?.additionalUserInfo?.profile?.email || '',
    //                 device_type: "web",
    //                 device_token: "dummy",
    //                 platform: credential.providerId,
    //                 token: credential.accessToken,
    //                 //image: user?.photoUrl || response?.additionalUserInfo?.profile?.picture || '',
    //                 client_id: user.uid,
    //                 role_id: role_id.toString()
    //             }
    //
    //             await SocialLogin(data).then((data) => {
    //                 if (data.status && data.data) {
    //                     //toast.success(data.message);
    //                     if (data.data && data.data.roles[0] && data.data.roles[0].id == constants.ROLES.ROLE_USER) {
    //                         Toast(data.message, true);
    //                         setIsCustomerLogin(GetCurrentUser());
    //                         setLoginAs('');
    //                         navigate('/explore');
    //                     }
    //
    //                     //If Role Professional Redirect to Professional Dashboard
    //                     if (data.data && data.data.roles[0] && data.data.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL) {
    //                         Toast(data.message, true);
    //                         setIsCustomerLogin(GetCurrentUser());
    //                         setLoginAs('');
    //                         if (data.data && data.data.is_verified === 0 && data.data.is_profile_complete === 0) {
    //                             navigate('/account-verification-professional')
    //                         } else if (data && data.is_verified === 1 && data.is_profile_complete === 0) {
    //                             navigate('/configure-profile')
    //                         } else if (data && data.is_verified === 1 && data.is_profile_complete === 1) {
    //                             navigate('/dashboard');
    //                         }
    //                     }
    //                 }
    //             })
    //         }
    //     ).catch((error) => {
    //         console.log(error.message)
    //         Toast(error.message, error)
    //     });
    // }

    const handleOnClickUser = async (provider, role_id) => {
        auth.signInWithPopup(provider).then(async (response) => {
                if (!response) {
                    console.log(response, "response from social login")
                }

                var credential = response.credential;
                var user = response.user;

                let data = {
                    name: response?.additionalUserInfo?.profile?.given_name || '',
                    email: user?.email || response?.additionalUserInfo?.profile?.email || '',
                    device_type: "web",
                    device_token: "dummy",
                    platform: credential.providerId,
                    token: credential.accessToken,
                    client_id: user.uid,
                    role_id: role_id.toString()
                }

                await SocialLogin(data).then(async (data) => {
                    if (data.status && data.data) {
                        //toast.success(data.message);
                        if (data.data && data.data.roles[0] && data.data.roles[0].id == constants.ROLES.ROLE_USER) {
                            await activeInactiveUser(data.data.id, true)
                            await createOrUpdateUser(data.data).then(() => {
                                Toast(data.message, true);
                                setIsCustomerLogin(GetCurrentUser());
                                setLoginAs('');
                                navigate('/explore');
                            })
                        } else {
                            EmptyLocalStorage()
                            Toast('Account is linked with professional! Please try to login as a professional', false)
                        }
                    }
                })
            }
        ).catch((error) => {
            //console.log(error.message)
            Toast(error.message, error)
        });
    }

    const handleOnClickProfessional = async (provider, role_id) => {
        auth.signInWithPopup(provider).then(async (response) => {
                if (!response) {
                    console.log(response, "response from social login")
                }

                var credential = response.credential;
                var user = response.user;

                let data = {
                    name: response?.additionalUserInfo?.profile?.given_name || '',
                    email: user?.email || response?.additionalUserInfo?.profile?.email || '',
                    device_type: "web",
                    device_token: "dummy",
                    platform: credential.providerId,
                    token: credential.accessToken,
                    client_id: user.uid,
                    role_id: role_id.toString()
                }


                await SocialLogin(data).then(async (data) => {
                    if (data.status && data.data) {
                        //If Role Professional Redirect to Professional Dashboard
                        if (data.data && data.data.roles[0] && data.data.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL) {
                            await activeInactiveUser(data.data.id, true)
                            await createOrUpdateUser(data.data).then(() => {
                                Toast(data.message, true);
                            })
                            setIsCustomerLogin(GetCurrentUser());
                            setLoginAs('');
                            if (data.data && data.data.is_verified === 0 && data.data.is_profile_complete === 0) {
                                navigate('/account-verification-professional')
                            } else if (data && data.is_verified === 1 && data.is_profile_complete === 0) {
                                navigate('/configure-profile')
                            } else if (data && data.is_verified === 1 && data.is_profile_complete === 1) {
                                navigate('/dashboard');
                            }
                        } else {
                            EmptyLocalStorage()
                            Toast('Account is linked with user! Please try to login as a user.', false)
                        }
                    }
                })
            }
        ).catch((error) => {
            //console.log(error.message)
            Toast(error.message, error)
        });
    }

    const onSubmit = async (data) => {
        await Login(data.email, data.password).then(async (data) => {
            if (data.status) {
                if (data.data.is_active == 0) {
                    Toast('Account is suspended By Admin', false);
                    return false;
                }
                //If Role User Redirect To NewsFeed
                if (data.data && data.data.roles[0] && data.data.roles[0].id == constants.ROLES.ROLE_USER) {
                    await setIsCustomerLogin(GetCurrentUser());
                    await activeInactiveUser(data.data.id, true)
                    await createOrUpdateUser(data.data).then(() => {
                        Toast(data.message, true);
                        navigate('/explore');
                    })
                }
                //If Role Professional Redirect to Professional Dashboard
                else if (data.data && data.data.roles[0] && data.data.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL) {
                    await setIsCustomerLogin(GetCurrentUser());
                    await activeInactiveUser(data.data.id, true)
                    await createOrUpdateUser(data.data).then(() => {
                        Toast(data.message, true);
                    })
                    if (data && data.data.is_verified === 0 && data.data.is_profile_complete === 0) {
                        navigate('/account-verification-professional')
                    } else if (data && data.data.is_verified === 1 && data.data.is_profile_complete === 0) {
                        navigate('/configure-profile')
                    } else if (data && data.data.is_approved === 0 && data.data.is_verified === 1 && data.data.is_profile_complete === 1) {
                        navigate('/not-verified-account')
                    } else if (data && data.data.is_approved === 1 && data.data.is_verified === 1 && data.data.is_profile_complete === 1) {
                        navigate('/dashboard')
                    }
                } else {
                    Toast('User not found!', false);
                }
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            //console.log('error', error?.response)
            if (error?.response?.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const ModalSelectionContent = ({type}) => {
        let provider;
        if (type == "google") {
            provider = googleProvider
        }
        if (type == "facebook") {
            provider = facebookProvider
        }
        if (type == "apple") {
            provider = appleProvider
        }
        if (type == "twitter") {
            provider = twitterProvider
        }
        return (
            <div className={"signinChoice"}>
                <Button type={"button"} className={"btn-solid w-100"} onClick={() => {
                    handleOnClickUser(provider, constants.ROLES.ROLE_USER)
                }}>
                    As User
                </Button>
                <Button type={"button"} className={"btn-solid w-100"} onClick={() => {
                    handleOnClickProfessional(provider, constants.ROLES.ROLE_PROFESSIONAL)
                }}>
                    As Professional
                </Button>
            </div>
        )
    }

    /*const ModalSelectionTwitter = () => {
        return (
            <div className={"signinChoice"}>

                <LoginSocialTwitter
                    isOnlyGetToken
                    client_id={process.env.REACT_APP_TWITTER_V2_APP_KEY || ''}
                    //onLoginStart={onLoginStart}
                    onResolve={({provider, data}) => {
                        console.log(provider, data, constants.ROLES.ROLE_USER);
                        //setProvider(provider)
                        //setProfile(data)
                    }}
                    onReject={(err) => {
                        console.log(err)
                    }}
                >
                    <Button type={"button"} className={"btn-solid w-100"}>
                        As User
                    </Button>
                </LoginSocialTwitter>


                <LoginSocialTwitter
                    isOnlyGetToken
                    client_id={process.env.REACT_APP_TWITTER_V2_APP_KEY || ''}
                    redirect_uri={"http://localhost:3005/"}
                    //onLoginStart={onLoginStart}
                    onResolve={({provider, data}) => {
                        console.log(provider, data, constants.ROLES.ROLE_PROFESSIONAL);
                        //setProvider(provider)
                        //setProfile(data)
                    }}
                    onReject={(err) => {
                        console.log(err)
                    }}
                >
                    <Button type={"button"} className={"btn-solid w-100"}>
                        As Professional
                    </Button>
                </LoginSocialTwitter>

                {/!*<Button type={"button"} className={"btn-solid w-100"} onClick={() => {
                    handleOnClickProfessional('twitter', constants.ROLES.ROLE_PROFESSIONAL)
                }}>
                    As Professional
                </Button>*!/}
            </div>
        )
    }*/

    /*
    *
    *  Google Signin
    * */
    const SocialSignIn = ({type, title}) => {
        const [modal, setModal] = useState(false);
        const showIcon = (type) => {
            if (type == "google") {
                return (
                    <>
                        <FcGoogle/>
                        Sign In with Google
                    </>
                )
            }
            if (type == "facebook") {
                return (
                    <>
                        <FaFacebook/>
                        Sign In with Facebook
                    </>
                )
            }
            if (type == "apple") {
                return (
                    <>
                        <FaApple/>
                        Sign In with Apple
                    </>
                )
            }
            if (type == "twitter") {
                return (
                    <>
                        <FaTwitter/>
                        Sign In with Twitter
                    </>
                )
            }
        }

        return (
            <div>
                <Button type={"button"} className={"w-100 btn-solid active"} onClick={() => {
                    setModal(true);
                }}>
                    {showIcon(type)}
                </Button>
                <ThemeModal title={title} content={<ModalSelectionContent type={type}/>} className={"reportUser"}
                            size={"sm"}
                            show={modal}
                            onHide={() => {
                                setModal(false)
                            }}/>
            </div>
        )
    }

    const [twitterModal, setTwitterModal] = useState(false);

    return (
        <div className={"loginForm signIn"}>
            <h1>Login</h1>
            {/*<p>Quam pulvinar nullam quam nunc.</p>*/}
            <div className={"filler m-t-30"}></div>
            <form className={"m-t-40"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                <div className={"mb13"}>
                    <Form.Control type="email" autoComplete={"off"} placeholder="Email" className={"formEmail"}
                                  {...register("email",
                                      {
                                          maxLength: {
                                              value: VALIDATIONS.EMAIL,
                                              message: VALIDATIONS_TEXT.EMAIL_MAX
                                          },
                                          pattern: {
                                              value: Patterns.Email,
                                              message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                          },
                                      })
                                  }
                    />
                    {errors.email &&
                    <span className={"validation-error"} role="alert">{errors.email.message}</span>}
                </div>
                <div className={"mb13"}>
                    <InputGroup className={"passwordGroup"}>
                        <Form.Control
                            type={password ? "text" : "password"} placeholder="Password"
                            aria-label="password"
                            autoComplete={"off"}
                            aria-describedby="inputGroup-sizing-sm"
                            className={"mb-0"}
                            {...register("password",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                    },
                                })
                            }
                        />
                        <Button variant="outline-secondary" id="button-addon1"
                                onClick={() => setPassword(!password)}>
                            {password ? <AiFillEyeInvisible/> : <AiFillEye/>}
                        </Button>
                    </InputGroup>
                    {errors.password &&
                    <span className={"validation-error"} role="alert">{errors.password.message}</span>}
                </div>

                {/*<div className={"mb13"}>
                    <InputGroup className={"passwordGroup"}>
                        <Form.Control
                            placeholder="Select Role"
                            id={"roleId"}
                            {...register('role_id', {
                                required: {
                                    value: "required",
                                    message: "Role is required"
                                },
                            })}
                            as="select"
                        >
                            <option value="">Login as</option>
                            <option value={constants.ROLES.ROLE_ADMIN}>Admin</option>
                            <option value={constants.ROLES.ROLE_USER}>User</option>
                            <option value={constants.ROLES.ROLE_PROFESSIONAL}>Professional</option>
                        </Form.Control>
                    </InputGroup>
                    {errors.role_id &&
                    <span className={"validation-error"} role="alert">{errors.role_id.message}</span>}
                </div>*/}


                <ul className={"list-login list-inline d-flex justify-content-between align-items-center"}>
                    <li className={"list-inline-item"}>
                        <Form.Check
                            type={"checkbox"}
                            id={"rememberMe"}
                            label={"Remember Me"}
                            className={"formCheckbox"}
                        />
                    </li>
                    <li className={"list-inline-item"}>
                        <Link to={"/forgot-password"} className={"linkNav"}>
                            Forgot Password
                        </Link>
                    </li>
                </ul>
                <Button type={"submit"} className={"w-100 btn-solid"}>
                    Sign In
                </Button>
            </form>
            {/*<Button type={"button"} className={"w-100 btn-solid active"}>*/}
            {/*    <FcGoogle /> Sign in with Google*/}
            {/*</Button>*/}
            <SocialSignIn type={"google"} title={"Sign In with Google"}/>
            <SocialSignIn type={"facebook"} title={"Sign In with Facebook"}/>
            {/*<SocialSignIn type={"apple"} title={"Sign In with Apple"}/>*/}
            {/*<SocialSignIn type={"twitter"} title={"Sign In with Twitter"}/>*/}

            {/*<div>
                <Button type={"button"} className={"w-100 btn-solid active"} onClick={() => {
                    setTwitterModal(true);
                }}>
                    <FaTwitter/>Sign In with Twitter
                </Button>
                <ThemeModal title={"Sign In with Twitter"} content={<ModalSelectionTwitter type="twitter"/>}
                            className={"reportUser"}
                            size={"sm"}
                            show={twitterModal}
                            onHide={() => {
                                setTwitterModal(false)
                            }}/>
            </div>*/}

            {/*<LoginSocialTwitter*/}
            {/*    isOnlyGetToken*/}
            {/*    client_id={process.env.REACT_APP_TWITTER_V2_APP_KEY || ''}*/}
            {/*    //redirect_uri={REDIRECT_URI}*/}
            {/*    //onLoginStart={onLoginStart}*/}
            {/*    onResolve={({provider, data}) => {*/}
            {/*        setProvider(provider)*/}
            {/*        setProfile(data)*/}
            {/*    }}*/}
            {/*    onReject={(err) => {*/}
            {/*        console.log(err)*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Button type={"button"} className={"w-100 btn-solid active"} onClick={() => {*/}
            {/*        setModal(true);*/}
            {/*    }}>*/}
            {/*        {showIcon(type)}*/}
            {/*    </Button>*/}
            {/*</LoginSocialTwitter>*/}
            {/*<ThemeModal title={'Twitter Login'} content={<ModalSelectionTwitter type={type}/>} className={"reportUser"}*/}
            {/*                size={"sm"}*/}
            {/*                show={modal}*/}
            {/*                onHide={() => {*/}
            {/*                    setModal(false)*/}
            {/*                }}/>*/}

            {/*<Button type={"button"} className={"w-100 btn-solid active"}>
            <FaFacebook/> Sign in with Facebook
        </Button>
        <Button type={"button"} className={"w-100 btn-solid active"}>
            <FaApple/> Sign in with Apple
        </Button>
        <Button type={"button"} className={"w-100 btn-solid active"}>
            <FaTwitter /> Sign in with Twitter
        </Button>*/}
            <div className={"filler m-t-30"}></div>
            <p className={"text-center p-account"}>Don’t have an account? <Link to={"/sign-up"}
                                                                                className={"linkNav"}>Sign
                Up</Link></p>
            <hr className={"hr-short"}/>
            <p className={"text-center p-account boxed-account"}>Are you a professional? <Link
                to={"/create-account-professional"} className={"linkNav"}>Sign Up Here</Link></p>
        </div>
    );
}

export default SignIn;
