import React, {useContext, useEffect, useState} from 'react'
import {Container, Row, Col, DropdownButton, Dropdown, Button, Form} from "react-bootstrap"
import {
    Dot,
    LeftIcon,
    Star,
    VerticalIcon,
    ChatIconOutline,
    BlockIcon,
    ReportIcon,
    SuccessIcon, UnlockedIcon, WarningIcon, ClockIcon, CheckIcon, EmptyListIcon
} from "../components/SvgComponents";
import {Link, useNavigate, useParams} from "react-router-dom"
import profileImg from "../../assets/images/others/feed/feed7-lg.png"
import placeholderImg from "../../assets/images/others/image-not-found.png"
import ReadMore from "../components/ReadMore";
import ThemeModal from "../components/ThemeModal";
import {DeleteBlockUser, GetBlockUserById, GetUserById, PostBlockUser} from "../../services/user.service";
import {EmptyLocalStorage} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import moment from "moment";
import {useForm} from "react-hook-form";
import {GetReportType} from "../../services/report_type.service";
import {PostReport} from "../../services/report.service";
import {constants} from "../../utils/constants";
import {GetChatRequest, PostChatRequest} from "../../services/chat_request.service";
import {blockUnblockProfile, blockUnblockThread, createUserChatList, getUser} from "../../services/firebase.service";
import {IsCustomerLogin} from "../../App";
import Profile01 from "../../assets/images/others/feed/feed1.png";
import ProfilePlaceholder from "../../assets/images/placeholders/placeholder1.png";

const Status = ({status}) => {
    if (status === "active") {
        return (
            <div className={"statusContainer"}>
                <span className={"status accepting"}>
                    <Dot/> Accepting messages
                </span>
            </div>
        )
    } else if (status === "notActive") {
        return (
            <div className={"statusContainer"}>
                <span className={"status notAccepting"}>
                    <Dot/> Not accepting messages
                </span>
            </div>
        )
    }
}

const BlockStatus = ({status}) => {
    if (status) {
        return (
            <div className={"statusContainer"}>
                <span className={"status blocked"}>
                    You have blocked this person
                </span>
            </div>
        )
    }
}

function ChatUserProfile({user_id, showProfile, setShowProfile}) {
    const navigate = useNavigate();
    //const params = useParams();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [modalBlockShow, setModalBlockShow] = useState(false);
    const [modalUnBlockShow, setModalUnBlockShow] = useState(false);
    const [modalReportShow, setModalReportShow] = useState(false);
    const [ReportSuccessModalShow, setReportSuccessModalShow] = useState(false);
    const [data, setUserProfileDetails] = useState([]);
    const [reportType, setReportType] = useState([]);
    const [isBlocked, setIsBlocked] = useState(false);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [successBlockModalShow, setSuccessBlockModalShow] = useState(false);
    const [successUnBlockModalShow, setSuccessUnBlockModalShow] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [requestData, setRequestData] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [charityImage, setCharityImage] = useState(false);
    const {register, handleSubmit, reset, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        getCharityImage();
        getProfile();
        getBlockUserById();
        getReportType();
        getChatRequest();
    }, [])

    const getCharityImage = async () => {
        await GetUserById(user_id).then((data) => {
            if(data.status && data?.data){
                let charity_image = data?.data?.charity?.image || placeholderImg
                setCharityImage(charity_image);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const getProfile = async () => {
        await getUser(user_id, (data) => {
            if (data) {
                setUserProfileDetails({
                    profileId: data ? data.id : '',
                    profileName: data ? data?.name || data.username : '',
                    ageLimit: moment().diff(data?.dob, 'years', false) <= 18 ? 'Under 18' : '',
                    isCharityMember: data ? data.charity_mode : '',
                    status: data && data.taking_messages == 1 ? "active" : "notActive",
                    block_status: data && data.block_status == 1 ? "" : "blocked",
                    img: data ? data.image : placeholderImg,
                    category: data && data.profession ? data.profession.name : '',
                    bio: data && data.about ? data.about : 'N/A',
                    tokensCount: data ? parseInt(data.message_price) : 0,
                    categoryTags: data ? data.tags : [],
                    charity: data ? data.charity : ''
                })
            }
        });
    }

    const getChatRequest = async () => {
        await GetChatRequest({user_id: user_id, professional_id: isCustomerLogin.id}).then((data) => {
            if (data.status) {
                setRequestData(data.data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const getBlockUserById = async () => {
        await GetBlockUserById(user_id).then((data) => {
            if (data.status) {
                data.data ? setIsBlocked(true) : setIsBlocked(false)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const getReportType = async () => {
        await GetReportType().then((data) => {
            if (data.status) {
                setReportType(data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const BlockModalContent = () => {
        return (
            <div className={"blockProfile"}>
                <p className={"text-center"}>
                    Are you sure you want to<br/>
                    block this user?
                </p>
                <Row className={"g-3"}>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button type={"button"} className={"btn-solid active w-100"}
                                onClick={() => setModalBlockShow(false)}>
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button disabled={disableButton} type={"button"} className={"btn-solid w-100"}
                                onClick={() => handleBlockProfile(user_id)}>
                            Block
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    const UnBlockModalContent = () => {
        return (
            <div className={"blockProfile"}>
                <p className={"text-center"}>
                    Are you sure you want to<br/>
                    unblock this user?
                </p>
                <Row className={"g-3"}>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button type={"button"} className={"btn-solid active w-100"}
                                onClick={() => setModalUnBlockShow(false)}>
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button disabled={disableButton} type={"button"} className={"btn-solid w-100"}
                                onClick={() => handleUnBlockProfile(user_id)}>
                            Unblock
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    const ReportModalContent = () => {
        return (
            <div className={"blockProfile"}>
                <p>
                    Reason to report
                </p>
                <form className={"contactSupport"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                    <Row className={"g-3 p-3"}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={"mb13"}>
                                <Form.Select id={"formLocation"}
                                             {...register("report_type_id",
                                                 {
                                                     required: {
                                                         value: true,
                                                         message: VALIDATIONS_TEXT.REPORT_TYPE_REQUIRED
                                                     },
                                                 })
                                             }>
                                    <option value="">Report Type</option>
                                    {
                                        reportType && reportType.map((type) => {
                                            return (
                                                <option value={type.id}>{type.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                                {errors.report_type_id &&
                                <span className={"validation-error"}
                                      role="alert">{errors.report_type_id.message}</span>}
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={"mb13"}>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    placeholder="Description"
                                    rows={"5"}
                                    {...register('description', {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.MESSAGE_REQUIRED
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.MESSAGE_MAX,
                                            message: VALIDATIONS_TEXT.MESSAGE_MAX
                                        },
                                    })}
                                />
                                {errors.description &&
                                <span className={"validation-error"}
                                      role="alert">{errors.description.message}</span>}
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button disabled={disableButton} type={"submit"} className={"btn-solid w-100"}>
                                Report
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
        )
    }

    const ReportSuccessModal = () => {
        return (
            <div className={"SuccessModal codeSentSuccess py-5"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-20"}>
                        <SuccessIcon/>
                    </div>
                    <h2 className={"m-b-20"}><b>Thanks for reporting</b></h2>
                    <p className={"m-b-20"}>If we find this content to be in violation of our community guidelines we
                        will remove it.</p>
                    <Button className="btn-solid" onClick={() => setReportSuccessModalShow(false)}>Done</Button>
                </div>
            </div>
        )
    }

    const SuccessBlockModal = () => {
        return (
            <div className={"SuccessModal codeSentSuccess py-5"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-20"}>
                        <SuccessIcon/>
                    </div>
                    <h2 className={"m-b-20"}><b>User Blocked</b></h2>
                    <Button className="btn-solid" onClick={() => setSuccessBlockModalShow(false)}>Done</Button>
                </div>
            </div>
        )
    }

    const SuccessUnBlockModal = () => {
        return (
            <div className={"SuccessModal codeSentSuccess py-5"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-20"}>
                        <SuccessIcon/>
                    </div>
                    <h2 className={"m-b-20"}><b>User Unblocked</b></h2>
                    <Button className="btn-solid" onClick={() => setSuccessUnBlockModalShow(false)}>Done</Button>
                </div>
            </div>
        )
    }

    const onSubmit = async (data) => {
        setDisableButton(true);
        await PostReport({
            report_type_id: data.report_type_id,
            description: data.description,
            instance_type: constants.ROLES.ROLE_PROFESSIONAL,
            instance_id: user_id
        }).then((data) => {
            if (data.status) {
                // Toast(data.message, true);
                reset();
                setReportSuccessModalShow(true);
                setModalReportShow(false);
                setDisableButton(false);
            } else {
                setDisableButton(false);
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                setDisableButton(false);
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    *  handle Block Click
    * */
    const handleBlockProfile = async (id) => {
        setDisableButton(true);
        await PostBlockUser(id).then((data) => {
            if (data.status) {
                // Toast("User Blocked", true);
                setSuccessBlockModalShow(true)
                let activeThread = [];
                if (isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER) {
                    activeThread = isCustomerLogin.id + '-' + id;
                } else {
                    activeThread = id + '-' + isCustomerLogin.id;
                }
                blockUnblockProfile(activeThread, 1, id)
                getBlockUserById();
                setDisableButton(false)
                setModalBlockShow(false)
            } else {
                setDisableButton(false)
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                setDisableButton(false)
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    *  handle Block Click
    * */
    const handleUnBlockProfile = async (id) => {
        setDisableButton(true);
        await DeleteBlockUser(id).then((data) => {
            if (data.status) {
                // Toast("User UnBlocked", true);
                setSuccessUnBlockModalShow(true)
                setModalUnBlockShow(false)

                let activeThread = [];
                if (isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER) {
                    activeThread = isCustomerLogin.id + '-' + id;
                } else {
                    activeThread = id + '-' + isCustomerLogin.id;
                }
                blockUnblockProfile(activeThread, 0, id)
                getBlockUserById();
                setDisableButton(false);
            } else {
                setDisableButton(false);
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                setDisableButton(false);
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    *  handle Report Profile Click
    * */
    const handleReportProfile = (id) => {
        console.log("Report profile Id: ", id);
    }

    const handleChatRequest = async (data) => {
        if (data && data.profileId) {
            let obj = {
                ...data, ...{
                    user_id: isCustomerLogin.id,
                    username: isCustomerLogin?.name || isCustomerLogin?.username || '',
                }
            };

            createUserChatList(obj).then(() => {
                //navigate to chat
                navigate('/inbox')
            })
        }
    }


    /*
    *  Request List temporary
    * */
    const RequestList = [
        {
            isActive: true,
            isUnderAge: false,
            img: Profile01,
            name: "Logan Klitsch",
            lastSeen: "Now",
            lastMessage: "You: Curabitur id ex et ex pharetra mollis. Duis placerat, augue.",
            status: "completed"
        },
        {
            isActive: true,
            isUnderAge: true,
            img: Profile01,
            name: "Logan Paul",
            lastSeen: "Now",
            lastMessage: "You: Curabitur id ex et ex pharetra mollis. Duis placerat, augue.",
        }];

    /*
    *  status bar
    * */
    const StatusBar = ({type}) => {
        if (type == "unlocked") {
            return (
                <span className={"statusBar unlocked"}>
                    <UnlockedIcon/> This chat has been unlocked.
                </span>
            )
        } else if (type == "urgent") {
            return (
                <span className={"statusBar urgent"}>
                    <WarningIcon/> Urgent response needed
                </span>
            )
        } else if (type == "timed") {
            return (
                <span className={"statusBar pending"}>
                    <ClockIcon/> You have 47h to reply
                </span>
            )
        } else if (type == "completed") {
            return (
                <span className={"statusBar completed"}>
                    <CheckIcon/> This chat is completed
                </span>
            )
        }
    }

    /*
    *  Request box
    * */
    const RequestBox = (props) => {
        const navigate = useNavigate()
        if(props.name){
            return (
            <li className={""}>
                <div className={`tileComponent ${props.isActive ? "isActive" : ""}`} style={{cursor: 'unset'}} >
                    <div className={"profileImgContainer"}>
                        <div className={"imgTile"}>
                            <img className={"img-fluid"} src={props.img ? props.img : ProfilePlaceholder}
                                 alt={"Logan"}/>
                        </div>
                    </div>
                    <div className={"profileDetails"}>
                        {
                            props.isUnderAge && <span className={"ageTag"}>Under 18</span>
                        }
                        <div className={"profileDetailsHeader"}>
                            {
                                props.name &&
                                <div style={{textTransform: 'capitalize'}}
                                     className={"profileName"}>{props.name}</div>
                            }
                            {
                                props.lastSeen && <div className={"activeStatus"}>{props.lastSeen}</div>
                            }
                        </div>
                        {
                            props.lastMessage && <p>{props.lastMessage}</p>
                        }
                        {
                            props.statusBar && <StatusBar type={props.statusBar}/>
                        }
                    </div>
                </div>
            </li>
            )
        }

    }


    return (
        <div className={"mainDashboard"}>
            <div className={"SingleFeedProfile"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1 m-t-30"}>
                            <Row className={"m-b-30"}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className={"linkText"} style={{cursor: 'pointer'}} onClick={() => {
                                        setShowProfile(false)
                                    }}>
                                        <LeftIcon/> Back
                                    </div>
                                </Col>
                            </Row>

                            <Row className={"feedProfileBox"}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}
                                     className={"col-xxl-5 offset-xxl-1"}>
                                    <div
                                        className={`imgContainer ${data.isCharityMember ? "charityMember" : ""}`}>
                                        <img src={data.img ? data.img : placeholderImg}
                                             alt={data.profileName}
                                             className={"img-fluid parentImage"}
                                             onError={({currentTarget}) => {
                                                 currentTarget.onerror = null; // prevents looping
                                                 currentTarget.src = placeholderImg;
                                             }}/>
                                        {
                                            data.isCharityMember && data?.charity?.name ?
                                                <div className={"charityIcons"}>
                                                    <div className={"imageFlex"}>
                                                        <img src={charityImage || placeholderImg}
                                                             alt={data.profileName}
                                                             className={"img-fluid"}
                                                             onError={({currentTarget}) => {
                                                                 currentTarget.onerror = null; // prevents looping
                                                                 currentTarget.src = placeholderImg;
                                                             }}/>
                                                        <span className={"charityName"}>{data?.charity?.name || ''}</span>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}
                                     className={"col-xxl-5 offset-xxl-1"}>
                                    <div className={"profileDescription"}>
                                        <div className={"profileTitle"}>
                                            <div className={"titleContainer"}>
                                                <h1>{data.profileName}</h1>
                                                {
                                                    data.ageLimit &&
                                                    <span className={"tag"}>{data.ageLimit}</span>
                                                }
                                            </div>
                                            <div className={"dropdownContainer"}>
                                                <DropdownButton id="dropdown-item-button" align={"end"}
                                                                title={<VerticalIcon/>}>
                                                    {
                                                        isBlocked ?
                                                            <Dropdown.Item as="button"
                                                                           onClick={() => setModalUnBlockShow(true)}><BlockIcon/> Unblock</Dropdown.Item>
                                                            :
                                                            <Dropdown.Item as="button"
                                                                           onClick={() => setModalBlockShow(true)}><BlockIcon/> Block</Dropdown.Item>
                                                    }

                                                    <Dropdown.Item as="button"
                                                                   onClick={() => setModalReportShow(true)}><ReportIcon/> Report
                                                        profile</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        isBlocked && <BlockStatus status={isBlocked}/>
                                    }
                                    {
                                        isCustomerLogin.roles && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ?
                                            !isBlocked && <Status status={data.status}/>
                                            : ''
                                    }


                                    {
                                        isCustomerLogin.roles && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ?
                                            <>
                                                <p className={"streamingCategory"}>{data.category}</p>

                                                <h4>Bio</h4>
                                                <Row>
                                                    <Col xs={12} sm={12} md={9} lg={9} xl={9} className={"col-xxl-9"}>
                                                        {
                                                            data.bio &&
                                                            <ReadMore classList={"bioDetails"} textLength={"255"}
                                                                      children={data.bio}/>
                                                        }

                                                    </Col>
                                                </Row>
                                            </> : ''
                                    }

                                    {
                                        isCustomerLogin.roles && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL ?
                                            <>
                                                <h4>Requests Messages</h4>
                                                <div className={`requestList`}>
                                                    <div className={"requestsContainer"}>

                                                        <ul className={"tilesList"}>
                                                            {
                                                                requestData && requestData.length > 0 ? requestData.map((requests, index) => {
                                                                    if (requests.status != constants.CHAT_REQUEST_STATUS.ACCEPTED) {
                                                                        return (
                                                                            <RequestBox
                                                                                isActive={requests.isActive}
                                                                                img={requests?.user?.image || ProfilePlaceholder}
                                                                                isUnderAge={moment().diff(requests?.user?.dob, 'years', false) <= 18 ? 'Under 18' : ''}
                                                                                name={requests?.user?.name}
                                                                                lastSeen={requests.created_ago}
                                                                                lastMessage={requests?.last_message || "N/A"}
                                                                                //statusBar={""}
                                                                                userId={requests?.user?.id || ''}
                                                                            />
                                                                        )
                                                                    }

                                                                    /*if (requests.status != constants.CHAT_REQUEST_STATUS.ACCEPTED) {

                                                                    }*/
                                                                }) : <div className={`requestList isEmpty`}>
                                                                    <div className={"EmptyIconBox h-100"}>
                                                                        <div
                                                                            className={"mx-auto text-center h-100 d-flex flex-column align-contents-center justify-content-center align-items-center"}>
                                                                            <EmptyListIcon/>
                                                                            <p>No request yet.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>

                                            </> : ''
                                    }

                                    {
                                        isCustomerLogin.roles && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ?
                                            <>
                                                {/*<Button className={"chatNow"}
                                                        disabled={isBlocked || data.status == 'notActive'}
                                                        onClick={() => {
                                                            if (!isBlocked || data.status == 'Active') {
                                                                handleChatRequest(data)
                                                            }
                                                        }
                                                        }
                                                >
                                                    <div className={"d-flex align-items-center"}>
                                                        <div className={"chatIcon"}>
                                                            <ChatIconOutline/>
                                                        </div>
                                                        <div className={"d-flex align-items-center"}>
                                                            <span>Chat Now</span>
                                                            <span
                                                                className={"starLabel"}><Star/> {data.tokensCount}</span>
                                                        </div>
                                                    </div>
                                                </Button>*/}

                                                <h4 className={"m-b-20"}>Categories</h4>

                                                <ul className={"list-inline"}>
                                                    {
                                                        data.categoryTags && data.categoryTags.length > 0 ?
                                                            data.categoryTags?.map((tags, index) => {
                                                                return (
                                                                    <li className={"list-inline-item"} key={index}>
                                                                    <span className={"catTag"}>
                                                                        {tags.tag}
                                                                    </span>
                                                                    </li>
                                                                )
                                                            }) : 'N/A'
                                                    }
                                                </ul>
                                            </>

                                            : ''
                                    }


                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <ThemeModal title={"Block User"} content={<BlockModalContent/>} className={"blockUser"} size={"sm"}
                                show={modalBlockShow}
                                onHide={() => {
                                    setModalBlockShow(false)
                                }}/>

                    <ThemeModal title={"Unblock User"} content={<UnBlockModalContent/>} className={"blockUser"}
                                size={"sm"}
                                show={modalUnBlockShow}
                                onHide={() => {
                                    setModalUnBlockShow(false)
                                }}/>

                    <ThemeModal title={"Report User"} content={<ReportModalContent/>} className={"reportUser"}
                                size={"md"}
                                show={modalReportShow}
                                onHide={() => {
                                    setModalReportShow(false)
                                }}/>
                    <ThemeModal title={""} content={<ReportSuccessModal/>} className={"addCardInfo"} size={"sm"}
                                show={ReportSuccessModalShow}
                                onHide={() => {
                                    setReportSuccessModalShow(false)
                                    reset()
                                }}/>
                    <ThemeModal title={""} content={<SuccessBlockModal/>} className={"addCardInfo"} size={"sm"}
                                show={successBlockModalShow}
                                onHide={() => {
                                    setSuccessBlockModalShow(false)
                                    reset()
                                }}/>
                    <ThemeModal title={""} content={<SuccessUnBlockModal/>} className={"addCardInfo"} size={"sm"}
                                show={successUnBlockModalShow}
                                onHide={() => {
                                    setSuccessUnBlockModalShow(false)
                                    reset()
                                }}/>
                </Container>
            </div>
        </div>
    )
}

export default ChatUserProfile
