import React, {useEffect, useState} from 'react'
import {Container, Row, Col, Button} from "react-bootstrap"
import {Link, useNavigate, useParams} from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component";
import profile01 from "../../assets/images/others/feed/feed1.png"
import profile02 from "../../assets/images/others/feed/feed2.png"
import profile03 from "../../assets/images/others/feed/feed3.png"
import profile04 from "../../assets/images/others/feed/feed4.png"
import profile05 from "../../assets/images/others/feed/feed5.png"
import profile06 from "../../assets/images/others/feed/feed6.png"
import profile07 from "../../assets/images/others/feed/feed7.png"
import profile08 from "../../assets/images/others/feed/feed8.png"
import profile09 from "../../assets/images/others/feed/feed9.png"
import profile10 from "../../assets/images/others/feed/feed10.png"
import profile11 from "../../assets/images/others/feed/feed11.png"
import profile12 from "../../assets/images/others/feed/feed12.png"
import {Star} from "../components/SvgComponents";
import placeholderImg from "../../assets/images/others/image-not-found.png"
import {GetUser, GetUserExploreListing} from "../../services/user.service";
import {EmptyLocalStorage} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";
import {constants} from "../../utils/constants";
import {GetProfessionById} from "../../services/profession.service";
import {LoadingComponent} from "../components/LoadingComponent";
import {isOnline} from "../../services/firebase.service";
import {truncate} from "../utils/constants";

const ProfileCard = ({user_id, url, img, isCharityMember, username, tag, tokenCount, isActive}) => {
    const [activeOnline, setActiveOnline] = useState(false);

    useEffect(() => {
        let user = isOnline(user_id, (data) => {
            setActiveOnline(data)
        });
    }, [])
    return (
        <Col xs={6} sm={6} md={4} lg={2} xl={2}>
            <Link to={url}
                  className={`boxProfessional ${activeOnline ? "isActive" : ''} ${isCharityMember ? "charityMember" : ''}`}>
                <div>
                    <div className={"imgContainer"}>

                        <img className={"img-fluid imgProfile"}
                             src={img ? img : placeholderImg}
                             alt={"profile"}
                             onError={({currentTarget}) => {
                                 currentTarget.onerror = null; // prevents looping
                                 currentTarget.src = placeholderImg;
                             }}
                        />
                    </div>
                    <h4><span>{username ? truncate(username, 25) : username}</span></h4>
                    <p className={"tag"}>{tag}</p>
                    <p className={"tokenCount"}><Star/> {tokenCount} tokens</p>
                </div>
            </Link>
        </Col>
    )
}

function CategoryFeed() {
    const params = useParams();
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState(true);
    const [feed, setFeed] = useState([]);
    const [title, setTitle] = useState('');
    const [load, setLoad] = useState(true);
    const [showButton, setShowButton] = useState(false);
    const [meta, setMeta] = useState(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(12);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getUserExplore();
    }, [page])

    const getUserExplore = () => {
        let paramData = {}

        if (params.category == 'featured') {
            paramData = {
                'is_featured': 1,
                'page': page,
                'per-page': perPage
            }
        } else if (params.category == 'trending') {
            paramData = {
                'is_trending': 1,
                'page': page,
                'per-page': perPage
            }
        } else if (params.category) {
            paramData = {
                'profession_id': params.category,
                'page': page,
                'per-page': perPage
            }
        }

        GetUserExploreListing(paramData).then((data) => {
            if (data.status) {
                const responseData = data.data.data
                setMeta(data.data.meta)
                setFeed([...feed, ...responseData])
                setLoad(false)
                setShowButton(false)
            } else {
                Toast(data.message, false);
                setLoad(false)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                setLoad(false)
                return Toast(error.response.data.message, false);
            }
        })
    }

    return (
        <div className={"mainDashboard"}>
            <div className={"feedDisplay"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row className={"m-b-20"}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <h1>{title}</h1>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>

                                </Col>
                            </Row>

                            <Row id="ScrollContainerItems">
                                {
                                    load && <LoadingComponent/>
                                }
                                {
                                    !load && feed && feed.length > 0 && feed.map((profiles, index) => {
                                        return <ProfileCard
                                            key={index}
                                            user_id={profiles.id}
                                            url={profiles.id ? '/user_profile/' + profiles.id : ''}
                                            isCharityMember={profiles.charity_mode}
                                            img={profiles.image ? profiles.image : ''}
                                            username={profiles.name}
                                            tag={profiles.profession ? profiles.profession.name : ''}
                                            tokenCount={profiles.message_price ? parseInt(profiles.message_price) : 'N/A'}
                                            isActive={true}
                                        />
                                    })
                                }
                            </Row>
                            <div style={{marginTop: '30px', display: 'flex', justifyContent: 'center'}}>
                                {
                                    !showButton && !load && meta?.current_page != meta?.last_page ?
                                        <Button onClick={() => {
                                            setPage(page + 1)
                                            setShowButton(true)
                                        }} className={"btn-rounded-outline w-40"}>
                                            Load More
                                        </Button> : ''
                                }
                                {
                                    showButton && <LoadingComponent/>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default CategoryFeed
