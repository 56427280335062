import React, {useContext, useEffect, useState, useMemo} from 'react'
import {Container, Row, Col, Form, FloatingLabel, FormLabel, Button} from "react-bootstrap";
import ProfileImg from "../../assets/images/others/profileImage.png"
import {MdDeleteOutline} from "react-icons/md"
/*
* React tags input
* */
import {render} from 'react-dom';
import {WithContext as ReactTags} from 'react-tag-input';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {LeftIcon, SuccessNotificationIcon} from "../components/SvgComponents";
import placeholderImg from "../../assets/images/others/profilePlaceholder.png"
import ProfileImgUser from "../../assets/images/others/profileUser.png";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import {EmptyLocalStorage, GetCurrentUser, SetAuthUser} from "../../services/authentication.service";
import {GetProfession} from "../../services/profession.service";
import {Toast, UploadImageToS3} from "../../utils/helpers";
import {PostUserTags} from "../../services/user_tag.service";
import {GetPages, UpdateProfile} from "../../services/user.service";
import {IsCustomerLogin} from "../../App";
import Navigation from "../components/navigations/Navigation";
import {createOrUpdateUser} from "../../services/firebase.service";
import countryList from 'react-select-country-list'
import moment from "moment";
import {constants} from "../../utils/constants";

function Pages(props) {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(false);
    const options = useMemo(() => countryList().getData(), [])
    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getPages();
    }, [])

    const getPages = async () => {
        setLoading(false)
        await GetPages({slug: props.slug}).then((data) => {
            if (data.status) {
                setPage(data.data)
               // console.log(data.data);
                setLoading(true)
            } else {
                props.showPrivacyPolicy(false)
                props.showTerms(false)
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                props.showPrivacyPolicy(false)
                props.showTerms(false)
                return Toast(error.response.data.message, false);
            }
        })
    }

    return (
        loading &&
        <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'start',
            marginTop: '0px',
            padding: '20px'
        }}>
            <div className={"configureProfile PolicyPage"}>
                <Container>
                    <Row className={"m-b-20"}>
                        <Col xs={12} sm={12}>
                            <div>
                                <div onClick={() => {
                                    props.showPrivacyPolicy(false)
                                    props.showTerms(false)
                                }} style={{marginBottom: '15px', cursor: 'pointer'}}><span style={{marginRight: '5px'}}><LeftIcon/></span> Back
                                </div>
                                <h1> {page?.title || ''}</h1>
                            </div>
                        </Col>
                        <Col xs={12} sm={12}>
                            <div dangerouslySetInnerHTML={{ __html: page?.content }} />
                            {/*<div>
                                {page?.content || ''}
                            </div>*/}
                        </Col>

                    </Row>

                </Container>
            </div>
        </div>
    )
}

export default Pages
