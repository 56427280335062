const roles = {
    ROLE_ADMIN: 1,
    ROLE_USER: 2,
    ROLE_PROFESSIONAL: 3,
}

const transactionTypes = {
    PURCHASE_TOKEN: 5,
    WITHDRAW_TOKENS: 10,
    REFUND_TOKENS: 15
}

const transactionTypesKey = {
    [transactionTypes.PURCHASE_TOKEN]: 'PURCHASE_TOKEN',
    [transactionTypes.WITHDRAW_TOKENS]: 'WITHDRAW_TOKENS',
    [transactionTypes.REFUND_TOKENS]: 'REFUND_TOKENS',
}

const s3Credential = {
    bucketName: "our-chat-media",//our-chat
    dirName: "chat",
    region: "ap-south-1",
    accessKeyId: "AKIATMFKXRG7GUZUNEFM",//AKIAXCCCZ7TILLMFSYQM
    secretAccessKey: "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",//XvKCcU4JRc/Yp4qJnfVRicxTJpn1rpczZYTg89WE
    s3EndPoint: "https://our-chat-media.s3.ap-south-1.amazonaws.com",
    fileSize: '5000000'
}

const collections = {
    CHATROOM: "chatRoom_live",
    USERS: "user_live",
    CHATLIST: "chatlist",
    CHATROOMSTAGING: "chatRoom_staging",
    USERSTAGING: "user_staging",
    CHATLISTSTAGING: "chatlist_staging",
    SCHEDULER: "scheduler",
    GENERALCHATLIST: "general_chat_users_live",
    GENERALCHATROOM: "general_chatRoom_live",
}

const errorMessages = {
    fileSize: 'The file size is too large',
    fileSuccess: 'file uploaded successfully',
    fileError: 'Error in uploading file',
}

const ChatRequestStatus = {
    REQUESTED: 10,
    ACCEPTED: 20,
    LOCKED: 30,
    URGENT: 40
}

export const constants = {
    LOCAL_STORAGE_TOKEN: 'AUTH_ACCESS_TOKEN',
    LOCAL_STORAGE_USER: 'USER_DATA',
    BASE_URL: process.env.REACT_APP_BASE_URL,
    ROLES: roles,
    TRANSACTION_TYPES: transactionTypes,
    TRANSACTION_TYPE_KEYS: transactionTypesKey,
    LOCALE: 'en-us',
    CURRENCY: 'USD',
    S3CREDENTIAL: s3Credential,
    COLLECTION: collections,
    ERRORMESSAGES: errorMessages,
    CHAT_REQUEST_STATUS: ChatRequestStatus,
    ACCOUNT_LOCK_HOURS: 48,
    ACCOUNT_LOCK_FIVE_HOURS: 120,
    //ACCOUNT_LOCK_HOURS: 1,
    //ACCOUNT_LOCK_FIVE_HOURS: 2,
    DATE_AGE_LIMIT: 13,
    MESSAGE_CHARACTER_LIMIT: 255,
    PROFESSIONAL_MIN_CHARACTER_LIMIT: 30
};

export const apiUrl = {
    login: 'login',
    register: 'register',
    social_login: 'social-login',
    logout: 'logout',
    forgot_password: 'forgot-password',
    resend_otp: 'resend-otp',
    verify_otp: 'verify-otp',
    reset_password: 'reset-password',
    change_password: 'change-password',
    check_username: 'check-username',
    check_email: 'check-email',
    profile: 'me',
    users: 'users',
    professions: 'professions',
    user_tags: 'user-tags',
    admin_queries: 'admin-queries',
    faqs: 'faqs',
    blocked_users: 'blocked-users',
    get_block_user_by_id: 'get-block-user-by-id',
    unblock_user: 'unblock-user',
    report_types: 'report-types',
    reports: 'reports',
    get_blocked_user_list: 'get-blocked-user-list',
    user_dashboard_data: 'user-dashboard-data',
    withdrawal_requests: 'withdrawal-requests',
    user_cards: 'user-cards',
    tokens: 'tokens',
    purchase_tokens: 'purchase-tokens',
    chat_requests: 'chat-requests',
    update_chat_requests: 'update-chat-request',
    recent_searches: 'recent-searches',
    avatars: 'avatars',
    charities: 'charities',
    pages: 'pages',
    get_pages: 'get-pages',
    offensive_words: 'offensive-words',
    chat_request_limit: 'chat-request-limit',
    unlocked_chat_request: 'unlocked-chat-request',
    user_explore: 'user-explore',
    user_explore_listing: 'user-explore-listing',
    user_search: 'user-search',
    settings: 'settings',
    chat_notifications: 'chat-notifications',
    delete_user_account: 'delete-user-account',
    create_token: 'create-token',
    checkout: 'checkout'
}
