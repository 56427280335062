import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import SignIn from "./app/authentication/SignIn";
import AuthLayout from "./app/layouts/AuthLayout";
import OpenPageLayout from "./app/layouts/OpenPageLayout";

/************************
 *  User Routes
 ************************/
import CreateAcountUser from './app/user/CreateAccountUser';
import MyFeed from "./app/user/MyFeed";
import UserProfile from "./app/user/UserProfile";

/************************
 *  Professional Routes
 ************************/
import CreateAcount from "./app/professional/CreateAccount";
import AccountVerification from "./app/professional/AccountVerification";
import ForgotPassword from "./app/authentication/ForgotPassword";
import ResetPassword from "./app/authentication/ResetPassword";
import Success from "./app/supportingPages/Success";
import ConfigureProfile from "./app/professional/ConfigureProfile";
import DashboardLayout from "./app/layouts/DashboardLayout";
import Dashboard from "./app/professional/dashboard/Dashboard";

/**********************************************
 *  shared by both user and professional users
 **********************************************/
import ProfileInformation from "./app/accountSetting/ProfileInformation";
import SecurityInfo from "./app/accountSetting/SecurityInfo";
import Payment from "./app/accountSetting/Payment";
import Settings from "./app/accountSetting/Settings";
import Error from "./app/supportingPages/Error";
import ProfileNotFound from "./app/supportingPages/ProfileNotFound";
import SingleProfile from "./app/professional/SingleProfile";

/**********************************************
 *  Chat Layout
 **********************************************/
import ChatLayout from "./app/chat/ChatLayout";
import Chat from "./app/chat/Chat";

import {useNavigate, useLocation} from "react-router-dom";
import Support from "./app/accountSetting/Support";
import Faqs from "./app/accountSetting/Faqs";
import CategoryFeed from "./app/user/CategoryFeed";
import MyProfileProfessional from "./app/professional/MyProfileProfessional";
import {GetAuthUser, GetCurrentUser} from "./services/authentication.service";
import ProfessionalProtectedRoute from "./middleware/ProfessionalProtectedRoute";
import UserProtectedRoute from "./middleware/UserProtectedRoute";
import AuthProtectedRoute from "./middleware/AuthProtectedRoute";
import {IsCustomerLogin} from "./App";
import {GetProfile} from "./services/user.service";
import {Toast} from "./utils/helpers";
import CompleteProfileCheck from "./middleware/CompleteProfileCheck";
import VerifyProfessionalAccount from "./middleware/VerifyProfessionalAccount";
import {constants} from "./utils/constants";
import {Splash} from "./app/authentication/Splash";
import BlockedUsers from "./app/accountSetting/BlockedUsers";
import ChatUserProfile from "./app/chat/UserProfile";
import {ResetPasswordSuccess} from "./app/supportingPages/ResetPasswordSuccess";
import {PasswordChangeSuccess} from "./app/supportingPages/PasswordChangeSuccess";
import Pages from "./app/authentication/Pages";
import PageLayout from "./app/layouts/PageLayout";
import NotVerifiedAccount from "./app/professional/NotVerifiedAccount";
import Page from "./app/supportingPages/Page";
import PageSetting from "./app/accountSetting/PageSetting";
import PaymentSuccess from "./app/supportingPages/PaymentSuccess";
import PaymentCancel from "./app/supportingPages/PaymentCancel";

const RoutesComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [updateCard, setUpdateCard] = useState(false);
    //const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const isCustomerLogin = GetCurrentUser()

    useEffect(() => {
        document.body.scrollTop = 0
    }, [navigate, location])

    /*useEffect(() => {
        console.log("====>", isCustomerLogin)
    }, [])*/


    return (
        <Routes>
            <Route element={<AuthLayout/>}>
                <Route exact path="*" element={<Error type={"404"}/>}/>
            </Route>

            {/* Split layout right and left */}
            <Route element={<AuthProtectedRoute/>}>
                <Route element={<AuthLayout/>}>
                    <Route index path="/" element={<SignIn/>}/>
                    <Route exact path="/sign-up" element={<CreateAcountUser/>}/>
                    <Route exact path="/create-account-professional" element={<CreateAcount/>}/>
                    <Route exact path="/forgot-password" element={<ForgotPassword/>}/>
                    <Route exact path="/reset-password-success" element={<ResetPasswordSuccess/>}/>
                    <Route exact path="/reset-password/:id" element={<ResetPassword/>}/>
                    <Route exact path="/password-change-success" element={<PasswordChangeSuccess/>}/>
                    <Route exact path="/success" element={<Success type={"reset-password"}/>}/>
                    <Route exact path="/success-verification" element={<Success type={"verification-code"}/>}/>
                    <Route exact path="/account-created-success" element={<Success type={"account-created"}/>}/>
                    <Route exact path="/password-changed-success" element={<Success type={"verification-code"}/>}/>
                    <Route exact path="/page/:slug" element={<Page/>}/>
                </Route>
            </Route>

            <Route element={<AuthLayout/>}>
                <Route exact path="/account-verification-professional" element={<AccountVerification/>}/>
                <Route exact path="/success-verified-profile" element={<Success type={"account-verified"}/>}/>
                <Route exact path="/not-verified-account" element={<NotVerifiedAccount/>}/>
            </Route>

            <Route element={<OpenPageLayout/>}>
                <Route exact path="/configure-profile" element={<ConfigureProfile/>}/>
            </Route>

            <Route element={<PageLayout/>}>
                <Route exact path="/pages" element={<Pages slug={'privacy-policy'}/>}/>
            </Route>

            <Route element={<DashboardLayout updateCard={updateCard} setUpdateCard={setUpdateCard}/>}>
                {
                    isCustomerLogin && isCustomerLogin.roles[0] && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL &&
                    <Route>
                        {/*Professional Routes*/}
                        <Route exact path="/dashboard" element={<Dashboard/>}/>
                        <Route exact path="/single-profile/:id" element={<SingleProfile/>}/>
                        <Route exact path="/profile-information" element={<MyProfileProfessional/>}/>
                    </Route>
                }


                {
                    isCustomerLogin && isCustomerLogin.roles[0] && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER &&
                    <Route>
                        {/*User Routes*/}
                        <Route exact path="/explore" element={<MyFeed/>}/>
                        <Route exact path="/explore/:category" element={<CategoryFeed/>}/>
                        <Route exact path="/user_profile/:id" element={<UserProfile/>}/>
                    </Route>
                }

                {/*Account Settings shared by both professional and user*/}
                <Route exact path="/myprofile" element={<ProfileInformation/>}/>
                <Route exact path="/security" element={<SecurityInfo/>}/>
                <Route exact path="/payment" element={<Payment updateCard={updateCard}/>}/>
                <Route exact path="/settings" element={<Settings/>}/>
                <Route exact path="/support" element={<Support/>}/>
                <Route exact path="/faqs" element={<Faqs/>}/>
                <Route exact path="/blocked-users" element={<BlockedUsers/>}/>
                <Route exact path="/other/:slug" element={<PageSetting/>}/>

                <Route element={<AuthLayout/>}>
                    <Route exact path="/payment_success" element={<PaymentSuccess />}/>
                    <Route exact path="/payment_cancel" element={<PaymentCancel />}/>
                </Route>
            </Route>

            <Route element={<ChatLayout/>}>
                {
                    isCustomerLogin && isCustomerLogin.roles[0] && (isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER || isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL) &&
                    <Route>
                        <Route exact path="/inbox" element={<Chat/>}/>
                        <Route exact path="/inbox/user_profile/:id" element={<ChatUserProfile/>}/>
                    </Route>
                }
            </Route>

        </Routes>
    )
}

function RouteConfig() {
    return (
        <BrowserRouter>
            <RoutesComponent/>
        </BrowserRouter>
    );
}

export default RouteConfig;
