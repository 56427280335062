import React, {useState, useEffect} from 'react'
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Emoji from 'react-emojis';

export const ShowReaction = ({props}) => {
    const renderEmoji = (props) => {
        return (
            <Tooltip id="emoji" {...props}>{props.name}</Tooltip>
        )
    };
    if (props.message && props.message.reactions.length > 0) {
        return (
            <div className={"showReactions"}>
                {
                    props.message && props.message.reactions.map((data) => {
                        if (data.react === "satisfaction") {
                            return (
                                /*<OverlayTrigger
                                    delay={{ hide: 0, show: 0 }}
                                    placement="bottom"
                                    overlay={renderEmoji(data)}
                                >
                                    <span><Emoji emoji="thumbs-up"/></span>
                                </OverlayTrigger>*/
                                <span style={{cursor: "default"}} title={data.name}><Emoji emoji="thumbs-up"/></span>
                            )
                        }
                        if (data.react === "love") {
                            return (
                                /*<OverlayTrigger
                                    delay={{ hide: 0, show: 0 }}
                                    placement="bottom"
                                    overlay={renderEmoji(data)}
                                >
                                    <span><Emoji emoji="red-heart"/></span>
                                </OverlayTrigger>*/
                                <span style={{cursor: "default"}} title={data.name}><Emoji emoji="red-heart"/></span>
                            )
                        }
                        if (data.react === "happy") {
                            return (
                                /*<OverlayTrigger
                                    delay={{ hide: 0, show: 0 }}
                                    placement="bottom"
                                    overlay={renderEmoji(data)}
                                >
                                    <span><Emoji emoji="grinning-squinting-face"/></span>
                                </OverlayTrigger>*/
                                <span style={{cursor: "default"}} title={data.name}><Emoji emoji="grinning-squinting-face"/></span>
                            )
                        }
                        if (data.react === "surprise") {
                            return (
                                /*<OverlayTrigger
                                    delay={{ hide: 0, show: 0 }}
                                    placement="bottom"
                                    overlay={renderEmoji(data)}
                                >
                                    <span><Emoji emoji="face-with-open-mouth"/></span>
                                </OverlayTrigger>*/
                                <span style={{cursor: "default"}} title={data.name}><Emoji emoji="face-with-open-mouth"/></span>
                            )
                        }
                        if (data.react === "sad") {
                            return (
                                /*<OverlayTrigger
                                    delay={{ hide: 0, show: 0 }}
                                    placement="bottom"
                                    overlay={renderEmoji(data)}
                                >
                                    <span><Emoji emoji="crying-face"/></span>
                                </OverlayTrigger>*/
                                <span style={{cursor: "default"}} title={data.name}><Emoji emoji="crying-face"/></span>
                            )
                        }
                        if (data.react === "angry") {
                            return (
                                /*<OverlayTrigger
                                    delay={{ hide: 0, show: 0 }}
                                    placement="bottom"
                                    overlay={renderEmoji(data)}
                                >
                                    <span><Emoji emoji="pouting-face"/></span>
                                </OverlayTrigger>*/
                                <span style={{cursor: "default"}} title={data.name}><Emoji emoji="pouting-face"/></span>
                            )
                        }
                    })
                }
            </div>
        )
    }
}