import {constants, apiUrl} from '../utils/constants';
import {GET, POST} from './api.service.wrapper';

export const PostWithDrawRequest = async (params) => {
    const response = await POST(apiUrl.withdrawal_requests, params);
    return response;
}

export const GetWithDrawRequest = async (params) => {
    let response = await GET(apiUrl.withdrawal_requests, params);
    return response;
}
