import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {HiArrowSmLeft} from 'react-icons/hi';
import {LeftIcon, SuccessNotificationIcon} from "../components/SvgComponents";
import {useForm} from "react-hook-form";
import {Patterns, VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import {toast} from "react-toastify";
import {EmptyLocalStorage, ForgetPassword} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";

function ForgotPassword() {
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        let email = data.email
        await ForgetPassword(email).then((data) => {
            if (data.status) {
                Toast(data.message, true);
                navigate("/reset-password-success", {state: {
                    email: email
                }});
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }
  return (
      <div className={"viewportFull"}>
          <div className={"d-flex align-items-center justify-content-center h-100"}>
              <div className={"componentContainer"}>
                  <div className={"forgotPassword"}>
                      <h1>Password recovery</h1>
                      {/*<p>Curabitur id ex et ex pharetra mollis. Duis<br/> placerat, augue vel accumsan feugiat.</p>*/}

                      <Form className={"m-t-40"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                          <div className={"mb13"}>
                              <Form.Control type="email" autoComplete={"off"} placeholder="Email" className={"formEmail"}
                                            {...register("email",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.EMAIL,
                                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                                    },
                                                    pattern: {
                                                        value: Patterns.Email,
                                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                    },
                                                })
                                            }/>
                              {errors.email &&
                              <span className={"validation-error"} role="alert">{errors.email.message}</span>}
                          </div>

                          <Button type={"submit"} className={"w-100 btn-solid m-t-30"}>
                              Send instructions
                          </Button>
                      </Form>

                      <div className={"mx-auto text-center m-t-40"}>
                          <Link to={"/"} className={"backToBtn"}>
                              <LeftIcon /> Back to Sign In
                          </Link>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default ForgotPassword;
