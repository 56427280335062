import S3 from "react-aws-s3";
import {apiUrl, constants} from "./constants";
import {ToastContainer, toast} from 'react-toastify';
import {SuccessNotificationIcon, UrgentIcon} from "../app/components/SvgComponents";
import axios from "axios";

window.Buffer = window.Buffer || require("buffer").Buffer;

export const Toast = (message, success) => {
    if (success) {
        return toast.success(message, {
            icon: <SuccessNotificationIcon/>,
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    else{
        return toast.error(message, {
            icon: <UrgentIcon/>,
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export const MoneyFormat = (value) => {
    let currency = Intl.NumberFormat(constants.LOCALE, {
        style: "currency",
        currency: constants.CURRENCY,
        useGrouping: false,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });

    return currency.format(value)
}

export const randomIdWithName = (userName) => {
    let randomNumber = Math.random().nextInt(100000);
    return userName + randomNumber;
}

export const calculateAge = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
    {
        age_now--;
    }
    return age_now;
}

export const UploadImageToS3 = async (fileInput) => {
    const file = fileInput?.current?.files[0] || fileInput[0];
    const newFileName = file.name;
    const fileSize = file.size;

    //Check filesize
    if (fileSize > parseInt(constants.S3CREDENTIAL.fileSize, 10)) {
        return {
            success: false,
            message: constants.ERRORMESSAGES.fileSize
        }
    }

    const config = {
        bucketName: constants.S3CREDENTIAL.bucketName,
        dirName: constants.S3CREDENTIAL.dirName,
        region: constants.S3CREDENTIAL.region,
        accessKeyId: constants.S3CREDENTIAL.accessKeyId,
        secretAccessKey: constants.S3CREDENTIAL.secretAccessKey,
        s3Url: constants.S3CREDENTIAL.s3EndPoint,
    };

    const ReactS3Client = new S3(config);
    let responseData = {};
    await ReactS3Client.uploadFile(file, newFileName).then((data) => {
        if (data && data.status === 204) {
            responseData = {
                success: true,
                message: constants.ERRORMESSAGES.fileSuccess,
                data: data.location
            }
        } else {
            responseData = {
                success: false,
                message: constants.ERRORMESSAGES.fileError
            }
        }
    })

    return responseData;
}

export const get_firebase_token = async (callback) => {
    let url = constants.BASE_URL + apiUrl.create_token;
    let response = await axios.get(url)
    callback(response.data.data);
}
