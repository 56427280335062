import React, {useContext, useEffect, useRef, useState} from 'react'
import {Container, Row, Col, InputGroup, Button, Form} from "react-bootstrap"
import {Link, useNavigate} from "react-router-dom"
import {ArrowIcon, CancelIcon, LeftIcon, Star} from "../components/SvgComponents";
import {GetUser, GetUserExplore, GetUserSearch} from "../../services/user.service";
import {EmptyLocalStorage, Logout} from "../../services/authentication.service";
import {get_firebase_token, Toast} from "../../utils/helpers";
import {constants} from "../../utils/constants";
import {truncate} from "../utils/constants";
import placeholderImg from "../../assets/images/others/image-not-found.png"
import {CgSearch} from "react-icons/cg"
import {MdCancel} from "react-icons/md"
import {DeleteRecentSearches, GetRecentSearches, PostRecentSearches} from "../../services/recent_searches.service";
import {activeInactiveUser, isOnline, test, testCron, updateChatListDelete} from "../../services/firebase.service";
import {IsCustomerLogin} from "../../App";
import {auth} from "../../utils/firebase-config";

export const ProfileCard = ({user_id, url, img, isCharityMember, username, tag, tokenCount, isActive}) => {
    const [activeOnline, setActiveOnline] = useState(false);

    useEffect(() => {
        if (user_id) {
            let user = isOnline(user_id, (data) => {
                setActiveOnline(data)
            });
        }
    }, [])

    return (
        <Col xs={6} sm={6} md={4} lg={2} xl={2}>
            <Link to={url}
                  className={`boxProfessional ${activeOnline ? "isActive" : ''} ${isCharityMember ? "charityMember" : ''}`}>
                <div>
                    <div className={"imgContainer"}>
                        <img className={"img-fluid imgProfile"}
                             src={img ? img : placeholderImg}
                             alt={"profile"}
                             onError={({currentTarget}) => {
                                 currentTarget.onerror = null; // prevents looping
                                 currentTarget.src = placeholderImg;
                             }}
                        />
                    </div>
                    <h4><span>{username ? truncate(username, 25) : username}</span></h4>
                    <p className={"tag"}>{tag}</p>
                    <p className={"tokenCount"}><Star/> {tokenCount} tokens</p>
                </div>
            </Link>
        </Col>
    )
}

export const Search = () => {
    const [focus, setFocus] = useState(false)
    const searchRef = useRef()
    const [recentSearch, setRecentSearch] = useState([])
    const [recommendedProfessional, setRecommendedProfessional] = useState([])
    const [search, showSearch] = useState(false)
    const [recommendation, showRecommendation] = useState(false)
    const navigate = useNavigate();

    const getRecentSearch = async () => {
        await GetRecentSearches({is_mine: 1, 'order-column': 'updated_at', order: 'desc'}).then((data) => {
            if (data.status) {
                setRecentSearch(data.data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const postRecentSearch = async (value) => {
        await PostRecentSearches({keyword: value}).then((data) => {
            if (data.status) {
                getRecentSearch()
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const removeSearch = async (data) => {
        await DeleteRecentSearches(data.id).then((data) => {
            if (data.status) {
                getRecentSearch()
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const getRecommendedProfessional = async (value) => {
        setRecommendedProfessional([])
        await GetUserSearch({
            keyword: value
        }).then((data) => {
            if (data.status) {
                setRecommendedProfessional(data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const handleOnChange = (e) => {
        if (e.target.value != '') {
            postRecentSearch(e.target.value);
            showSearch(false);
            getRecommendedProfessional(e.target.value);
            showRecommendation(true);
        } else {
            handleOnFocus(e)
        }
    }

    const handleOnClickSearch = (input) => {
        if (input) {
            postRecentSearch(input);
            showSearch(false);
            getRecommendedProfessional(input);
            showRecommendation(true);
        }
    }

    const handleOnFocus = (e) => {
        showRecommendation(false);
        showSearch(true);
        getRecentSearch();
    }

    const clearInput = () => {
        searchRef.current.value = "";
        showSearch(false);
        showRecommendation(false);
    }

    const reSearch = (input) => {
        searchRef.current.value = input
        postRecentSearch(input)
        getRecommendedProfessional(input)
        showSearch(false);
        showRecommendation(true);
    }

    return (
        <>
            <div className='SearchHolder'>
                <div className='SearchComp d-flex align-items-center justify-content-between'>
                    <InputGroup className={"passwordGroup"}>
                        <Form.Control
                            className="py-0"
                            onFocus={(e) => {
                                handleOnFocus(e)
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleOnChange(e)
                                }
                            }}
                            /*onBlur={() => {
                                showSearch(false);
                            }}*/
                            ref={searchRef}
                            type="text"
                            placeholder="Search"
                        />

                        {
                            recommendation &&
                            <><Button type="button" variant="outline-secondary" onClick={() => {
                                clearInput()
                            }}>
                                <MdCancel/>
                            </Button></>
                        }
                        {
                            search && <Button type="button" variant="outline-secondary" onClick={() => {
                                clearInput()
                            }}>
                                <MdCancel/>
                                {/*<CgSearch onClick={() => {
                                                handleOnClickSearch(searchRef.current.value)
                                            }}/>*/}
                            </Button>
                        }
                    </InputGroup>
                    {/*{
                        focus &&
                        <span className="ms-2 d-inline-block cancel" onClick={() => clearInput()}>Cancel</span>
                    }*/}
                </div>

                {
                    search && <div className="SearchSuggestions py-3">
                        <ul className='RecentSearch'>
                            <span className='d-block mb-2'>Recent searches</span>
                            {
                                recentSearch && recentSearch.map((data) => {
                                    return (
                                        <li className='text-muted mb-2'>
                                            <div className={"icon-keyword"} onClick={() => {
                                                reSearch(data.keyword)
                                            }}>
                                                <span className={"icon"}><CgSearch/></span>
                                                <span className={"keyword"}>{data.keyword ? data.keyword : ''}</span>
                                            </div>
                                            <div className={"cancelIcon"} onClick={() => {
                                                removeSearch(data)
                                            }}>
                                                <CancelIcon/>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }

                {
                    recommendation && <div className="SearchSuggestions py-3">
                        <ul className='Recommended'>
                            <span className='d-block mb-2'>Recommended</span>
                            {
                                recommendedProfessional && recommendedProfessional.length > 0 ?
                                    recommendedProfessional.map((data) => {
                                        return (
                                            <li className='mb-2' onClick={() => {
                                                navigate('/user_profile/' + data.id)
                                            }} style={{cursor: 'pointer'}}>
                                                <div className="d-flex align-items-center ">
                                                    <div className='img-div'>
                                                        <img src={data.image ? data.image : placeholderImg}
                                                             alt="placeholderImg" onError={({currentTarget}) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = placeholderImg;
                                                        }}/>
                                                    </div>
                                                    <div>
                                                        <span>{data.name}</span>
                                                        <small
                                                            className='d-block text-muted'>{data.profession ? data.profession.name : ''}</small>
                                                    </div>
                                                </div>

                                            </li>
                                        )
                                    }) : <small>No data found in search result</small>
                            }
                        </ul>
                    </div>
                }

            </div>
        </>
    )
}

export const FeatureData = ({featureData}) => {
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}
                 className={`${featureData && featureData.length > 0 ? 'd-block' : 'd-none'}`}>
                <div
                    className={"FeedTitles"}>
                    <h3 className={""}>Featured Professionals</h3>
                    <Link to={"/explore/featured"}
                          className={`linkInline ${featureData && featureData.length > 5 ? 'd-block' : 'd-none'}`}>
                        <span>View more</span> <LeftIcon/>
                    </Link>
                </div>
            </Col>

            {
                featureData && featureData.length > 0 && featureData.map((profiles, index) => {
                    return (
                        <ProfileCard
                            key={profiles.id}
                            user_id={profiles.id}
                            url={profiles.id ? '/user_profile/' + profiles.id : ''}
                            isCharityMember={profiles.charity_mode}
                            img={profiles.image ? profiles.image : ''}
                            username={profiles.name}
                            tag={profiles.profession ? profiles.profession.name : ''}
                            tokenCount={profiles.message_price ? parseInt(profiles.message_price) : 'N/A'}
                            isActive={true}
                        />
                    )
                })
            }
        </Row>
    )
}

export const TrendingData = ({trendingData}) => {
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}
                 className={`${trendingData && trendingData.length > 0 ? 'd-block' : 'd-none'}`}>
                <div
                    className={"FeedTitles"}>
                    <h3 className={""}>Trending Professionals</h3>
                    <Link to={"/explore/trending"}
                          className={`linkInline ${trendingData && trendingData.length > 5 ? 'd-block' : 'd-none'}`}>
                        <span>View more</span> <LeftIcon/>
                    </Link>
                </div>
            </Col>

            {
                trendingData && trendingData.length > 0 && trendingData.map((profiles, index) => {
                    return (
                        <ProfileCard
                            key={profiles.id}
                            user_id={profiles.id}
                            url={profiles.id ? '/user_profile/' + profiles.id : ''}
                            isCharityMember={profiles.charity_mode}
                            img={profiles.image ? profiles.image : ''}
                            username={profiles.name}
                            tag={profiles.profession ? profiles.profession.name : ''}
                            tokenCount={profiles.message_price ? parseInt(profiles.message_price) : 'N/A'}
                            isActive={true}
                        />
                    )
                })
            }
        </Row>
    )
}

export const ProfessionData = ({professionData}) => {
    return (
        <Row>
            {
                professionData && professionData.length > 0 && professionData.map((profession, index) => {
                    if (profession.data && profession.data.length > 0) {
                        return (
                            <>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div
                                        className={"FeedTitles"}>
                                        <h3 className={""}>{profession.name}</h3>
                                        <Link to={`/explore/${profession.id}`}
                                              className={`linkInline ${profession.data && profession.data.length > 5 ? 'd-block' : 'd-none'}`}>
                                            <span>View more</span> <LeftIcon/>
                                        </Link>
                                    </div>
                                </Col>
                                {
                                    profession.data.map((profiles, index) => {
                                        return (
                                            <ProfileCard
                                                key={profiles.id}
                                                user_id={profiles.id}
                                                url={profiles.id ? '/user_profile/' + profiles.id : ''}
                                                isCharityMember={profiles.charity_mode}
                                                img={profiles.image ? profiles.image : ''}
                                                username={profiles.name}
                                                tag={profiles.profession ? profiles.profession.name : ''}
                                                tokenCount={profiles.message_price ? parseInt(profiles.message_price) : 'N/A'}
                                                isActive={true}
                                            />
                                        )
                                    })
                                }
                            </>
                        )
                    }
                })
            }
        </Row>
    )
}

function MyFeed() {
    const navigate = useNavigate();
    const [data, setData] = useState([])

    useEffect(() => {
        //  testCron()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        get_firebase_token((token) => {
            return auth.signInWithCustomToken(token)
        });
    }, [])

    useEffect(() => {
        getUserExplore();
    }, [])

    const getUserExplore = () => {
        GetUserExplore().then((data) => {
            if (data.status) {
                const responseData = data.data
                setData(responseData)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    return (
        <div className={"mainDashboard"}>
            <div className={"feedDisplay"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <>
                                <Row className={"m-b-20"}>
                                    <Col className="my-auto mb-3 mb-md-0" xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <h1 className="m-0">Explore</h1>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Search/>
                                    </Col>
                                </Row>

                                <FeatureData featureData={data.feature_professional}/>

                                <TrendingData trendingData={data.trending_professional}/>

                                <ProfessionData professionData={data.professions}/>
                            </>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default MyFeed
