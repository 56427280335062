import {constants, apiUrl} from '../utils/constants';
import {GET, POST} from './api.service.wrapper';

const storageKey = constants.LOCAL_STORAGE_TOKEN;
const storageUser = constants.LOCAL_STORAGE_USER;

export const Login = async (email, password, device_type, device_token) => {
    const response = await POST(apiUrl.login, {email, password, device_type, device_token});
    await SetToken(response.data.access_token?.token);
    await SetAuthUser(JSON.stringify(response?.data));
    return response;
}

export const VerifyOtpApi = async (otp_code, email) => {
    const response = await POST(apiUrl.verify_otp, {otp_code, email});
    return response;
}

export const ResetPasswordApi = async (email, otp_code, password, password_confirmation) => {
    const response = await POST(apiUrl.reset_password, {email, otp_code, password, password_confirmation});
    return response;
}

export const ForgetPassword = async (email) => {
    const response = await POST(apiUrl.forgot_password, {email, "sending_from": "web"});
    return response;
}

export const ResendOtp = async (email) => {
    const response = await POST(apiUrl.resend_otp, {email});
    return response;
}

export const Register = async (params) => {
    const response = await POST(apiUrl.register, params);
    await SetToken(response.data.access_token?.token);
    await SetAuthUser(JSON.stringify(response?.data));
    return response;
}

export const SocialLogin = async (params) => {
    const response = await POST(apiUrl.social_login, params);
    await SetToken(response.data.access_token?.token);
    await SetAuthUser(JSON.stringify(response?.data));
    return response;
}

export const SetToken = (token) => {
    if (token) localStorage.setItem(storageKey, token);
    else localStorage.removeItem(storageKey);
}

export const GetToken = () => {
    return localStorage.getItem(storageKey);
}

export const SetAuthUser = (user_data) => {
    if (user_data) localStorage.setItem(storageUser, user_data);
    else localStorage.removeItem(storageUser);
}

export const GetAuthUser = () => {
    return JSON.parse(localStorage.getItem(storageUser))?.user;
}

export const GetCurrentUser = () => {
    return JSON.parse(localStorage.getItem(storageUser));
}

export const Logout = async () => {
    const response = await POST(apiUrl.logout);
    return response;
}

export const EmptyLocalStorage = async () => {
    await localStorage.removeItem(storageUser);
    return await localStorage.removeItem(storageKey)
}

export const ChangePassword = async (current_password, password, password_confirmation) => {
    const response = await POST(apiUrl.change_password, {current_password, password, password_confirmation});
    return response;
}

export const CheckEmail = async (params) => {
    const response = await POST(apiUrl.check_email, params);
    return response;
}

export const GetSettings = async () => {
    let response = await GET(apiUrl.settings);
    return response;
}

/*

export const GetAccessToken = () => {
    return JSON.parse(localStorage.getItem(storageUser))?.access_token;
}


export const VerifyOtpApi = async (otp_code, email) => {
    const response = await POST(apiUrl.verify_otp, {otp_code, email});
    return response;
}





export const GetUserRole = () => {
    const user = GetAuthUser();
    let role;
    if (user && user.roles) {
        let role_id = user.roles[0].id;
        if (role_id == constants.ROLES.ROLE_ADMIN) {
            role = constants.ROLES.ROLE_ADMIN;
        } else if (role_id == constants.ROLES.ROLE_VENDOR) {
            role = constants.ROLES.ROLE_VENDOR;
        } else if (role_id == constants.ROLES.ROLE_CSA) {
            role = constants.ROLES.ROLE_CSA;
        } else {
            role = constants.ROLES.ROLE_USER;
        }
    }

    return role;
}
*/
