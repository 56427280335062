import React, {useContext, useEffect} from "react";
import {Outlet, Navigate, useLocation} from "react-router-dom";
import UserNavigation from "../components/navigations/UserNavigation";
import {IsCustomerLogin} from "../../App";
import { ToastContainer, toast } from 'react-toastify';
import ProfessionalNavigation from "../components/navigations/ProfessionalNavigation";
import {constants} from "../../utils/constants";
import {GetCurrentUser} from "../../services/authentication.service";
import {createBrowserHistory} from 'history';
import {Toast} from "../../utils/helpers";
import {GetProfile} from "../../services/user.service";
import {updateTokens} from "../../services/firebase.service";

const history = createBrowserHistory();

function ChatLayout() {
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const location = useLocation()
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status')
        if (status == 'cancel') {
            Toast('Payment Cancelled', false)
            queryParams.delete('cancel')
            history.replace({
                status: queryParams.toString(),
            });
        }
        if (status == 'success') {
            Toast('Payment Successful', true)
            getProfile();
            queryParams.delete('success')
            history.replace({
                status: queryParams.toString(),
            });
        }
    }, [])
    //const isCustomerLogin = GetCurrentUser()

    const getProfile = async () => {
        await GetProfile().then(async (result) => {
            if (result.status) {
                if (result.data?.profile) {
                    updateTokens(result.data.profile.id, result.data.profile.total_tokens);
                }
            }
        }).catch((error) => {
            console.log('error')
        })
    }

    if (isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER) {
        return (
           <div className="ChatLayout">
                <UserNavigation userData={isCustomerLogin}/>
                <div className={"ChatContainer"}>
                <Outlet />
            </div>
                <ToastContainer/>
            </div>
        )
    } else if (isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL) {
        if (isCustomerLogin && isCustomerLogin.is_verified === 1 && isCustomerLogin.is_profile_complete === 1) {
            return (
                <div className="ChatLayout">
                    <ProfessionalNavigation userData={isCustomerLogin} />
                    <div className={"ChatContainer"}>
                <Outlet />
            </div>
                    <ToastContainer/>
                </div>
            )
        }
        else{
            return <Navigate to="/"/>
        }
    }
}

export default ChatLayout;
