import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Form, Row, Button, Accordion} from "react-bootstrap";
import AccSidebar from "../components/AccSidebar";
import {GetFaqs} from "../../services/faqs.service";
import {Toast} from "../../utils/helpers";
import {EmptyLocalStorage} from "../../services/authentication.service";
import {useNavigate} from "react-router-dom";
import {IsCustomerLogin} from "../../App";
import {LoadingComponent} from "../components/LoadingComponent";

function Faqs() {
    const navigate = useNavigate();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [pageLoading, setPageLoading] = useState(false)

    const [faqsList, setFaqsList] = useState([{
        heading: "Where can I subscribe to your newsletter?",
        content: "Unfortunately, we’re unable to offer free samples. As a retailer, we buy all magazines from their publishers at the regular trade price. However, you could contact the magazine’s publisher directly to ask if they can send you a free copy."
    }]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getFaqs();
    }, [])

    const getFaqs = async () => {
        await GetFaqs().then((data) => {
            if (data.status) {
                //Toast(data.message, true);
                setFaqsList(data?.data);
                setPageLoading(true)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const AccordionItem = (props) => {
        return (
            <Accordion.Item eventKey={props.index}>
                <Accordion.Header>
                    <span>{props.heading}</span>
                </Accordion.Header>
                <Accordion.Body style={{wordBreak: 'break-word'}}>
                    <p>
                        {props.content}
                    </p>
                </Accordion.Body>
            </Accordion.Item>
        )
    }

    return (
        <div className={"mainDashboard accountSettings"}>
            <div className={"ProfileInformation"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row>
                                <Col xs={12} sm={12} md={5} lg={4} xl={4} className={"col-xxl-4 d-none d-md-block"}>
                                    <AccSidebar userData={isCustomerLogin}/>
                                </Col>
                                <Col xs={12} sm={12} md={7} lg={8} xl={{span: 6, offset: 1}}
                                     className={"col-xxl-6 offset-xxl-1"}>
                                    {
                                        pageLoading && <> <h1 className={"m-b-30"}>FAQ's</h1>
                                        <Accordion defaultActiveKey="0" flush>
                                        {
                                            faqsList.map((faq, index) => {
                                                return (
                                                    <AccordionItem heading={faq.question} content={faq.answer}
                                                                   index={index} key={index}/>
                                                )
                                            })
                                        }
                                        </Accordion> </>
                                    }


                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Faqs;
