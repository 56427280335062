import React, {useEffect, useState} from "react";
import {LeftIcon} from "../components/SvgComponents";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {GetCurrentUser} from "../../services/authentication.service";
import {constants} from "../../utils/constants";
import { FaThumbsUp } from 'react-icons/fa';

function PaymentSuccess({type}) {
    const isCustomerLogin = GetCurrentUser()
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/')
        }, 5000)
    })

    if (isCustomerLogin && isCustomerLogin.roles[0] && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL) {
        navigate('/')
    } else {
        return (
            <div className={"viewportFull"}>
                <div className={"d-flex align-items-center justify-content-center h-100"}>
                    <div className={"componentContainer"}>
                        <div className={"successPage"}>
                            <div className={"codeSentSuccess"}>
                                <div className={"mx-auto text-center"}>
                                    <div className={"m-b-20"}>
                                        <h1 className={"error404Text"}><FaThumbsUp /></h1>
                                    </div>
                                    <h2>Payment Success</h2>
                                    <p>Wait... You will be automatically redirect</p>
                                </div>
                                <div className={"mx-auto text-center m-t-40"}>
                                    <p className={"p-small"}><LeftIcon/> <span onClick={() => {
                                        navigate('/')
                                    }} style={{cursor: 'pointer'}}>Go Back</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default PaymentSuccess;
